import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import OtpInput from "react-otp-input";
import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { Close } from '@mui/icons-material';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {
    Typography, Grid, Button,Modal,Box,Snackbar, SnackbarContent, IconButton
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
  

    page1card: {
       width: '100%',
  
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   resendtxt:{
    cursor: 'pointer', textAlign: 'center', marginLeft: '15px', color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '14px' 

},
resendtxtdis:{
     textAlign: 'center', marginLeft: '15px', color: '#999999', fontFamily: 'cardiuma-regular !important', fontSize: '14px' 
    
},
   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },
   page2card: {
    width: '100%',
    paddingLeft: '15%',
    background: '#1B2636',

    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        padding: '52px 16px',
        marginLeft: '0%',

    }
},
})

 class e_sign_otp extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            resendstatus: false,
            emailid:'',
            otp: '',
            starttime:true,
        islodding:false,
            aadharnobuttonst: true,
        }



    }
    handleOTPChange = (otp) => {
        this.setState({ otp })


    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    Continue8 = async () => {
        const { otp } = this.state
        let isok = 0
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        
let client_id=rd2.client_id
let emailid=rd2.emailid
let mobileno=rd2.mobileno

        if (!otp.length && isok === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the OTP Number", mysnack: snackeror.myerror })


        }




        if (otp.length !== 6 && isok === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter valid OTP Number", mysnack: snackeror.myerror })


        }
        if (isok === 0) {
            this.setState({ islodding: true })
            let payload = {
                "client_id": client_id,
                "otp": otp,
                "mobileno": mobileno,
                "emailid": emailid,

            }

            try {
                const otp = await httpRequest({
                    endPoint: `/api/verfy/esignVerfy`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });

                this.setState({ islodding: false })
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                if (otp.status === 200) {

                    window.ChangePageNo (11)

                } else {
                    this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
                }
            } catch (error) {

                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

            }

        }
    }
  render() {
    const { classes } = this.props;
    return (
      <div>    <Grid className={classes.page1card} >
      <Typography className={classes.card_tit}>Please enter OTP</Typography>


      <Typography style={{ textAlign: 'center', marginTop: '5px', color: '#999999', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
      <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Grid style={{ width: '95%', display: 'flex', justifyContent: 'center', marginTop: '5px', }}>
              <Typography style={{ textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>{this.state.aadharno}</Typography>

          </Grid>

      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', }}>

          <OtpInput

              numInputs={6}
              value={this.state.otp}
              isInputSecure={true}
              shouldAutoFocus={true}
              focusStyle={{
                  outline: 'none',
                  backgroundColor: 'red !important',
              }}

              inputStyle={{
                  backgroundColor: 'transparent',
                  width: "40px",
                  height: "40px",


                  border: "none",
                  marginRight: "5px",
                  color: "#ffffff ",
                  fontSize: "16px",
                  fontFamily: "cardiuma-M !important",
                  borderBottom: '1px solid #949A9E',

              }}
              containerStyle={{
                  justifyContent: "center",
                  borderBottom: '10',

              }}
              renderInput={(props) => <input {...props} />}

              onChange={this.handleOTPChange}
          />
      </Grid>
      <Grid style={{ marginTop: '5%', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Grid style={{ width: '80%', display: 'flex', }}>
      <Button fullWidth className={classes.joinDiplomtsBtn}
          style={{ marginTop: '50px', }}
          onClick={() => this.Continue8()}
      >Continue

      </Button>
      </Grid>   </Grid>
      <Grid style={{ marginTop: '6%', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography className={this.setState.resendstatus ===true ? classes.resendtxt:classes.resendtxtdis}
              onClick={() => this.Continue7()}

          >Resend OTP</Typography>
      </Grid>
  </Grid>
  <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

</Modal>
<Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
  </div>
    )
  }
}
export default (withStyles(styles)((e_sign_otp)));