import React, { Component } from 'react'

export default class risk_disclosure extends Component {
    render() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <h1>Coming soon </h1>
            </div>
        )
    }
}
