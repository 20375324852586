import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { Button, Grid, Typography, } from '@mui/material'

// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Faqs | BullForce',
    description: 'Bullforce FAQs: Get quick answers on demat accounts, trading platforms, payments, and more. Explore our detailed FAQs to navigate your investment journey with ease.',
    canonical: 'https://bullforce.co/faqs',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, faqs, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const styles = theme => ({

    heading: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important',
        // h1 tags margin start, end removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal'
    },
    heading1: {
        textTransform: 'none !important', color: '#00508A !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important', marginBlockStart: 0, marginBlockEnd: 0,
        
    },
    heading2: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',
        
    },
    p_body: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",
        
    },
    button_text : {
        textTransform: 'none !important', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '18px !important', lineHeight: "22px !important",
       
    },

    mustRead : {
        backgroundColor : "#62AEE433",
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",padding : "20px 20px 10px 0px",
        
    },
    menu_label:{
         backgroundColor : "#F74542" , padding:'12px 45px', margin : "10px 0px 0px 25px", borderRadius : "30px", textTransform: 'none !important', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '18px !important', lineHeight: "22px !important",
    }

})

class Account extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: 'auto', padding: '0px 0px 20px 0px' }}>
                <DocumentMeta {...meta} />
                <Grid style={{ padding: '20px 0px 40px 0px' }}>
                    {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                    <h1 className={classes.heading} style={{ textAlign: "start" , marginLeft : "20px"}} >
                    How to close the BullForce account?
                    </h1>

                    <Grid className={classes.mustRead}>
                            <h3 className={classes.heading1} style={{marginLeft : "20px", fontWeight : "bold"}}>
                                Must Read Information
                            </h3> 
                            <ul style={{color : "#00508A"}}>
                                <li>The account cannot be closed if there is a negative balance.</li>
                                <li>All possessions must be squared off, and holdings must be sold or transferred to another account before closing the BullForce account. To learn how to transfer shares, see What are the different ways to transfer shares from a BullForce demat account to another demat account?</li>
                                <li>Clients can opt for the closure-cum-transfer by submitting a signed hard copy of the account closure form <a href='https://bullforce-images.blr1.digitaloceanspaces.com/public/Account_closure_form.pdf' target="_blank" style={{color : "blue"}}>(PDF)</a> along with a sealed and signed physical copy of the Client Master Report (CMR) or digital CMR copy, e-signed by an official of the DP of the destination demat account. See What is Client Master Report (CMR), and how to get it? This is mandated by depositories and is applicable to all brokers. Refer to point numbers 10.6.1.1 and 10.6.2.1 in this CDSL operating instructions for depository participants (PDF).</li>
                                <li>Locked-in shares, mutual funds and government securities can only be moved to a CDSL demat account.</li>
                                <li>Download all the necessary reports, such as contract notes, ledger, and P&L statements, by visiting <a style={{color : "blue"}} href='https://console.bullforce.co/'>console.bullforce.co </a> before closing the account, as these reports cannot be downloaded or accessed once the account is closed.</li>
                                <li>Once the account is closed, the client cannot reopen the same account with the same user ID. Clients will have to open a new account. To open a new account, see How to open a BullForce account online?</li>
                            </ul>
                    </Grid>

                    <h1 className={classes.heading} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "20px"}} >
                    The BullForce Account can be closed online and offline
                    </h1>

                    <h3 className={classes.heading} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "20px"}} >
                    Online
                    </h3>

                    <Typography className={classes.heading2} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "10px"}} >
                    Resident Indians, NRIs, and minors with Trading Platform access can close their BullForce accounts online via Console. To close an account, follow these steps :
                    </Typography>

                    <ol className={classes.heading2} style={{marginTop : "0px"}}>
                                <li>Login to Backoffice.BullForce.co.</li>
                                <li>Click on Account.</li>
                                <li>Click on Segments.</li>
                                <li>Click on Close account.</li>
                                <li>Select the reason and enter additional feedback.</li>
                                <li>Click on Continue.</li>
                                <li>Accept the terms and conditions and click on Proceed to eSign.</li>
                                <li>Click on Sign now.</li>
                                <li>Tick the checkbox to authorise CDSL.</li>
                                <li>Enter the Aadhaar number and click on Send OTP..</li>
                                <li>Enter the OTP and click on Verify OTP.</li>
                        </ol>

                    <Typography className={classes.heading2} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "10px"}} >Joint account holders and minors without Trading Platform access must <a href='/contactus' style={{color : "blue"}}>create a ticket</a> with the signed account closure form <a href='https://bullforce-images.blr1.digitaloceanspaces.com/public/Account_closure_form.pdf'  target="_blank" style={{color : "blue"}}>(PDF)</a> to close their BullForce account. For joint accounts, all the account holders must sign the closure form. For minor accounts, the guardian must sign the closure form.
                    </Typography>

                    <h3 className={classes.heading} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "30px"}} >
                    Offline
                    </h3>

                    <Typography className={classes.heading2} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "10px"}} >Non-individual BullForce accounts can only be closed offline. For corporate, One Person Company (OPC) and Limited Liability Partnership (LLP) account holders, the account closure form <a href='https://bullforce-images.blr1.digitaloceanspaces.com/public/Account_closure_form.pdf'  target="_blank" style={{color : "blue"}}>(PDF)</a> must be signed by the authorised signatory as per the board resolution.<br/>
For partnership, trust and Association of Persons (AOP) account holders, the account closure form <a href='https://bullforce-images.blr1.digitaloceanspaces.com/public/Account_closure_form.pdf' target="_blank" style={{color : "blue"}}>(PDF)</a> must be signed by the authorised signatory as per the authority letter. For HUFs, Karta must sign the closure form.<br/>
The signed forms must be couriered to:<br/>
BullForce,<br/>
(Maliram Makharia Finstock Pvt. Ltd)<br/>
No. 911, 9TH Floor, Tulsiani Chambers, Free Press<br/>
J-Marg, Nariman Point, MUMBAI - 400021, India<br/>
Refer to the sample signed account closure form <a href='https://bullforce-images.blr1.digitaloceanspaces.com/public/Account_closure_form.pdf'  target="_blank" style={{color : "blue"}}>(PDF)</a>.<br/>
The BullForce account will be closed within 7 working days, and an email notification will be sent to the registered email ID upon completion.
                    </Typography>

                    <h3 className={classes.heading} style={{ textAlign: "start" , marginLeft : "20px" , marginTop : "20px"}} >
                    Still Need Help ?
                    </h3>
<div style={{marginTop:'2%'}}>
                    <a href='/contactus' className={classes.menu_label} >Create a Ticket</a>
                    </div>
                  
                </Grid>
            </div>
        ) 
    }
}
export default (withStyles(styles)((Account)));