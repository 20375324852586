import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import MainLandingPage from './ui-routeing/mainroute';
import MainPage from './ui-pages/landingpage/main'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <MainPage />

    <MainLandingPage />
  </div>


);

