import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import { Typography, Grid } from '@mui/material';
import Footer from '../landingpage/fotter'
const styles = theme => ({
    main_tit: {


        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "32px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",

        fontFamily: "cardiuma-M !important"

    },
    tit: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",

        fontFamily: "cardiuma-M !important"
    },
    tit_label: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",
        textAlign: 'center',
        fontFamily: "cardiuma-regular !important",
        padding: '0px 5px'
    },

    tit_label_date: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",
        textAlign: 'center',
        fontFamily: "cardiuma-Bold !important",
        padding: '0px 5px',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",
        }
    },
    tit_label1: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "28px !important",
        color: "#333333 !important",
        cursor: 'pointer',

        fontFamily: "cardiuma-M !important",
        padding: '0px 5px',

        "&:hover": {
            color: "#0088C9 !important",
            textDecoration: 'underline'


        },
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "13px !important",
        }
    },

    cir_card1:{
        width: '90%' ,
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', 
        }
    },
    cir_card2:{
        width: '20%', marginLeft: '1%',
        "@media only screen and (max-device-width: 480px)": {
            width: '20%',  marginLeft: '0%'
        }
    },
    cir_card3:{
        width: '20%', marginLeft: '1%',
        "@media only screen and (max-device-width: 480px)": {
            width: '30%',  marginLeft: '1%'
        }
    },

    cir_card4:{
        width: '80%', marginLeft: '1%',
        "@media only screen and (max-device-width: 480px)": {
            width: '70%',  marginLeft: '1%'
        }
    }
});

class circular extends Component {
    constructor(props) {
        super(props)


        this.state = {
            userdata: []
        }



    }

    componentDidMount = async () => {

        try {
            const cir_data = await httpRequest({
                endPoint: `api/circulars/list`,
                method: "get",
                instance: "instanceOne",

            });
            console.log("Circular data",cir_data)
            // Sorting the data based on dates
            const sortedData = cir_data.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            this.setState({ userdata: sortedData })
        } catch (error) {
        }

    }
    render() {
        const { classes } = this.props;
        return (
            <div style={{ width: '100%', marginTop: '25px', height: 'auto' }}>

                {/* <Grid style={{ background: '#E9F4FF', display: 'flex', justifyContent: 'space-evenly', padding: '8px', marginTop: '35px', }}>
                    <Typography className='escalationhead' style={{ width: '10%' }} >Date</Typography>
                    <Typography className='escalationhead' style={{ width: '40%' }} >Subject</Typography>
                    <Typography className='escalationhead' style={{ width: '20%' }}>Department</Typography>
                    <Typography className='escalationhead' style={{ width: '10%' }} >Circular No</Typography>


                </Grid>
        */}
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Grid className={classes.cir_card1}>
                        {this.state.userdata.map((label, index) => {


                            return (

                                <Grid style={{ width: '100%', display: 'flex', background: '#ffffff', alignItems: 'center', padding: '20px 2px', borderTop: '1.5px solid #DFE0EB' }}>
                                    <Grid className={classes.cir_card3}>
                                        <Typography className={classes.tit_label_date}>{label.c_date}</Typography>
                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <Grid style={{ width: '100px', padding: '4px', background: '#F2F2F2', borderRadius: '4px', marginTop: '5px' }}>
                                                <Typography className={classes.tit_label}>{label.exchange}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid className={classes.cir_card4}>
                                        <Typography className={classes.tit_label1}

                                            onClick={(e) => window.open(label.link, '_blank')}

                                        >{label.subject}</Typography>
                                    </Grid>

                                </Grid>

                            )
                        })}
                    </Grid>
                </Grid>


            </div>
        )
    }
}
export default (withStyles(styles)((circular)));