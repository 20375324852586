import React, { Component, createRef } from 'react'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'
import { Close } from '@mui/icons-material';
import SignatureCanvas from 'react-signature-canvas'
import { withStyles, } from "@mui/styles";
import * as CryptoJS from 'crypto-js';

import cong_bull from '../../../ui-assets/cong_bull.svg'
import {
    Typography, Grid, Button, Modal, Box, Snackbar, SnackbarContent, IconButton,Checkbox
} from '@mui/material'

import backendHosts from '../../../ui-utils/apiConfig.js'
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'

        }
    },
    sigCanvas: {
        background: '#ffffff'
    },
    shutter: {
        marginTop: '50px',
        width: '100%',
        height: '100vh',
        background: 'red',
        borderTopLeftRadius: '8px',
        borderTopRightRaduis: '8px',
    },



};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);
const styles = theme => ({
    page1card: {
        width: '100%',
        paddingLeft: '20%',
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',

        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 25px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    page2card_1: {
        width: '100%',
        paddingLeft: '0%',
        background: '#1B2636',

        "@media only screen and (max-device-width: 480px)": {
            width: '100vw',
            padding: '75px 16px',
            marginLeft: '0%',
            height:'100vh',


        }
    },
    //Qr Add 28/02/24
    button_card: {
        width: '30%',
        "@media only screen and (max-device-width: 480px)": {
            width: '45%',
        }
    },
    button_card1: {
        width: '30%',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'
        }
    },
    cong_tit2: {
        textAlign: 'center', color: '#ffffff !important', fontFamily: 'cardiuma-M !important', fontSize: '28px !important', width: '50%', fontWeight: '400',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '18px !important',
            marginTop: '10%',

        },
    },
    cong_card3: {
        width: '100%', marginTop: '1%', display: 'flex', justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '2%',
        },
    },
    cong_card: {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: 'auto',

        outline: 'none',
        p: 4,
        "@media only screen and (max-device-width: 480px)": {
            top: '15%',
            left: '0%',
            transform: 'none'
        }
    },
    cong_tit1: {
        textAlign: 'center', color: '#BCD171', fontFamily: 'cardiuma-Bold !important', fontSize: '42px !important',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '32px !important'
        },


    },
})

class signature extends Component {

    constructor(props) {
        super(props)

        this.signref = createRef();
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,

            emailid: '',
            pageno: 0,
            qrid: 0,
            islodding: false,


        }



    }
    signpadclear() {
        this.signref.clear()
    }
    closeContactModal = () => {
        this.setState({ showerr: false })
    }
    Continue_sign = async () => {
        let trimmedDataURL = ''
        let isok = 0
        trimmedDataURL = this.signref.getCanvas().toDataURL("image/jpeg", 100)

        let emailid, mobileno, aadharno = ""
        console.log('trimmedDataURL', trimmedDataURL.toString().length)
        if (trimmedDataURL.toString().length < 7000) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Signature is too Short", mysnack: snackeror.myerror })

        }
        if (isok === 0) {
            let payload_new = {
                "qrid": this.state.qrid,
            }
            const qrdata = await httpRequest({
                endPoint: `/api/qrid_data`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload_new,
            });
console.log(payload_new)
            if (qrdata.status === 200) {
                emailid = qrdata.emailid
                mobileno = qrdata.mobileno
console.log(qrdata.aadhaarno)
                aadharno = CryptoJS.AES.decrypt(qrdata.aadhaarno, "96358214256984120").toString(CryptoJS.enc.Utf8).substring(8, 12)
                console.log(aadharno)
            }


            this.setState({ islodding: true })
            let payload = {
                "sign_img": trimmedDataURL,
                "emailid": emailid,
                "mobileno": mobileno,
                "aadhar": aadharno //To get last four digits of Aadhaar. Hey Cortana, Yeah. 
            }
            let backoffice = {


                "mobileno": mobileno,
                "emailid": emailid,
                "type": 0

            }
            try {
                const otp = await httpRequest({
                    endPoint: `/api/verfy/qrsignature`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({ islodding: false })
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                if (otp.status === 200) {
                    this.setState({ islodding: false })

                    this.setState({ pageno: 1 })
                   

                } else {
                    this.setState({ pageno: 1 })
                }
            } catch (error) {
                this.setState({ pageno: 1 })

                // this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
        }
    }
    componentDidMount = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        console.log('refno', urlParams.get('refno'))
        let dedata = CryptoJS.AES.decrypt(urlParams.get('refno'), "963").toString(CryptoJS.enc.Utf8)
        //  dedata=dedata.split('*')
        console.log("ano", dedata)
        this.setState({ qrid: dedata })


    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.pageno === 1 &&
                    <div>
                        <Grid className={classes.cong_card}>
                            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <img src={cong_bull} alt="congbull"></img>
                            </Grid>

                            <Grid className={classes.cong_card3}>
                                <Typography className={classes.cong_tit2} >Thank you! You have completed the signature review.</Typography>
                            </Grid>


                        </Grid>
                    </div>
                }

                {this.state.pageno === 0 &&
                    <Grid className={classes.page2card_1} style={{ marginLeft: '5%' }} >
                        <Typography className={classes.card_tit}>Signature</Typography>




                        <Grid style={{ marginTop: '5%' }}>
                            <SignatureCanvas penColor='black'

                                velocityFilterWeight='0.1'


                                imageType="image/jpeg"
                                backgroundColor='white'
                                ref={(ref) => { this.signref = ref }}
                                canvasProps={{ width: window.innerWidth < 960 ? 350 : 500, height: 300 }} />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' ,alignItems:'center',marginTop:'2%'}}>

<WhiteBackgroundCheckbox
                                        checked={true}
                                      
                                    ></WhiteBackgroundCheckbox>
                                    <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '16px' }}>I am an Indian citizen, For tax purposes I am not resident in any jurisdiction(s) outside India (FATCA/CRS Declaration)</Typography>
                                    </Grid>
                        {/*Qr Add 28/02/24*/}

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                            <Grid style={{ width: '90%', display: 'flex', justifyContent: 'space-evenly' }}>



                                <Grid className={classes.button_card}>
                                    <Button fullWidth className={classes.joinDiplomtsBtn}
                                        style={{ marginTop: '15px', }}
                                        onClick={() => this.signpadclear()}
                                    >Clear

                                    </Button>

                                </Grid>

                                <Grid className={classes.button_card}>
                                    <Button fullWidth className={classes.joinDiplomtsBtn}
                                        style={{ marginTop: '15px' }}
                                        onClick={() => this.Continue_sign()}
                                    >Continue

                                    </Button>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                }
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    style={{marginBottom:'10%'}}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>

            </div>
        )
    }
}
export default (withStyles(styles)((signature)));