import React, { Component } from 'react'
import { withStyles, styled } from "@mui/styles";

import {
    Grid, Tab, Tabs,Typography,Table, TableHead, TableCell, TableRow, TableBody
} from '@mui/material'

import Footer from '../landingpage/fotter'

const StyledTabs = styled(Tab)({
    '&.Mui-selected': {
        fontFamily: 'cardiuma-Bold !important',
    },
});


const styles = theme => ({
    buttonlabel: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {

            cursor: 'pointer'

        },
        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important',fontSize: '12px !important',
        }
    },
    buttonlabel_bold: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important'

    },
    buttonlabel_bold1: {
        color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'
        },

    },
    p_card:{
        width: '90%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
        }
    },
    p_tab_back:{
        background: '#F7F9FA !important',
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important',
        }

    },
    p_tab_back1:{
        background: '#ffffff', height: 'auto', padding: '32px', borderRadius: '8px', marginTop: '15px' ,
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important',borderRadius: '0px', paddingTop: '15px'
        }

    },
    main_tab:{
        paddingTop: '100px', height: 'auto',
        "@media only screen and (max-device-width: 480px)": {
            paddingTop: '70px', height: 'auto',
        }
    },
    heading: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important',
        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '20px !important'
        }

    },
    p_body: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",
        "@media only screen and (max-device-width: 480px)": {
            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    },
    p_1:{
        padding: '20px 0px 0px 0px',
        "@media only screen and (max-device-width: 480px)": {
            padding: '2px 0px 0px 0px',
        }


    },
    p_body1: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important', lineHeight: "22px !important",
        "@media only screen and (max-device-width: 480px)": {
            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    }
})
class fatca extends Component {
    constructor(props) {
        super(props)


        this.state = {


            tabvalue: 0
        }



    }
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2"
        if (window.innerWidth < 960) {
            document.body.style.backgroundColor = "#1B2636"
        }

       
        if (window.location.pathname === "/Privacy_Policy") {
            this.setState({ tabvalue: 0 })
        }
        if (window.location.pathname === "/refund_policy") {
            this.setState({ tabvalue: 1 })
        }
        if (window.location.pathname === "/termsandconditions") {

            this.setState({ tabvalue: 2 })
        }
        if (window.location.pathname === "/cdsl") {

            this.setState({ tabvalue: 3 })
        }
       
    }
    handleTabChange = (event, value) => {
        this.setState({ tabvalue: value });
    };

    render() {
        const { classes } = this.props;
        const { tabvalue } = this.state
        return (
            <div className={classes.main_tab}>
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '50px' }}>
                    <Grid className={classes.p_card}>

                        {tabvalue === 0 &&
                            <div className={classes.p_tab_back1}>
                                <div style={{ height: 'auto' }}>
                    <Grid className={classes.p_1}  >
                    <Typography className={classes.heading} style={{ textAlign: 'center'}}  >
                     FATCA & CRS- SELF CERTIFICATION FORM FOR INDIVIDUALS
                    </Typography>
                    
                    <Typography className={classes.p_body1} style={{marginTop: '10px' }} >
                        CBDT Terms and Conditions
                    </Typography>
                    
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    The Central Board of Direct Taxes has notified Rules 114F to 114H, as part off the Income Tax Rules, 1962, which required Indian Financial Institution to seek additional personal tax and beneficial owner information and certain certifications and documentation from customers. In relevant cases, information will have to be reported to tax authorities / appointed agencies. Towards compliance we may also be required to provide information to any institutions.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    Should there be any change in any information provided by you, please ensure you advise us promptly i.e., within 30 days.
                    </Typography>
                    
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    Please note that you may receive more than one request for information if you have multiple relationships with us or our group entities. Therefore, it is important that you respond to our request, even if you believe you have already supplied any previously requested information.
                    </Typography>
                    
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    It is mandatory to supply a TIN or functional equivalent if the country/territory in which you are tax resident issues such identifiers. If no TIN or functional equivalent is yet available or has not yet been issued, please provide an explanation as mentioned above in the form.
                    </Typography>
                    
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    In case investor has the following Inidicia pertaining to a foreign country/territory and yet declares self to be non-tax resident in the respective country/territory, investor to provide relevant Curing Documents as mentioned below.
                    </Typography>  


                    <Table style={{marginTop : "30px"}} variant = "plain" borderAxis = "bothBetween">
                        <TableHead >
                            <TableRow >
                                <TableCell style={{border : "1px solid black", borderRight : "none",}}></TableCell>
                                <TableCell className={classes.p_body} style={{textAlign : "center", border : "1px solid black", fontWeight : "bold" , borderLeft : "none", paddingRight : "60px"}}>
                                    FATCA / CRS Indicia observed (ticked)
                                </TableCell>
                                <TableCell className={classes.p_body} style={{textAlign : "center",fontWeight : "bold" , border : "1px solid black"}}>
                                Documentation required for Cure of FATCAI / CRS indicia
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow >
                                <TableCell style={{border : "1px solid black" , borderRight : "none" , }}>

                                </TableCell>
                                <TableCell style={{border : "1px solid black" , borderLeft : "none"}}>
                                    
                                </TableCell>
                                <TableCell className={classes.p_body} style={{textAlign : "center",border : "1px solid black"}}>
                                    if customer does not agree to be Specified U.S person / reportbale person status
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" ,border : "1px solid black"}}>
                                    1
                                </TableCell>
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" , border : "1px solid black" ,padding : ""}}>
                                    U.S place of birth
                                </TableCell>
                            
                                <TableCell className={classes.p_body} style={{border :"1px solid black"}}>
                                1. Self-certification  (in attached format) that the account holder is neither a citizen of United States of America nor a resident for tax purposes:<br/>2. Non-US passport or any non-US government issued document evidencing nationality or citizenship (refer list below): <b>AND</b>
                                <br/>3. Any one of the following documents:
                                <br/> a. Certified Copy of "Certificate of Loss of Nationality or
                                <br/>b. Reasonable explanation of why the customer does not have such a certificate despite renouncing US citizenship; or Reason the customer did not obtain U.S. citizenship at birth
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" , border:"1px solid black"}}>
                                    2
                                </TableCell>
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" ,border : "1px solid black"}}>
                                    Residence/mailing address in a country other than India
                                </TableCell>
                                    
                                <TableCell className={classes.p_body} style={{border :"1px solid black"}}>
                                1. Self-certification (in attached format) that the account holder is neither a
                                	citizen of United States of America nor a resident for tax purposes: <b>and</b>
                                <br/>2. Documentary evidence (refer list below)

                                </TableCell>
                            </TableRow>

                            <TableRow>
                                
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" , border : "1px solid black"}}>
                                    3
                                </TableCell>
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" , border : "1px solid black"}}>
                                Telephone number in a country other than India (and no telephone number in India provided)
                                </TableCell>
                                    
                                <TableCell className={classes.p_body} style={{border :"1px solid black"}}>
                                1. Self-oertification (in attached format) that the account holder is neither a citizen of United States of America nor a resident for tax purposes; <b>and</b> <br/>2 Documentary evidence (refer list below)
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" , border : "1px solid black"}}>
                                    4
                                </TableCell>
                                <TableCell className={classes.p_body} style={{fontWeight : "bold" , border :"1px solid black"}}>
                                Standing instructions to transfer funds to an account maintained in a country other than India
                                </TableCell>
                                   
                                <TableCell className={classes.p_body} style={{border :"1px solid black"}}>
                                1. Self-certification (in attached format) that the account  holder is neither a citizen of United States ofAmerica nor a resident for tax purposes: 
                                <b> and</b> <br/>2. Documentary evidence (refer list below)
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Typography className={classes.p_body1} style={{marginTop: '30px' }} >
                    List of acceptable documentary evidence needed to establish the residence(s) for tax purposes:
                    </Typography>
                    <Typography className={classes.p_body} style={{marginTop: '10px' }} >
                    1. Certificate of residence issued by an authorized government body*
                    <br/> 2. Valid identification issued by an authorized government body* (e.g. Passport, National Identity card etc.)

                    </Typography>

                    <Typography className={classes.p_body1} style={{marginTop: '10px', fontStyle : "italic" }} >
                     *Government or agency there of or a municipality
                    </Typography>

                    <Typography className={classes.p_body} style={{marginTop: '30px' }} >
                    <b>Registered Office</b> : 911, Tulsiani Chambers, Free Press Journal Marg. Nariman Point. Mumbai 400 021. <br/><b>Phone</b> : 022 6632 5373 - 77 <b>Customer Care</b> : 022 6996 4600<br/>
                    <b>Email</b> : maliram.makharia@gmail.com /mmfinstock@rediffmail.com <b>Website</b> : www.bullforce.co
                    </Typography>
                </Grid>

            </div >
    </div>
    }
                     

                    </Grid>
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Footer />
                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((fatca)));