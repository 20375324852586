// New page created -13/09/24 - Dev Srini

import { useEffect, useState, useCallback,   } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { Typography, Grid, useMediaQuery, Box, Divider, TextField, Button, Link } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// optimze re renders - 16-09-24 - Dev Srini
import { debounce } from 'lodash'

import './campaign.css'

import logo from '../../ui-assets/adcampone.png'
import logo2 from '../../ui-assets/adcamptwo.svg'
import congBull from '../../ui-assets/new_bull.svg'
import aniGif from '../../ui-assets/Bullbg.gif'

import { httpRequest } from '../../ui-utils/api'
import { publicIpv4} from 'public-ip'


const Campaign = () => {
    const isSmallScreen = useMediaQuery('(max-width:950px)')
    // removed - 16-09-24 - Dev Srini
    
    const is1000px = useMediaQuery('(min-height:1000px)')


    const history = useHistory()

    const [userInfo, setUserInfo] = useState('')
    const [toggle, setToggle] = useState(false)
    const [anime, setAnime] = useState(true)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // Regular expressions for email and mobile validation - 16-09-24 - Dev Srini
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const phoneRegex = /^[0-9]{10}$/

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const referrer = queryParams.get('referral')

    // post api integrated for user count - 16/09/24 - Dev Srini
    useEffect(() => {
        const postVistorsData = async () => {
            try {
                let ipAddress = await publicIpv4()

                const result = await httpRequest({
                    endPoint: `api/users/count`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: {
                        ipAddress, pageName: "ad_campaign"
                    }
                })

                console.log(result, "PAGE VISITORS")

            }

            catch (err) {
                console.log("Error in visitors post API: ", err)
            }
        }

        if (window.location.pathname === '/ad_campaign') {
            postVistorsData()
        }

    }, [])
    

    useEffect(() => {
        if (isSmallScreen) {
            window.Changemytab(5)
        }
    }, [isSmallScreen])

    useEffect(() => {
        if(toggle){
            setTimeout(() => {
                setAnime(!anime)
            }, 10000)
        }
    }, [toggle])

    // validation for mobile / email - 16-09-24 - Dev Srini
    const validateInput = useCallback(
        debounce((value) => {
            console.log('rendering')
          if (!emailRegex.test(value) && !phoneRegex.test(value)) {
            setErrorMessage('Please enter a valid email or phone number');
          } else {
            setErrorMessage('');
          }
        }, 500), // 500ms delay to debounce input
        []
    )

    const handleChangeUser = useCallback((e) => {
        setUserInfo(e.target.value)
        validateInput(e.target.value)
    }, [])

    const handleSubmit = async () => {
        if(userInfo.trim().length === 0){
            setError(true)
            setErrorMessage('Email / Phone is require')
        }
        else if (errorMessage) {
            setError(true)
        }
        else{
            // referr added in payload - 16/09/24 - Dev Srini
            let ipAddress = await publicIpv4()
            let campaignLink = `${history.location.pathname} ${referrer ? referrer : ""}`
            try{
                const result = await httpRequest({
                    endPoint: `api/user/campaign`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: {
                      ipAddress, phoneEmail:userInfo, campaignLink
                    }
                  })
                setToggle(true)
                setError(false)
                setErrorMessage('')
                console.log(result, "Social Campaign")
            }
            catch(err){
                console.log("Error in Campaign, post API: ", err)
            }
        }
    }
    
    if (isSmallScreen) {
        return (
            <Grid container sx={{ 
                background: "#1B2636",
                }}>
                <Grid item>
                    <Grid container padding={1} spacing={0.5} sx={{ background: "#0B1420", width: "auto", height: "auto" }}  >
                        <Grid
                            container
                            item
                            xs={12}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexWrap: 'nowrap' }}
                        >
                            <img src={logo2} alt='logo' width={70} style={{ margin: "5px 0 0 0px" }} />
                            <img src={logo} alt='logo' width={160} style={{ margin: "20px 0 0 10px" }} />

                            <Grid
                                item
                                sx={{ display: 'flex', margin: '0 0 0 10px', }}
                            >
                                <Typography
                                    className='since'
                                    sx={{ margin: '0 0 -5px 0', whiteSpace: 'nowrap' }}
                                >
                                    Since 1996
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Divider className='divdr' />
                        </Grid>

                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography className='malli'>
                                MALIRAM MAKHARIA FINSTOCK PRIVATE LIMITED
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {toggle ? (
                    <>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 5,
                                    position:'relative',
                                }}
                            >
                                <img src={congBull} alt='congBull' style={{position:'relative'}}  />
                                {anime && <img 
                                    src={aniGif} alt='congBull' 
                                    style={{position:'absolute', top: '30px',}}  
                                />}
                            </Box>

                            <Grid
                                item
                                textAlign='center'

                            >
                                <Typography
                                    className='weoffrweb'
                                >
                                    Thank You Very Much
                                </Typography>
                            </Grid>

                            <Grid
                                item
                            >
                                <Typography
                                    className='shemrph'
                                    padding={4}
                                    textAlign='center'
                                >
                                    Our team will connect with you soon for further communication
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{ textAlign: 'center', background: "#0B1420" }}
                            >
                                <Typography
                                    className='shemrph'
                                    padding={3}
                                >
                                    OR
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={2}
                                sx={{ padding: '0 0 30px 0', background: "#0B1420" }}
                            >
                                <Button
                                    className='subbtn'
                                    onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                                >
                                    Sign Up Now
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{ textAlign: 'center', background: "#0B1420" }}
                            >
                                <Link
                                    className='explor'
                                    href='https://bullforce.co/'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Explore More
                                    <ArrowForwardIosIcon sx={{ fontSize: '18px' }} />
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                            <Grid
                                item
                                className='bgimg'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    className='bgbox'
                                >
                                    <Typography
                                        className='bgbxtxt'
                                    >
                                        We are one of the most experienced stock Broking houses of India
                                    </Typography>
                                </Box>

                                <Box
                                    className='bgbox1'
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginTop: '14px',
                                            position: 'relative',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                height: '1px',
                                                background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, #F3D45E 100%)',
                                                marginRight: '8px',
                                            }}
                                        />
                                        <Typography
                                            className='weoffr'
                                        >
                                            We Offer
                                        </Typography>
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                height: '1px',
                                                background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 0%, #F3D45E 100%)',
                                                marginLeft: '8px',
                                            }}
                                        />
                                    </Box>

                                    <Grid container display='flex' 
                                    justifyContent='flex-start' padding={0.5} gap={4} ml="5px">
                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            className='offrtxt'
                                        >
                                            <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                            Stock Broking
                                        </Typography>

                                        <Typography
                                            sx={{ display: 'flex', alignItems: 'center' }}
                                            className='offrtxt'
                                        >
                                            <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                            Demat Account
                                        </Typography>
                                    </Grid>


                                    <Grid container gap='10px' padding={0.5} flexDirection='column' ml="5px">
                                        <Grid item>
                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center', }}
                                                className='offrtxt'
                                            >
                                                <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                                Expert Advice By SEBI Registered Advisors
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center', }}
                                                className='offrtxt'
                                            >
                                                <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                                Wealth Vaults
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Grid
                                container
                                justifyContent='center'
                                sx={{
                                    position: 'relative'
                                }}
                            >
                                <Typography
                                    className='shemrph'
                                    padding={0.5}
                                    textAlign='center'
                                >
                                   Connect with us
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={1}
                            >
                                <Grid item textAlign="center">
                                    <TextField
                                        className="textfld"

                                        id="outlined-basic"
                                        label="Email / Phone Number"
                                        variant="outlined"

                                        InputLabelProps={{
                                            sx: {
                                                color: '#fff',
                                                fontSize: '16px',
                                                fontFamily: 'cardiuma-regular',
                                                fontWeight: 400,
                                                // padding: 1
                                            },
                                        }}

                                        value={userInfo}
                                        onChange={(e) => handleChangeUser(e)}
                                    />

                                    {error && <Typography
                                        sx={{ fontFamily: 'cardiuma-regular', fontSize: '14px', fontFamily: '400', color: 'red', padding: '10px' }}
                                    >{errorMessage}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={error ? 0 : 2}
                            >
                                <Button
                                    className='subbtn'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{ textAlign: 'center' }}
                            >
                                <Typography
                                    className='shemrph'
                                    padding={1}
                                >
                                    OR
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={3}
                                sx={{ padding: '0 0 100px 0' }}
                            >
                                <Button
                                    className='subbtn'
                                    onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                                >
                                    Sign Up Now
                                </Button>
                            </Grid>
                    </>
                )}
            </Grid>
        )
    }
    else {
        return (
            <Grid container sx={{ background: "#1B2636", minHeight: '100vh' }}>

                {/* Left Section */}
                <Grid
                    item
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        className='bgimg1'
                        display='flex'
                        justifyContent='center'
                        mt='50px'
                    >
                        <Box
                            className='bgboxweb'
                        >
                            <Typography
                                className='bgbxtxtweb'
                            >
                                We are one of the most experienced stock Broking houses of India
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                {/* Right Section */}
                <Grid
                    item
                    md={6}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            background: toggle ? '#0B1420' : '#1B2636',
                        }}
                    >
                        {toggle ? (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '16px',
                                        position: 'relative',
                                        top: "12%"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            height: '1px',
                                            background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 25%, #F3D45E 100%)',
                                            marginRight: '30px',
                                        }}
                                    />
                                    <Typography
                                        className='weoffrweb'
                                    >
                                        Thank You Very Much
                                    </Typography>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            height: '1px',
                                            background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 25%, #F3D45E 100%)',
                                            marginLeft: '30px',
                                        }}
                                    />
                                </Box>

                                <Grid
                                    container
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection:'column',
                                        position: 'relative',
                                        top: '11%',
                                    }}
                                >

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: 5,
                                            position: 'relative',
                                        }}
                                    >
                                        <img src={congBull} alt='congBull' style={{ position: 'relative' }} />
                                        {anime && <img
                                            src={aniGif} alt='congBull'
                                            style={{ position: 'absolute', top: '0px', }}
                                        />}
                                    </Box>

                                    <Grid
                                        item
                                    >
                                        <Typography
                                            className='shemrph'
                                            padding={5}
                                            textAlign='center'
                                        >
                                            Our team will connect with you soon for further communication
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        flexDirection="column"
                                        sx={{
                                            textAlign: 'center',
                                            background: "#1B2636",
                                            width: '100%',
                                            height: '200px'
                                        }}
                                        className='sgnupnw'
                                    >
                                        
                                        <Button
                                            className='subbtn'
                                            onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                                            sx={{marginTop: "20px"}}
                                        >
                                            Sign Up Now
                                        </Button>

                                        <Link
                                            className='explor'
                                            href='https://bullforce.co/'
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ padding: '30px'}}
                                        >
                                            Explore More
                                            <ArrowForwardIosIcon sx={{ fontSize: '18px' }} />
                                        </Link>
                            
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            <>
                                    <Grid container padding={1} gap='5px' sx={{
                                        background: "#0B1420", width: "auto", height: "auto",
                                        position: 'relative',
                                        top: '65px',
                                    }}  >
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={logo2} alt='logo' width={is1000px ? 90 :70} style={{ margin: "10px 0 5px 0" }} />
                                            <img src={logo} alt='logo' width={ is1000px ? 200 : 160} style={{ margin: "25px 0 0 5px" }} />
                                        </Grid>

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center',  }}>
                                            <Divider className='divdr' />
                                        </Grid>

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography className='malli' >
                                                MALIRAM MAKHARIA FINSTOCK PRIVATE LIMITED
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', }}>
                                            <Typography className='sinceweb'>
                                                Since 1996
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        mt='65px'
                                        sx={{
                                            background: "#0B1420", width: "auto", height: "auto",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: '16px',
                                                position: 'relative',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    height: '1px',
                                                    background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 25%, #F3D45E 100%)',
                                                    marginRight: '30px',
                                                }}
                                            />
                                            <Typography
                                                className='weoffrweb'
                                            >
                                                We Offer
                                            </Typography>
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    height: '1px',
                                                    background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 25%, #F3D45E 100%)',
                                                    marginLeft: '30px',
                                                }}
                                            />
                                        </Box>

                                        <Grid container display='flex' justifyContent='center' padding={1} gap={3}>
                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                className='offrtxtweb'
                                            >
                                                <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '5px' }} />
                                                Stock Broking
                                            </Typography>

                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                className='offrtxtweb'
                                            >
                                                <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '5px' }} />
                                                Demat Account
                                            </Typography>

                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                className='offrtxtweb'
                                            >
                                                <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '5px' }} />
                                                Wealth Vaults
                                            </Typography>

                                            <Typography
                                                sx={{ display: 'flex', alignItems: 'center', }}
                                                className='offrtxtweb'
                                                mr='90px'
                                                mb='10px'
                                            >
                                                <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '5px' }} />
                                                Expert advise by SEBI registered advisors
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                    >
                                        <Typography
                                            className='shemrph'
                                            padding={3}
                                            textAlign='center'
                                        >
                                            Please share your email / phone number.
                                            One of our executive will connect and complete the process
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        padding={1}
                                    >
                                        <Grid item textAlign="center">
                                            <TextField
                                                className="textfld"

                                                id="outlined-basic"
                                                label="Please Email / Phone Number"
                                                variant="outlined"

                                                InputLabelProps={{
                                                    sx: {
                                                        color: '#fff',
                                                        fontSize: is1000px ? '20px' : '16px',
                                                        fontFamily: 'cardiuma-regular',
                                                        fontWeight: 400,
                                                        padding: is1000px && 1
                                                    },
                                                }}

                                                value={userInfo}
                                                onChange={(e) => handleChangeUser(e)}
                                            />
                                            {error && <Typography
                                                sx={{ fontFamily: 'cardiuma-regular', fontSize: '14px', fontFamily: '400', color:'red', padding: '10px' }}
                                            >{errorMessage}</Typography>}
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        padding={error ? 2 : 1}
                                    >
                                        <Button
                                            className='subbtn'
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Typography
                                            className='shemrph'
                                            padding={2}
                                        >
                                            OR
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        padding={3}
                                        sx={{ padding: '0 0 50px 0' }}
                                    >
                                        <Button
                                            className='subbtn'
                                            onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                                        >
                                            Sign Up Now
                                        </Button>
                                    </Grid>
                            </>
                        )}
                    </Box>
                </Grid>

            </Grid>
        )
    }
}

export default Campaign