import React, { Component } from 'react'

export default class index extends Component {
    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '100px', height: '100vh', background: '#ffffff' }}>
                <h2> Coming soon </h2>

            </div>
        )
    }
}
