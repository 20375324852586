import React, { Component } from 'react'
import { Grid, Typography, Button, TextField, Snackbar, SnackbarContent,
     IconButton, FormControl, FormHelperText ,Hidden} from '@mui/material'
import bull from "../../ui-assets/bull.svg";
import { Phone, Email, Close } from '@mui/icons-material';
import { FileUploader } from "react-drag-drop-files";
import { withStyles } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import how1 from '../../ui-assets/how1.svg'
import how2 from '../../ui-assets/how2.svg'
import how3 from '../../ui-assets/how3.svg'
import how4 from '../../ui-assets/how4.svg'
import Secure from '../../ui-assets/Secure.svg'
import how1_m from '../../ui-assets/img1_m.svg'
import how2_m from '../../ui-assets/img2_m.svg'
import how3_m from '../../ui-assets/img3_m.svg'
import how4_m from '../../ui-assets/img4_m.svg'
import arr_down from '../../ui-assets/arr_down_m.svg'

import adown from '../../ui-assets/arrowdown.svg'
import aup from '../../ui-assets/arrowup.svg'
import Fotter from '../landingpage/fotter'
import validator from 'validator'
import upload from '../../ui-assets/upload.svg'
import openfileimg from '../../ui-assets/openfolder.svg'

// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Contact us | BullForce',
    description: "Bullforce Support: Get 24/7 assistance for trading, demat accounts, and investments. Contact us for expert help and resolve issues quickly. We're here to support you.",
    canonical: 'https://bullforce.co/contactus',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, Support, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, , SEBI, investments, Wealth'
        }
    }
}

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};


const styles = theme => ({

    DiplomtsBtn: {
        background: "#F74542 !important",
        padding: "15px !important",
        borderRadius: "30px !important",
        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        "@media only screen and (max-device-width: 480px)": {
            
            padding: "10px !important",
            fontWeight: "100 !important",
            fontSize: "16px !important",
           
        }

    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#FFFFFF'

        }
    },
    how_tit: {
        color: "#3E3E3E !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        fontSize: "40px !important",
        lineHeight: "normal !important",
        fontFamily: "cardiuma-Bold !important",
        textAlign: 'center',

        // h1 tags margin start & end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media only screen and (max-device-width: 480px)": {
            color: "#FFFFFF !important",
            fontSize: "24px !important",
            fontWeight: "500 !important",
        }


    },
g1:{
    width: '100%', display: 'flex', alignItems: 'center', padding: '80px 80px 0px 20px',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',padding:'80px 1px 10px 1px'

    }
},
    maingrid:{
        width: '60%', padding: '5px 35px 35px 35px',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',padding:'0px'
    
        }
    },
contus_txt:{
    

    color: "#BCD171 !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "32px !important",
    lineHeight: "normal !important",
    fontFamily: "cardiuma-Bold !important",
    marginBlockStart: 0,

    "@media only screen and (max-device-width: 480px)": {
        fontSize: '24px !important',
        fontWeight: "400 !important",
        fontFamily: "cardiuma-M !important",
        padding:'0px 16px'

    }
},
bullimg:{
    width: '100%', marginTop: '1px' ,
    "@media only screen and (max-device-width: 480px)": {
       display:'none'

    }
},

qcrad:{
    width: '38%', background: '#1B2532', padding: '30px 40px', borderRadius: '8px',  marginLeft: '2%',
    "@media only screen and (max-device-width: 480px)": {
        display:'none'
 
     }
},
qcrad1:{
 
    display:'none',
 
    "@media only screen and (max-device-width: 480px)": {
        display:'inline',  width: '50%'
 
     }
},
bull_tit:{
    color: '#F3B95B', fontFamily: 'cardiuma-M !important', fontSize: '16px',
    "@media only screen and (max-device-width: 480px)": {
        display:'inline',  width: '50%'
 
     }
},
add_card_main:{
    width: '100%', marginTop: '1px', display: 'flex',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',display: 'inline',
 
     } 
},
add_card:{
    width: '50%', padding: '5px',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',padding:'16px'
 
     }
},
reg_main_card:{
    width: '100%', padding: '1px', display: 'flex', background: '#1B2532', borderRadius: '6px',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',borderRadius: '0px',  background: '#1B2532',display:'block'
 
     }
},

phone_card_main:{
width: '100%', padding: '1px', display: 'flex',
"@media only screen and (max-device-width: 480px)": {
    width: '100%', padding: '16px', display:'block'

 }
},

phone_card:{
    width: '50%', padding: '15px', display: 'flex', alignItems: 'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', padding: '16px', display:'block'
 
     }
},

how_main_card:{
    background: '#ffffff', width: '100%', padding: '50px 0px', display: 'flex', justifyContent: 'center',marginTop:'20px',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', padding: '16px', display:'block',
        background: '#1B2636',  justifyContent: 'center',
        
 
     }

},
how_main_card1:{
background: '#ffffff', width: '100%', display: 'flex', justifyContent: 'center', paddingLeft: '4%',
"@media only screen and (max-device-width: 480px)": {
    display:'block',width: '100%',
        background: '#1B2636',  justifyContent: 'center',
        
}
},
img1:{
    width: '35%', display: 'flex', justifyContent: 'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
    }

},

img_arr1:{
    width: '13%', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginBottom: '-44px' ,
    "@media only screen and (max-device-width: 480px)": {
        display: 'none', marginBottom: '0px' ,
    }
},
img1_card:{
    width: '100%', display: 'flex',
    "@media only screen and (max-device-width: 480px)": {
        display: 'block',
    }

},
img2_card:{
    width: '40%', display: 'flex', justifyContent: 'flex-start' ,
    "@media only screen and (max-device-width: 480px)": {
        display: 'block', width: '100%',
    }

},
img2:{
    width: '100%', display: 'flex', marginTop: '25px' ,
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block', marginTop: '25px' 
    }
},
img2_arr:{
    width: '30%', display: 'flex', justifyContent: 'flex-end', marginTop: '-70px', marginLeft: '10px',
    "@media only screen and (max-device-width: 480px)": {
        display: 'none', 
}
},
img3:{
    width: '57%', display: 'flex', justifyContent: 'flex-end',
    "@media only screen and (max-device-width: 480px)": {
        display: 'block', width: '100%',
}
},
img3_mian:{
    width: '55%', display: 'flex', justifyContent: 'flex-start',
    "@media only screen and (max-device-width: 480px)": {
        display: 'block', width: '100%',
}

},
img4_arr:{
    width: '40%', display: 'flex', justifyContent: 'flex-end', marginTop: '-100px', 
    "@media only screen and (max-device-width: 480px)": {
        display: 'none', 
}
},
img4_main:{
    width: '43%', display: 'flex', justifyContent: 'flex-end',
    "@media only screen and (max-device-width: 480px)": {
        display: 'block', width: '100%',
}

},
img4:{
width: '70%', display: 'flex', justifyContent: 'flex-start' ,
"@media only screen and (max-device-width: 480px)": {
    display: 'block', width: '100%',
}
},
mat_main_card:{
    background: '#0B1420', width: '100%', padding: '45px 45px 10px 128px' ,
    "@media only screen and (max-device-width: 480px)": {
        padding:'16px',
    }
},
esc_tit:{
color: '#ffffff !important', fontFamily: 'cardiuma-Bold !important', fontSize: '32px !important',
marginBlockStart: 0, marginBlockEnd: 0,

"@media only screen and (max-device-width: 480px)": {
    fontSize: '20px !important',padding:'0px 16px'
}

},
mat_head:{
    background: '#0B1420', width: '100%', padding: '5px 128px 25px 128px',
    "@media only screen and (max-device-width: 480px)": {
        padding:'16px 0px',overflow:"auto"
    }
},
escalationhead:{
    color: '#333333 !important',
    fontFamily: 'cardiuma-M !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    
    textAlign: 'center !important',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '12px !important',
        fontWeight: '300 !important',
    }
},
escalationdel:{
    color: '#333333 !important',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    
    textAlign: 'center !important',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '12px !important',
        fontWeight: '300 !important',
    }

},
upload_tit1:{
    color: '#7A7C7F !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',paddingLeft:'5px'
},
aa:
{
    width:window.innerWidth-30+'px',
    overflow:'scroll'
}

});
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const fileTypes = ["JPG", "PNG", "GIF"];

class contactus extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            fname: '',
            phoneno: '',
            profession: '',
            errtype: 0,
            emailid:'',
            mw:'300px',
            filedata:'',
            filename:''
        }



    }
     handleChange  = async (file) => {

        console.log("File Uploaded", file)
     
       
        const base64 = await convertBase64(file);

        this.setState({filedata:base64,filename:file.name})
      
    };
    
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        document.body.style.backgroundColor = "#0B1420"
    }
    
    closeContactModal() {
        this.setState({ showerr: false })
    }
    Submitquery = async () => {

        const { fname, phoneno, profession ,emailid,filedata} = this.state

        let isok = 0

        if (!fname.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the  Name" })

        }




        if (!phoneno.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the Contact no" })

        }

        if (validator.isMobilePhone(phoneno,'en-IN') === false && isok === 0 ){

            isok = 1
            this.setState({errtype: 2, errorMsg: "Enter valid Contact no"})

        }

        if (!emailid.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 4, errorMsg: "Enter the E-Mail ID" })

        }

        if (validator.isEmail(emailid).length && isok === 0 ) {

            isok = 1
            this.setState({ errtype: 4, errorMsg: "Enter valid Email"})

        }

        if (!profession.length && isok === 0 ){

            isok = 1
            this.setState({ errtype: 3, errorMsg: "Enter the Description"})

        }

        if (isok === 0) {

            let payload = {
                fname: fname,
                phoneno: phoneno,
                description: profession,
                emailid:emailid,
                data:filedata
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/ticktes`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg: openaccount.message, mysnack: snackeror.mysucc,

                        fname: '', phoneno: '', profession: '',emailid:'',filedata:'',filename:''
                    })
                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }

        }

    }

    render() {
        const { classes } = this.props
        return (
            <div>
                <DocumentMeta {...meta} />

                <Grid className={classes.g1}>
                    <Grid className={classes.maingrid}>
                        <Grid style={{ width: '100%', }} >
                            {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                            <h1 className={classes.contus_txt} > Contact us</h1>
                        </Grid>

                        <Grid className={classes.bullimg} >
                            <img
                                src={bull}
                                alt="girlSitting"
                                height='18%'
                                width='18%'


                            />
                        </Grid>

                        <Grid  className={classes.qcrad1}>

                        <h3 style={{ color: '#FFFFFF', fontFamily: 'cardiuma-M', fontSize: '20px',padding:'16px', marginBlockStart: 0, marginBlockEnd: 0 }} >Have a query?</h3>
<Grid style={{width:'100%',display:'flex', justifyContent:'center',padding:'16px'}}>
<Grid style={{width:'100%',justifyContent:'center'}}>
<Grid style={{width:'100%'}}>

    <FormControl style={{ width: '100%' }} variant="standard">
        <TextField size="small" variant="standard"



            label='Name'
            fullWidth

            value={this.state.fname}
            sx={{
                '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


            }}
            InputLabelProps={{

                style: { color: this.state.errtype === 1 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


            }}
            InputProps={{
                classes: { input: classes.input },
                maxLength: 16,
                style: {  color: "#ffffff", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

            }}
            onChange={(e) => this.setState({ fname: e.target.value, errtype: 0 })}
        ></TextField>
        {this.state.errtype === 1 &&
            <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542  !important"}}>{this.state.errorMsg}</FormHelperText>
        }
    </FormControl>

</Grid>

<Grid style={{ marginTop: '10px' }}>
    <FormControl style={{ width: '100%' }} variant="standard">
        <TextField size="small" variant="standard"



            label='Contact no'
            fullWidth

            value={this.state.phoneno}
            sx={{
                '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


            }}
            InputLabelProps={{

                style: { color: this.state.errtype === 2 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


            }}
            InputProps={{
                classes: { input: classes.input },
                maxLength: 16,
                style: {  color: window.innerWidth < 960 ?"#ffffff": "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

            }}
            onChange={(e) => this.setState({ phoneno: e.target.value, errtype: 0 })}
        ></TextField>
        {this.state.errtype === 2 &&
            <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
        }
    </FormControl>

</Grid>
<Grid style={{ marginTop: '10px' }}>
    <FormControl style={{ width: '100%' }} variant="standard">
        <TextField size="small" variant="standard"



            label='Email ID'
            fullWidth

            value={this.state.emailid}
            sx={{
                '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


            }}
            InputLabelProps={{

                style: { color: this.state.errtype === 4 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


            }}
            InputProps={{
                classes: { input: classes.input },
                maxLength: 16,
                style: {  color: window.innerWidth < 960 ?"#ffffff": "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

            }}
            onChange={(e) => this.setState({ emailid: e.target.value, errtype: 0 })}
        ></TextField>
        {this.state.errtype === 4 &&
            <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
        }
    </FormControl>

</Grid>

<Grid style={{ marginTop: '10px' }}>

    <FormControl style={{ width: '100%' }} variant="standard">
        <TextField size="small" variant="standard"



            label='Query Description'
            fullWidth

            value={this.state.profession}
            sx={{
                '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


            }}
            InputLabelProps={{
                style: { color: this.state.errtype === 3 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


            }}
            InputProps={{
                classes: { input: classes.input },
                maxLength: 16,
                style: { color: window.innerWidth < 960 ?"#ffffff": "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

            }}
            onChange={(e) => this.setState({ profession: e.target.value, errtype: 0 })}
        ></TextField>
        {this.state.errtype === 3 &&
            <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
        }
    </FormControl>

<Grid style={{ width: '100%', marginTop: '20px' }}>
                                    <FileUploader
                                        style={{}}
                                        multiple={false}
                                        maxSize={'1mb'}
                                        hoverTitle="rAI"
                                        label="Upload or drop a file right here"

                                        //   onChange={(e) => this.handleChange(e)}
                                        handleChange={this.handleChange}
                                        name="file"
                                        types={fileTypes}
                                        children={
                                            <Grid style={{ padding: '15px',  width: '100%', background: '#1B2532', borderRadius: '8px', border: '1px dashed #999999' }}>
                                               
                                                <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                                                    <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                                                    <Typography style={{cursor:'pointer', color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Browse File</Typography>
                                                   
                                                </Grid>
                                               
                                            </Grid>
                                        }
                                    />
                                    <Grid style={{width:'100%',display:'flex'}}>

                                        <Grid style={{width:'75%'}}>
                                        <Typography className={classes.upload_tit1}>{this.state.filename}</Typography>
                                        </Grid>
                                        <Grid style={{width:'25%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                            <img src={Secure} alt="Secure"></img>
                                            <Typography className={classes.upload_tit1}>Secure</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
</Grid>
<Grid style={{ marginTop: '15px' }}>
    <Button fullWidth className={classes.DiplomtsBtn}

        onClick={() => this.Submitquery()}
    >Create a ticket</Button>
</Grid>
</Grid>
</Grid>
</Grid>
                        <Grid className={classes.add_card_main} >
                            <Grid className={classes.add_card}>
                                <Typography className={classes.bull_tit}>Bullforce</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '15px' }}>(Maliram Makharia Finstock Pvt. Ltd)</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '15px' }}>
                                    No. 911, 9TH Floor, Tulsiani Chambers,
                                    Free Press J-Marg, Nariman Point,
                                    MUMBAI - 400021, India</Typography>
                            </Grid>
                            <Grid className={classes.add_card}>
                                <Typography className={classes.bull_tit}>Bullforce</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '15px' }}>(Maliram Makharia Finstock Pvt. Ltd)</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '15px' }}>
                                    3rd Floor, Redifice Strada,
                                    International Airport Road, Aramane Nagar,
                                </Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '15px' }}>

                                    Bangalore - 560080, India.</Typography>
                            </Grid>

                        </Grid>
                        <Grid className={classes.reg_main_card} >
                        <Grid className={classes.add_card}>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>NSE Membership No.: 10756</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>BSE Membership No.: 632</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>CDSL Registration No.:IN-DP-753-2023</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>SEBI Registration No.: INZ000295634</Typography>

                            </Grid>
                            <Grid className={classes.add_card}>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>Company CIN: U67120MH1996PTC098037</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>Company PAN: AACCM1102E</Typography>
                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular ', fontSize: '14px' }}>Company GSTIN: 27AACCM1102E1Z7</Typography>
                                

                            </Grid>
                        </Grid>
                        <Grid className={classes.phone_card_main}>
                            <Grid className={classes.phone_card}>
                                <div style={{display:'flex',width: '100%'}}>
                                <a href="tel:02266325373">  <Phone style={{ color: '#BCD171', fontSize: '25px' }} /></a>
                              <a href="tel:02266325373">  <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular ', fontSize: '16px', marginLeft: '10px' }} >022-66325375</Typography>
                              </a>
                                </div>
                            </Grid>
                            <Grid className={classes.phone_card}>
                            <div style={{display:'flex',width: '100%'}}>

                            <a href = "mailto:CustomerCare@bullforce.co">
                                <Email style={{ color: '#BCD171', fontSize: '25px' }} />
                                </a>
                                <a href = "mailto:CustomerCare@bullforce.co">
                                <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular ', fontSize: '16px', marginLeft: '10px' }} > CustomerCare@bullforce.co</Typography>
                                </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid  className={classes.qcrad}>

                        <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-M', fontSize: '20px', marginLeft: '10px' }} >Have a query?</Typography>
                        <Grid style={{ marginTop: '10px', }}>

                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"



                                    label='Name'
                                    fullWidth

                                    value={this.state.fname}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                    }}
                                    InputLabelProps={{

                                        style: { color: this.state.errtype === 1 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular ', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular ', fontSize: '18px', height: '30px', },

                                    }}
                                    onChange={(e) => this.setState({ fname: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 1 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>

                        </Grid>

                        <Grid style={{ marginTop: '10px' }}>
                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"



                                    label='Contact no'
                                    fullWidth

                                    value={this.state.phoneno}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                    }}
                                    InputLabelProps={{

                                        style: { color: this.state.errtype === 2 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular ', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular ', fontSize: '18px', height: '30px', },

                                    }}
                                    onChange={(e) => this.setState({ phoneno: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 2 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>

                        </Grid>
                        <Grid style={{ marginTop: '10px' }}>
    <FormControl style={{ width: '100%' }} variant="standard">
        <TextField size="small" variant="standard"



            label='Email ID'
            fullWidth

            value={this.state.emailid}
            sx={{
                '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


            }}
            InputLabelProps={{

                style: { color: this.state.errtype === 4 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


            }}
            InputProps={{
                classes: { input: classes.input },
                maxLength: 16,
                style: {  color: window.innerWidth < 960 ?"#ffffff": "#FFFFFF", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

            }}
            onChange={(e) => this.setState({ emailid: e.target.value, errtype: 0 })}
        ></TextField>
        {this.state.errtype === 4 &&
            <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
        }
    </FormControl>

</Grid>

                        <Grid style={{ marginTop: '10px' }}>

                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"



                                    label='Query Description'
                                    fullWidth

                                    value={this.state.profession}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 3 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular ', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular ', fontSize: '18px', height: '30px', },

                                    }}
                                    onChange={(e) => this.setState({ profession: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 3 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text" sx={{color:"#F74542 !important"}}>{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>

                        </Grid>
                     
                        <Grid style={{ width: '100%', marginTop: '20px' }}>
                                    <FileUploader
                                        style={{}}
                                        multiple={false}
                                        maxSize={'1mb'}
                                        hoverTitle="rAI"
                                        label="Upload or drop a file right here"

                                        //   onChange={(e) => this.handleChange(e)}
                                        handleChange={this.handleChange}
                                        name="file"
                                        types={fileTypes}
                                        children={
                                            <Grid style={{ padding: '15px',  width: '100%', background: '#1B2532', borderRadius: '8px', border: '1px dashed #999999' }}>
                                               
                                                <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                                                    <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                                                    <Typography style={{cursor:'pointer', color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Browse File</Typography>
                                                   
                                                </Grid>
                                               
                                            </Grid>
                                        }
                                    />
                                    <Grid style={{width:'100%',display:'flex'}}>

                                        <Grid style={{width:'75%'}}>
                                        <Typography className={classes.upload_tit1}>{this.state.filename}</Typography>
                                        </Grid>
                                        <Grid style={{width:'25%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                            <img src={Secure} alt="Secure"></img>
                                            <Typography className={classes.upload_tit1}>Secure</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        <Grid style={{ marginTop: '15px' }}>
                            <Button fullWidth className={classes.DiplomtsBtn}

                                onClick={() => this.Submitquery()}
                            >Create a ticket</Button>
                        </Grid>
                    </Grid>
                    <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                        
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}
                    sx={{ position: "absolute" }}
                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M "
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>

                </Grid>

                <Hidden only={["xs", "sm"]}>
                <Grid className={classes.how_main_card}>
                    <h1 className={classes.how_tit}>How it works</h1>
                </Grid>
                <Grid className={classes.how_main_card1}>

                    <Grid style={{ width: '100%' }}>

                        <Grid  className={classes.img1_card}>
                            <Grid className={classes.img1}>

                                <img src={how1} alt="how1" style={{ height: '85%' }}></img>

                            </Grid>
                            <Grid  className={classes.img_arr1}>

                                <img src={aup} alt="uparrow" style={{ height: '60%' }}></img>

                            </Grid>
                            <Grid className={classes.img2_card}>

                                <img src={how2} alt="how" style={{ height: '75%' }}></img>

                            </Grid>
                        </Grid>

                        <Grid className={classes.img2}>
                            <Grid className={classes.img3}>
                                <Grid  className={classes.img2_arr}>
                                    <img src={adown} alt="downarrow" style={{ height: '60%', }}></img>
                                </Grid>
                                <Grid className={classes.img3_mian}>
                                    <img src={how3} alt="how3" style={{ height: '85%' }} ></img>
                                </Grid>


                            </Grid>
                            <Grid  className={classes.img4_main}>
                                <Grid  className={classes.img4_arr}>
                                    <img src={adown} alt="uparrow" style={{ height: '60%', }}></img>
                                </Grid>
                                <Grid  className={classes.img4}>
                                    <img src={how4} alt="how4" style={{ height: '75%' }}></img>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </Hidden>   


                <Hidden only={["lg", "md", "xl"]}>
                <Grid className={classes.how_main_card}>
                    <h1 className={classes.how_tit}>How it works</h1>
                </Grid>
                <Grid style={{background:'#1B2636'}}>
                    <Grid style={{width:'100%',display:'flex',justifyContent:'center',paddingTop:'25px'}}>
                    <img src={how1_m} alt='img1' height='50%' width='50%'></img>
                    </Grid>
                    <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
                    <img src={arr_down} alt='img1' ></img>
                    </Grid>
                    <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
                    <img src={how2_m} alt='img1' height='50%' width='50%'></img>
                    </Grid>
                    <Grid style={{width:'60%',display:'flex',justifyContent:'flex-end'}}>
                    <img src={arr_down} alt='img1' ></img>
                    </Grid>
                    <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
                    
                    <img src={how3_m} alt='img1' height='50%' width='50%'></img>
                    </Grid>
                    <Grid style={{width:'60%',display:'flex',justifyContent:'flex-end'}}>
                    <img src={arr_down} alt='img1' ></img>
                    </Grid>
                    <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
                   
                    <img src={how4_m} alt='img1' height='50%' width='50%'></img>
                    </Grid>
                   
                   
                    </Grid>

                </Hidden>
             
                <Hidden only={["xs", "sm"]}>
                <Grid className={classes.mat_main_card}>
                   
                   <h1 className={classes.esc_tit} >Escalation matrix</h1>
              
           </Grid>
                <Grid className={classes.mat_head}>
                    <Grid style={{ background: '#E9F4FF', display: 'flex', justifyContent:'center', padding: '2px', marginTop: '10px', }}>
                        <Typography className={classes.escalationhead} style={{ width: '150px' }} >Details</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '200px' }} >Contact person</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '150px' }} >Contact no</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '350px' }}>Address</Typography>
                        
                        <Typography className={classes.escalationhead} style={{ width: '200px' }}> Email ID</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Customer Care</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Meena Laxmidas</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325374</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>No. 911,Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>CustomerCare@bullforce.co</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '8px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Head of Customer Care</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Kamlesh Gaikwad</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325375</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>No. 911,Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>CustomerCare@bullforce.co</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '8px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Compliance Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Ganesh Maheshwari</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325377</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>No. 911,Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>Compliance@bullforce.co</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '8px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Chief Executive Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Subrahmanya Basavanahalli</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325377</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>3rd Floor, Redifice Strada, International,

Airport Road,Aramane Nagar, Bangalore - 560080</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>Subbu@bullforce.co</Typography>

                    </Grid>
                    </Grid>

                    <Grid className={classes.mat_main_card}>
                   
                   <h1 className={classes.esc_tit} >Managerial Details</h1>
              
           </Grid>
           <Grid className={classes.mat_head}>
                    <Grid style={{ background: '#E9F4FF', display: 'flex', justifyContent:'center', padding: '8px', marginTop: '10px', }}>
                        <Typography className={classes.escalationhead} style={{ width: '150px' }} >Details</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '200px' }} >Contact person</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '150px' }} >Contact no</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '350px' }}>Address</Typography>
                        
                        <Typography className={classes.escalationhead} style={{ width: '200px' }}> Email ID</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '8px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Chief Executive Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Subrahmanya Basavanahalli</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325377</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>3rd Floor, Redifice Strada, International,

Airport Road,Aramane Nagar, Bangalore - 560080</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>Subbu@bullforce.co</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '8px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Compliance Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Ganesh Maheshwari</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325377</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>No. 911,Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>Compliance@bullforce.co</Typography>

                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex', justifyContent:'center', padding: '8px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Grievance Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '200px' }} >Anil Kumar</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >022-66325375</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '350px' }}>3rd Floor, Redifice Strada, International Airport Road,

Aramane Nagar, Bangalore - 560080</Typography>
                        
                        <Typography className={classes.escalationdel} style={{ width: '200px' }}>Grievances@bullforce.co</Typography>

                    </Grid>
                    </Grid>
                    </Hidden>
                    <Hidden only={["lg", "md", "xl"]}>
                <Grid className={classes.mat_main_card}>
                   
                   <h1 className={classes.esc_tit} >Escalation matrix</h1>
              
           </Grid>
           <Grid container className={classes.aa}>
         
           <Grid  style={{ background: '#E9F4FF',display:'flex',padding:'10px 2px'}}>
            
                        <Typography className={classes.escalationhead} style={{ width: '100px' }} >Details</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '150px' }} >Contact person</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '100px' }} >Contact no</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '200px' }} >Email ID</Typography>
                        <Typography className={classes.escalationhead} style={{ width: '300px' }}>Address</Typography>
                    </Grid>
                    <Grid style={{ background: '#ffffff', display: 'flex',  padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} > Customer Care</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Meena Laxmidas</Typography>
                        <a href="tel:02266325374">  <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325374</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >CustomerCare@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >No. 911, , Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        </Grid>  

                        <Grid style={{ background: '#ffffff', display: 'flex', padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} >Head of Customer Care</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Kamlesh Gaikwad</Typography>
                        <a href="tel:02266325375">  <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325375</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >CustomerCare@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >No. 911, , Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        </Grid>
                        <Grid style={{ background: '#ffffff', display: 'flex',  padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} >Compliance Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Ganesh Maheshwari</Typography>
                        <a href="tel:02266325376"> <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325377</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >Compliance@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >No. 911, , Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        </Grid>

                        <Grid style={{ background: '#ffffff', display: 'flex',  padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} >Chief Executive Officer</Typography>
                                                <Typography className={classes.escalationdel} style={{ width: '150px' }} >Subrahmanya Basavanahalli 
                        </Typography>

                        

                        <a href="tel:02266325377">   <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325377</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >Subbu@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >3rd Floor, Redifice Strada, International,

Airport Road,Aramane Nagar, Bangalore - 560080</Typography>
                        </Grid>

                    </Grid>  

                    <Grid className={classes.mat_main_card}>
                   
                   <h1 className={classes.esc_tit} >Managerial Details</h1>
              
           </Grid>
           <Grid container className={classes.aa}>
         
         <Grid  style={{ background: '#E9F4FF',display:'flex',padding:'10px 2px'}}>
          
                      <Typography className={classes.escalationhead} style={{ width: '100px' }} >Details</Typography>
                      <Typography className={classes.escalationhead} style={{ width: '150px' }} >Contact person</Typography>
                      <Typography className={classes.escalationhead} style={{ width: '100px' }} >Contact no</Typography>
                      <Typography className={classes.escalationhead} style={{ width: '200px' }} >Email ID</Typography>
                      <Typography className={classes.escalationhead} style={{ width: '300px' }}>Address</Typography>
                  </Grid>
                  <Grid style={{ background: '#ffffff', display: 'flex',  padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} >Chief Executive Officer</Typography>
                                                <Typography className={classes.escalationdel} style={{ width: '150px' }} >Subrahmanya Basavanahalli 
                        </Typography>

                        

                        <a href="tel:02266325377">   <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325377</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >Subbu@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >3rd Floor, Redifice Strada, International,

Airport Road,Aramane Nagar, Bangalore - 560080</Typography>
                        </Grid>
                        <Grid style={{ background: '#ffffff', display: 'flex',  padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} >Compliance Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Ganesh Maheshwari</Typography>
                        <a href="tel:02266325376"> <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325377</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >Compliance@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >No. 911, , Tulsiani Chambers, Nariman Point, MUMBAI - 400021</Typography>
                        </Grid>

                        <Grid style={{ background: '#ffffff', display: 'flex',  padding: '2px', borderBottom: '2px solid #DFE0EB' }}>
                        <Typography className={classes.escalationdel} style={{ width: '100px' }} >Grievance Officer</Typography>
                        <Typography className={classes.escalationdel} style={{ width: '150px' }} >Anil Kumar</Typography>
                        <a href="tel:02266325376"> <Typography className={classes.escalationdel} style={{ width: '100px' }} >022-66325375</Typography></a>
                        <a href = "mailto:CustomerCare@bullforce.co">       <Typography className={classes.escalationdel} style={{ width: '200px' }} >Grievances@bullforce.co</Typography></a>
                        <Typography className={classes.escalationdel} style={{ width: '300px' }} >3rd Floor, Redifice Strada, International Airport Road,

Aramane Nagar, Bangalore - 560080</Typography>
                        </Grid>
          </Grid>
                    </Hidden>
                <Grid style={{width:'100%'}}>
                <Fotter />
                </Grid>
              
            </div >
        )
    }
}
export default (withStyles(styles)((contactus)));
