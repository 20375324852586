import { Typography, Grid ,FormControl,FormHelperText,TextField,Button,Snackbar, SnackbarContent, IconButton,} from '@mui/material';
import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import bank_img from '../../ui-assets/bank.svg'
import bank_img1 from '../../ui-assets/bank1.svg'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FileUploader } from "react-drag-drop-files";
import Secure from '../../ui-assets/Secure.svg'
import { Close } from '@mui/icons-material';
import Fotter from '../landingpage/fotter'
import dayjs from 'dayjs';
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Payment | BullForce',
    description: 'Bullforce Payment: Secure online payments for trading, demat accounts, and investments. Fast, easy transactions with multiple payment options. Start trading today.',
    canonical: 'https://bullforce.co/payment',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, online, bullforce, payment, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {
 
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'
 
        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'
 
        }
    },
    sigCanvas:{
        background:'#ffffff'
    },
    shutter:{
        marginTop:'50px',
        width:'100%',
        height:'100vh',
        background:'red',
        borderTopLeftRadius :'8px',
        borderTopRightRaduis:'8px',
    },
 
 
  
 };

const styles = theme => ({

payment_h1:{
    color: '#3E3E3E !important',
    fontFamily: 'cardiuma-Bold !important',
    fontSize: '32px !important',
    fontstyle: 'normal',
    fontweight: '600',
    // h1 tags margin start, end removed - 02/09/24 - Dev Srini
    marginBlockStart: 0,
    marginBlockEnd: 0,

    "@media only screen and (max-device-width: 480px)": {
        fontSize: '24px !important',
        fontweight: '500',
    }
    
},

payment_h2:{
    color: '#999 !important',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '16px !important',
    fontstyle: 'normal',
    fontweight: '400',
    
},
   
subhead:{
    color: '#3E3E3E !important',
    fontFamily: 'cardiuma-regular !important',
    fontSize: '16px !important',
    fontstyle: 'normal',
    fontweight: '700 !important',
    lineHeight: "24px !important",
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '14px !important',
    }
    
},

subhead1:{
    color: '#3E3E3E !important',
    fontFamily: 'cardiuma-Bold !important',
    fontSize: '16px !important',
    fontstyle: 'normal',
    fontweight: '700 !important',
    lineHeight: "24px !important",
    marginBlockStart: 0,
    marginBlockEnd: 0,

    "@media only screen and (max-device-width: 480px)": {
        fontSize: '14px !important',
    }
},

f_head:{
    color: '#3E3E3E !important',
    fontFamily: 'cardiuma-Bold !important',
    fontSize: '20px !important',
    fontstyle: 'normal',
    fontweight: '700 !important',
    lineHeight: "24px !important",
    marginBlockStart: 0,
    marginBlockEnd: 0,

},

joinDiplomtsBtn: {
    background: "#F74542 !important",
    padding: "12px 45px !important",
    borderRadius: "20px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important"
},
errtxt: {
    color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
},
upload_tit1:{
    color: '#7A7C7F !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',paddingLeft:'5px'
},
mathodback:{
    width:'29%',background:'#ffffff',borderRadius:'4px',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'4px',padding:'16px'
},
mathodback1:{
    width:'32%',background:'#ffffff',borderRadius:'4px',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'4px',padding:'16px'
},
mathodback2:{
    width:'35%',background:'#ffffff',borderRadius:'4px',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'4px',padding:'16px'
},

head_card:{
    padding:'30px 140px',display:'flex',alignContent:'center',alignItems:'center',borderBottom:'1px solid #DFE0EB',
    "@media only screen and (max-device-width: 480px)": {
        padding: '16px',display:'flex',borderBottom:'none'
    }
},
bank_img_card:{
    width:'5%',marginRight:'2%',
    "@media only screen and (max-device-width: 480px)": {
        width:'20%',marginRight:'10%',
    }
},
bak_info_card:{
    width:'80%',display:'flex',justifyContent:'space-evenly',
    "@media only screen and (max-device-width: 480px)": {
        width:'90%',display:'grid',
    }
},
bak_info_card1:{
    width:'50%',background:'#ffffff',borderRadius:'12px',padding:'50px 30px',
    "@media only screen and (max-device-width: 480px)": {
        width:'100%',display:'grid',padding:'16px',background:'#FFFFFF'
    }
},
bank_payment_card:{
    width:'40%',background:'#ffffff',borderRadius:'12px',padding:'50px 30px',
  "@media only screen and (max-device-width: 480px)": {
    width:'100%',marginTop:'5%',borderRadius:'0px',padding:'16px'
}
},
bank_charger_card:{
    width:'75%',display:'flex',justifyContent:'space-evenly',
    "@media only screen and (max-device-width: 480px)": {
        width:'95%'
    }
}
});

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

class payment extends Component {
    constructor(props) {
        super(props)


        this.state = {
            accounno: '',
            amount:'',
            txno:'',
            txdate:new Date(),
            errtype:0,
            errorMsg:'',
            filenamedata1:'',
            filename1:'',
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
        }



    }

    componentDidMount = async () => {

      
    }
    handleChangefile1  = async (file) => {
        const base64 = await convertBase64(file);
        this.setState({filenamedata1:base64,filename1:file.name, errtype:0})
    };
    PaymentSubmit= async () => {

        const {accounno,amount,txdate,txno,filenamedata1}=this.state
        let isok=0
        if (!accounno.length && isok === 0) {
            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the Client Code" })
    
        }
        if (!amount.length && isok === 0) {
            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the Amount" })
    
        }
      
        if (!txno.length && isok === 0) {
            isok = 1
            this.setState({ errtype: 3, errorMsg: "Enter the   Transaction number" })
    
        }

        if(!filenamedata1.length && isok === 0){

            isok = 1
            this.setState({ errtype: 4, errorMsg: "Uplaod the reciet form"})
        }

        console.log(txdate)
        console.log(txdate.length)
       

        if( isok === 0)
        {
            let payload={
                client_code:accounno.toLocaleUpperCase(),
                amount:amount,
                txno:txno,
                txdate:txdate,
                imgdata:filenamedata1
            }
            try {
                const pay_st = await httpRequest({
                    endPoint: `/api/payment/receipt`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
    
                if(pay_st.status===200)
                {
                    this.setState({accounno:'',amount:'',txno:'',filenamedata1:'',filename1:''})
                    this.setState({ showerr: true, errorMsg: 'Receipt entry has been raised with concerned team to process', mysnack: snackeror.mysucc })
                }
                if(pay_st.status===201)
                {
                    this.setState({ showerr: true, errorMsg: pay_st.message, mysnack: snackeror.myerror })
                }
                
            } catch (error) {
                
            }
        }
      
    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    render() {
        const { classes } = this.props;
        return (
        <>
            <DocumentMeta {...meta} />
            <Grid style={{width:'100%',background:'#EFEFEF',marginTop:'50px'}}>
                <Grid s className={classes.head_card} >
<img src={bank_img} alt='bank' className={classes.bank_img_card}></img>
                {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                <h1 className={classes.payment_h1}  >IMPS/NEFT/RTGS transfer</h1>
                </Grid>
                <Grid  style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'12px'}}>
                <Grid  className={classes.bak_info_card}>
                <Grid className={classes.bak_info_card1}>
                    <Grid style={{display:'flex',alignItems:'center',borderBottom:'1px solid #D9D9D9'}}>
                <img src={bank_img1} alt='bank' style={{width:'8%',marginRight:'2%'}}></img>
                <Typography className={classes.payment_h2}  >Transfer money using IMPS,NEFT / RTGS</Typography>
                </Grid>
                <Grid style={{display:'flex',marginTop:'24px'}}>
                <Typography className={classes.subhead}  >Bank Name :  </Typography>
                <span style={{color:'#ffffff'}}>{"-"} </span>
                <Typography className={classes.subhead1}  > HDFC Bank</Typography>
                </Grid>    
                <Grid style={{display:'flex',marginTop:'24px'}}>
                <Typography className={classes.subhead}  >Account name : </Typography>
                <span style={{color:'#ffffff'}}>{"-"} </span>
                <Typography className={classes.subhead1}  >Maliram Makharia Finstock Private Limited</Typography>
                </Grid> 
                <Grid style={{display:'flex',marginTop:'24px'}}>
                <Typography className={classes.subhead}  >Account number : </Typography>
                <span style={{color:'#ffffff'}}>{"-"} </span>
                <Typography className={classes.subhead1}  > 00010340000518</Typography>
                </Grid> 
                <Grid style={{display:'flex',marginTop:'24px'}}>
                <Typography className={classes.subhead}  >Account type : </Typography>
                <span style={{color:'#ffffff'}}>{"-"} </span>
                <Typography className={classes.subhead1}  > Current account</Typography>
                </Grid> 
                <Grid style={{display:'flex',marginTop:'24px'}}>
                <Typography className={classes.subhead}  >Branch : </Typography>
                <span style={{color:'#ffffff'}}>{"-"} </span>
                <Typography className={classes.subhead1}  >Nariman Point-Mumbai</Typography>
                </Grid> 
                <Grid style={{display:'flex',marginTop:'24px'}}>
                <Typography className={classes.subhead}  >IFSC : </Typography>
                <span style={{color:'#ffffff'}}>{"-"} </span>
                <Typography className={classes.subhead1}  >HDFC0000001</Typography>
                </Grid> 
                
                </Grid>
                <Grid className={classes.bank_payment_card}>
                    <h3 className={classes.f_head} >Payment Receipt Form</h3>
                    <Grid  >
              <FormControl style={{ width: '100%',marginTop:'5px' }} variant="standard">

                  <TextField size="small" variant="standard"
                      fullWidth
                    
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#999' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#999' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#999' },
                      }}
                      InputLabelProps={{
                          style: { color: this.state.errtype === 1 ? "#F74542" : '#999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                      }}
                      label='Client Code'

                      value={this.state.accounno}
                      InputProps={{

                          classes: { input: classes.input },
                          style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '16px', height: '30px' }
                      }}
                      onChange={(e) => this.setState({accounno:e.target.value,errtype:0})}
                  ></TextField>
                  {this.state.errtype === 1 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
              </FormControl>
          </Grid>
          <Grid  >
              <FormControl style={{ width: '100%',marginTop:'5px' }} variant="standard">

                  <TextField size="small" variant="standard"
                      fullWidth
                      type='number'
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#999' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#999' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#999' },
                      }}
                      InputLabelProps={{
                          style: { color: this.state.errtype === 2 ? "#F74542" : '#999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                      }}
                      label='Amount'

                      value={this.state.amount}
                      InputProps={{

                          classes: { input: classes.input },
                          style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '16px', height: '30px' }
                      }}
                      onChange={(e) => this.setState({amount:e.target.value,errtype:0})}
                  ></TextField>
                  {this.state.errtype === 2 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
              </FormControl>
          </Grid>
          <Grid  >
              <FormControl style={{ width: '100%',marginTop:'5px' }} variant="standard">

                  <TextField size="small" variant="standard"
                      fullWidth
                    
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#999' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#999' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#999' },
                      }}
                      InputLabelProps={{
                          style: { color: this.state.errtype === 3 ? "#F74542" : '#999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                      }}
                      label='Transaction number'

                      value={this.state.txno}
                      InputProps={{

                          classes: { input: classes.input },
                          style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '16px', height: '30px' }
                      }}
                      onChange={(e) => this.setState({txno:e.target.value,errtype:0})}
                  ></TextField>
                  {this.state.errtype === 3 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
              </FormControl>
          </Grid>

          <Grid style={{ width: '100%',marginTop:'20px' }}>
          <FormControl style={{ width: '100%' }} variant="standard">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker defaultValue={dayjs(new Date())} style={{ marginTop: '3px' }} slotProps={{ textField: { size: 'small' } }} 
                                format="DD-MM-YYYY"

                                    onChange={(newValue) =>

                                        this.setState({ txdate: newValue.$d })
                                    }

                                />
                            </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid style={{marginTop:'20px'}}>
                        {this.state.errtype === 4 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
                        <FileUploader
          style={{}}
          multiple={false}
          maxSize={'1mb'}
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handleChangefile1}
          name="file"
          
          children={
              <Grid style={{ padding: '15px',  width: '100%', background: '#FFFFFF', borderRadius: '8px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
              
              </Grid>
          }
      />
        <Grid style={{width:'100%',display:'flex',marginTop:'2px'}}>
          <Grid style={{width:'75%'}}>
          <Typography className={classes.upload_tit1}>{this.state.filename1}</Typography>
          </Grid>
          <Grid style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
              <img src={Secure} alt="Secure"></img>
          <Typography className={classes.upload_tit1}>Secure</Typography>
              </Grid>
             

      </Grid>
      
                        </Grid>
                        <Grid style={{ marginTop:'20px', width: '100%', display: 'flex', justifyContent: 'center',  }}>
                                  <Button fullWidth className={classes.joinDiplomtsBtn}

                                      onClick={() => this.PaymentSubmit()}
                                  >Submit

                                  </Button>
                              </Grid>
                </Grid>

                </Grid>
                </Grid>  

                <Grid  style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'3%'}}>
                   
                <Grid className={classes.bank_charger_card}>
                    <Grid className={classes.mathodback}>
                    
                <h3 className={classes.subhead1}  > Method</h3>
                
                    </Grid>
                    
                    <Grid className={classes.mathodback1}> 
                <h3 className={classes.subhead1}  > Time taken</h3>
                
                </Grid>
                   
                        <Grid className={classes.mathodback2}>
                            <h3 className={classes.subhead1}  > Charges</h3>

                        </Grid>

            </Grid>
            </Grid>
            <Grid  style={{width:'100%',display:'flex',justifyContent:'center',marginTop:'8px%'}}>
            <Grid className={classes.bank_charger_card}>
                   
                    <Grid className={classes.mathodback}> 
                    <Typography className={classes.subhead}  >UPI</Typography>
                
                    </Grid>
                
                    <Grid className={classes.mathodback1}> 
                    <Typography className={classes.subhead}  >Instant</Typography>
                
</Grid>
                
                    <Grid className={classes.mathodback2    }> 
                    <Typography className={classes.subhead}  >Free</Typography>
                
                    </Grid>

            </Grid>
            </Grid>
            <Grid  style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Grid className={classes.bank_charger_card}>
                    
                    <Grid className={classes.mathodback}> 
                    <Typography className={classes.subhead}  >Payment Gateway</Typography>
                </Grid>
                    <Grid className={classes.mathodback1}> 
                    <Typography className={classes.subhead}  >Instant</Typography>
                                </Grid>
                   
                    <Grid className={classes.mathodback2}> 
                    <Typography className={classes.subhead}  >₹ 9 + 18% GST</Typography>
                                    </Grid>

            </Grid>
           
            
            </Grid>
            <Grid  style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Grid className={classes.bank_charger_card}>
                    
                    <Grid className={classes.mathodback}> 
                    <Typography className={classes.subhead}  >NEFT/RTGS</Typography>
                </Grid>
                    <Grid className={classes.mathodback1}> 
                    <Typography className={classes.subhead}  >Within 2Hrs</Typography>
                                </Grid>
                   
                    <Grid className={classes.mathodback2}> 
                    <Typography className={classes.subhead}  >Bank charges may apply</Typography>
                                    </Grid>

            </Grid>
           </Grid>
           <Grid  style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Grid className={classes.bank_charger_card}>
                    
                    <Grid className={classes.mathodback}> 
                    <Typography className={classes.subhead}  >IMPS</Typography>
                </Grid>
                    <Grid className={classes.mathodback1}> 
                    <Typography className={classes.subhead}  >Within 10 Minutes</Typography>
                                </Grid>
                   
                    <Grid className={classes.mathodback2}> 
                    <Typography className={classes.subhead}  >Bank charges may apply</Typography>
                                    </Grid>

            </Grid>
           </Grid>
           <Grid  style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:'5%'}}>
            <Grid className={classes.bank_charger_card}>
                    
                    <Grid className={classes.mathodback}> 
                    <Typography className={classes.subhead}  >Cheque</Typography>
                </Grid>
                    <Grid className={classes.mathodback1}> 
                    <Typography className={classes.subhead}  >3 to 5 Working days</Typography>
                                </Grid>
                   
                    <Grid className={classes.mathodback2}> 
                    <Typography className={classes.subhead}  >Free</Typography>
                                    </Grid>

            </Grid>
           </Grid>
           
                <Fotter/>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </Grid>
        </>
        )
    }
}
export default (withStyles(styles)((payment)));