
export  function signup_funcation(data,keyname,keyvalue) {

   let data1=[]
   let obj={}
   console.log(keyname+"==="+keyvalue)
   let maindata=JSON.parse( data)
    for (var key in maindata) {


if(keyname===key)
{
    obj[key]=keyvalue
}else
    {
        obj[key]=maindata[key]
}

    }

    //localStorage.removeItem('reg_data')
    localStorage.setItem('reg_data', JSON.stringify(obj))
   
    console.log(obj)
}
