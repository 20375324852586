import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'

const TranslateCode = ({ handleSelectedLanguage }) => {

    const [urduLang, setUrduLang] = useState("")

    useEffect(() => {
        if(urduLang == "Urdu"){
            document.body.dir = 'rtl';
        }else{
            document.body.dir = 'ltr';
        }
    }, [urduLang])

    useEffect(() => {
        // Create a script element for Google Translate
        const translateScript = document.createElement('script')
        translateScript.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
        translateScript.async = true

        // Error handling for script loading
        translateScript.onerror = (error) => {
            console.error('Translate Script load error:', error)
        }

        document.body.appendChild(translateScript)

        translateScript.onload = () => {
            console.log('Translate Script has been loaded.')

            // Initialization function for Google Translate
            window.googleTranslateElementInit = () => {
                new window.google.translate.TranslateElement(
                    {
                        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                        includedLanguages: 'en,hi,gu,kn,ml,mr,pa,ta,te,ur',
                    },
                    'google_translate_element'
                )
            }

            // Access selected language value on change

            setTimeout(() => {
                // Callback function for the MutationObserver
                const mutationCallback = function (mutationsList, observer) {
                    for (let mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            // Check if the mutation is a change to a child node
                            const targetSpan = mutation.target
                            // Assuming you want to target the first span inside <a>
                            if (targetSpan) {
                                const spanText = targetSpan.textContent.trim();

                                const languages = ["Telugu", "English", "Gujarati", "Kannada", "Malayalam", "Marathi", "Punjabi", "Tamil", "Urdu"];

                                // Check if spanText includes any language from the languages array
                                let languageFound = false;
                                let selectedLanguage
                                for (let language of languages) {
                                    if (spanText.includes(language)) {
                                        selectedLanguage = language
                                        languageFound = true;
                                        break;
                                    }
                                }

                                if (languageFound && selectedLanguage) {
                                    handleSelectedLanguage(languageFound)
                                    setUrduLang(selectedLanguage)
                                } else {
                                    handleSelectedLanguage(false)
                                    setUrduLang("")
                                }
                            }
                        }
                    }
                };

                // Options for the observer (which mutations to observe)
                const observerConfig = { childList: true, subtree: true };

                // Select the <a> tag to observe
                const targetNode = document.querySelector('.VIpgJd-ZVi9od-xl07Ob-lTBxed');

                // Create a MutationObserver instance
                const observer = new MutationObserver(mutationCallback);

                observer.observe(targetNode, observerConfig);

                modifyUI()
            }, 400)
            
            observeIframe()
        }

        // // Clean up function to remove script and global function
        return () => {
            document.body.removeChild(translateScript)
            delete window.googleTranslateElementInit
        }

    }, [])

    // Function to observe changes in the iframe
    const observeIframe = () => {
        const observer = new MutationObserver(() => {
            const iframe = document.querySelector('iframe.skiptranslate');
            
            if (iframe) {
                // Check if iframe is already loaded
                if (iframe.contentDocument && iframe.contentDocument.readyState === 'complete') {
                    console.log(iframe, "iframe is already loaded");
                    // If you want to modify iframe content immediately when found
                    modifyIframeContent(iframe);

                    iframe.style.borderBottom = 'none'
                    iframe.style.boxShadow = 'none'

                } else {
                    // Delay the modification using setTimeout after iframe loads
                    iframe.addEventListener('load', () => {
                        console.log(iframe, "iframe loaded");

                        // Now you can safely access iframe content
                        // modifyIframeContent(iframe);
                    });
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });
    }

    const modifyIframeContent = (iframe) => {

        try {
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

            const modifyContent = () => {
                const divElement = iframeDoc.querySelector('div#\\:1\\.menuBody.VIpgJd-ZVi9od-vH1Gmf');

                if (divElement) {
                    divElement.style.border = '1px solid #DFE0EB';

                    const aElements = divElement.querySelectorAll('a.VIpgJd-ZVi9od-vH1Gmf-ibnC6b-gk6SMd');
                    aElements.forEach(a => a.remove());

                    const nowrapDivs = divElement.querySelectorAll('div[style="white-space: nowrap;"]');
                    nowrapDivs.forEach(div => {

                        div.addEventListener('mouseenter', () => {
                            div.style.backgroundColor = 'transparent';
                            div.style.textDecoration = 'underline solid rgb(243, 212, 94) 20%';
                            div.style.color = '#999';
                        });

                        div.addEventListener('mouseleave', () => {
                            div.style.backgroundColor = 'transparent';
                            div.style.color = '#999';
                            div.style.textDecoration = 'none';
                        });

                    });

                    const spanElement = divElement.querySelectorAll('.VIpgJd-ZVi9od-vH1Gmf-ibnC6b > div');
                    spanElement.forEach(span => {
                        span.addEventListener('mouseenter', () => {
                            span.style.fontWeight = '600'
                            span.style.fontFamily = 'cardiuma-regular';

                        })

                        span.addEventListener('mouseleave', () => {
                            span.style.fontWeight = '400'
                        })
                    })

                    const indicatorSpans = divElement.querySelectorAll('span.indicator');
                    indicatorSpans.forEach(span => span.remove());

                    const textSpans = divElement.querySelectorAll('span.text');
                    textSpans.forEach(span => {
                        span.style.fontSize = '13px';
                        span.style.lineHeight = '28px';
                        span.style.letterSpacing = '0.2px';
                        span.style.textAlign = 'center';
                        span.style.color = '#000';
                        span.style.padding = '12px';
                    });
                }
            }

            // Call modifyContent immediately once, and observe mutations afterward
            modifyContent();

            const observer = new MutationObserver(() => {
                modifyContent();
            });

            observer.observe(iframeDoc.body, { childList: true, subtree: true });
        }
        catch (error) {
            console.error('Error accessing iframe content:', error);
        }
    }


    const modifyUI = () => {
        const changeTextContent = () => {
            const spanElement = document.querySelector('a.VIpgJd-ZVi9od-xl07Ob-lTBxed > span');
            if (spanElement && spanElement.textContent === "Select Language") {
                spanElement.textContent = "Choose Your Language";
            }
        };

        const changeBorderStyle = () => {
            const borderSpanElement = document.querySelector('a.VIpgJd-ZVi9od-xl07Ob-lTBxed > span[style*="border-left"]');
            if (borderSpanElement) {
                borderSpanElement.style.borderLeft = 'none';
            }
        };

        changeTextContent()
        changeBorderStyle()
    }

    setTimeout(() => {
        modifyUI()
    }, 0)

    // prevent page reload - 26-07-24 - Dev Srini
    const handleClick = (e) => {
        e.preventDefault()
    }

    return (
        <Grid
            id="google_translate_element"
            sx={{
                position: "fixed",
                right: "5%",
                top: "11%",
            }}

            onClick={(e) => handleClick(e)}
        >
            {/* CSS styles for Google Translate gadget */}
            <style>
                {`
                    #google_translate_element .goog-te-gadget {
                        font-family: cardiuma-regular !important;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 17px;
                        color: #999999;
                    }

                    .goog-te-gadget-icon {
                        display: none !important;
                    }

                    .goog-te-gadget-simple {
                        border-left: none;
                        border-right: none;
                        border-top: none;
                    }
                    .goog-te-combo {
                        font-family: cardiuma-regular !important;
                    }
                `}
            </style>
        </Grid>
    )
}

export default TranslateCode