import React, { Component, createRef } from 'react'
import Webcam from "react-webcam";
import { withStyles, } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import { publicIpv4} from 'public-ip';
import { Close } from '@mui/icons-material';
import cong_bull from '../../ui-assets/cong_bull.svg'
import {
    Typography, Grid, Button,
     Snackbar, SnackbarContent, IconButton, Modal, Box
} from '@mui/material'
import * as CryptoJS from 'crypto-js';
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {
 
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'
 
        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'
 
        }
    },
    sigCanvas:{
        background:'#ffffff'
    },
    shutter:{
        marginTop:'50px',
        width:'100%',
        height:'100vh',
        background:'red',
        borderTopLeftRadius :'8px',
        borderTopRightRaduis:'8px',
    },
 
 
  
 };
const styles = theme => ({
    card_tit1: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important', textAlign: 'left'
        },

    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    cam_tit: {


        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    page1card: {
        width: '100%',
        paddingLeft: '20%',
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',
 
        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',
 
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },
 
    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'
 
        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
 
 
    cong_card: {
     position: 'absolute',
     top: '45%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: '100%',
     height: 'auto',
 
     outline: 'none',
     p: 4,
     "@media only screen and (max-device-width: 480px)": {
         top: '15%',
         left: '0%',
         transform: 'none'
     }
 },
 cong_tit1: {
     textAlign: 'center', color: '#BCD171', fontFamily: 'cardiuma-Bold !important', fontSize: '42px !important',
     "@media only screen and (max-device-width: 480px)": {
         fontSize: '32px !important'
     },
 
 
 },
 cong_tit2: {
     textAlign: 'center', color: '#ffffff !important', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',width:'50%',fontWeight:'400',
     "@media only screen and (max-device-width: 480px)": {
         fontSize: '18px !important',
         marginTop: '10%',
       
     },
 },

 
 cong_card3: {
     width: '100%', marginTop: '1%',display:'flex',justifyContent:'center',
     "@media only screen and (max-device-width: 480px)": {
         width: '100%', marginTop: '2%',
     },
 },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
})

class selfie extends Component {
    constructor(props) {
        super(props)

        this.webcamRef = createRef();
        this.state = {

            emailid: '',
            mobileno:'',
            retakepage:0,
            aadharno:'',
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            pub_ip:'0',
            pageno:0,
            qrid:0
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let pub_ip=await publicIpv4()
        this.setState({pub_ip:pub_ip})
        console.log(pub_ip)
        console.log(urlParams.get('refno'))
        let dedata = CryptoJS.AES.decrypt(urlParams.get('refno'), "96358214256984120").toString(CryptoJS.enc.Utf8)
      //  dedata=dedata.split('*')
        console.log("ano", dedata)
        this.setState({ qrid: dedata})


    }
    Continue5 = async () => {
        const {  qrid} = this.state
        const imageSrc = this.webcamRef.current.getScreenshot({ width: 972, height: 1296, dpi: 96 });
        

        let similarity = 0
        let emailid="",mobileno="",aadharno=""
        let isok=0
        if(imageSrc===null)
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Not able to capture image from camera", mysnack: snackeror.myerror })
        }
        console.log(imageSrc)
        //  this.setState({ inner_pageno: 8, profile_img: imageSrc })

        let payload_new = {
            "qrid": qrid,
        }



try {
    
    const qrdata = await httpRequest({
        endPoint: `/api/qrid_data`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload_new,
    });

    if(qrdata.status===200)
    {
        emailid=qrdata.emailid
        mobileno=qrdata.mobileno
        
        aadharno=CryptoJS.AES.decrypt(qrdata.aadhaarno, "96358214256984120").toString(CryptoJS.enc.Utf8).substring(8,12)
  

        let payload = {
            "emailid": emailid,
           "mobileno":mobileno,
           "aadharno":aadharno,

            "img2": imageSrc,

"pub_ip":this.state.pub_ip

        }
        let payload1 = {
            "emailid": emailid,
           "mobileno":mobileno,
           "aadharno":aadharno,
           
            "img2": imageSrc,
           
            "pub_ip":this.state.pub_ip

        }
        if(isok===0)
        {
        try {

            this.setState({ islodding: true ,retakepage:1})
            const otp = await httpRequest({
                endPoint: `/api/qrverfy/Face`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            this.setState({ islodding: false })

            if (otp.status === 200) {
                this.setState({ similarity: otp.similarity, })
                try {
                    const check_liveness = await httpRequest({
                        endPoint: `/api/verfy/qrcheck_liveness`,
                        method: "post",
                        instance: "instanceTwo",
                        requestBody: payload1,
                    });

                    if (check_liveness.status === 200) {
                        if (check_liveness.score < 59) {
                            this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                        }
                        if (check_liveness.score > 59) {
                            this.setState({ pageno:1, selfcont_but: false, retake_but: true, liveness: check_liveness.score })
                        }


                    }

                    if (check_liveness.status !== 200) {
                        this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                        this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                    }
                } catch (error) {
                   
                }


            }
            if (otp.status === 201) {
                this.setState({ selfcont_but: true, retake_but: false, inner_pageno: 8, similarity: otp.similarity, })
                 
            }

        } catch (error) {

        }
    }
}

} catch (error) {
    
}
    }
    render() {
        const { classes } = this.props;
        return (
            <div style={{ marginTop: '20%',padding:'16px' }}>
                   {this.state.pageno===1 && 
                <div>
                    <Grid className={classes.cong_card}>
      <Grid style={{ width: '100%', display:'flex',justifyContent:'center' }}>
<img src={cong_bull} alt="congbull"></img>
</Grid>
    
    <Grid className={classes.cong_card3}>
        <Typography className={classes.cong_tit2} >Thank you! You have completed the liveliness review.</Typography>
    </Grid>
   
   
</Grid>
             </div>
    }
                {this.state.pageno===0 && 
                <div>
                <Typography className={classes.card_tit1}>Capture Selfie</Typography>
                <Grid style={{ width: '100%', padding: '16px' }}>
                    <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}><li>Photo should present full head from top of hair to bottom of chin with white background.</li></Typography>

                    <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px', marginTop: '5px' }}><li>Front view and eyes open.</li></Typography>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'center' }}>

                <div>
                              <Webcam

                                  screenshotFormat="image/jpeg"
                                  screenshotQuality={1}
                                  imageSmoothing={true}
                                  mirrored={true}

                                  height='400px' width='300px' ref={this.webcamRef} />
                          </div>
                        
                </Grid>
            
                {this.state.retakepage===0 && 
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Grid style={{ width: '70%', display: 'flex', justifyContent: 'center' }}>
                        <Button fullWidth className={classes.joinDiplomtsBtn}

                            onClick={() => this.Continue5()}
                        >Continue

                        </Button>
                    </Grid>
                </Grid>
    }

{this.state.retakepage===1 && 
<div>
    <Grid style={{ width: '100%', marginLeft: '5px', marginTop: '5px' }}>
    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography style={{ color: '#7DAB1C', fontFamily: 'cardiuma-M', fontSize: '18px' }}>{this.state.similarity}{'%'}</Typography>
        <Typography style={{ marginLeft: '5px', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>matching with aadhar</Typography>
    </Grid>
    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography style={{ color: '#7DAB1C', fontFamily: 'cardiuma-M', fontSize: '18px' }}>{this.state.liveness}{'%'}</Typography>
        <Typography style={{ marginLeft: '5px', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Liveness</Typography>
    </Grid>
</Grid>
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Grid style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                    <Button disabled={this.state.retake_but} fullWidth className={this.state.retake_but ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                            onClick={() => this.setState({retakepage:0,liveness:0,similarity:0})}
                        >Retake

                        </Button>
                    </Grid>
                   
                  
                </Grid>
</div>}
                </div>
    }
       <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>
        )
    }
}
export default (withStyles(styles)((selfie)));