import React, { Component } from 'react'
import './brokerage.css'
import {
    Typography, Grid, Radio, RadioGroup, FormControlLabel, TextField
} from '@mui/material'
import { withStyles, styled } from "@mui/styles";
import Select from 'react-select'
//  fontSize: 14,
//fontFamily: 'cardiuma-Bold !important',

const customstyles = {
    menu: base => ({
        ...base,
        borderColor: '#999999',
        color: "#3E3E3E", fontFamily: 'cardiuma-regular !important', fontSize: '12px',
    }),
    control: base => ({
        ...base,
        height: 30,
        minheight: 30,
        borderColor: '#999999',
        color: "#3E3E3E", fontFamily: 'cardiuma-regular!important', fontSize: '12px',

    })
};
const styles = theme => ({})
const CssTextField = styled(TextField)({
    borderRadius: '8px',

    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});
//  { label: "Father", value: "Father" },
const optcomtype = [
    { label: "COPPER", value: '2500', buy: 310, sell: 315, st: '400' },
    { label: "CRUDEOIL", value: '100', buy: 3170, sell: 3190, st: '3000' },
    { label: "GOLD", value: '100', buy: 30950, sell: 30980, st: '30000' },
    { label: "GOLDM", value: '10', buy: 30950, sell: 30980, st: '30000' },
    { label: "NATURALGAS", value: '1250', buy: 300, sell: 310, st: '150' },
    { label: "NICKEL", value: '1500', buy: 1520, sell: 1540, st: '1500' },
    { label: "SILVER", value: '30', buy: 43750, sell: 43800, st: '40000' },
    { label: "SILVERM", value: '5', buy: 53750, sell: 54180, st: '50000' },
    { label: "ZINC", value: '5000', buy: 156, sell: 159, st: '190' },


]
const comtype = [

    { label: "ALUMINI", value: '1000', buy: 100, sell: 112, cat: 'a', grp: 'n' },
    { label: "ALUMINIUM", value: '5000', buy: 110, sell: 112, cat: 'a', grp: 'n' },
    { label: "CARDAMOM", value: '100', buy: 1160, sell: 1165, cat: 'a', grp: 'a' },
    { label: "CASTORSEED", value: '100', buy: 3880, sell: 3884, cat: 'b', grp: 'a' },
    { label: "COPPER", value: '2500', buy: 310, sell: 315, cat: 'a', grp: 'n' },
    { label: "COTTONCNDY", value: '48', buy: 20100, sell: 20150, cat: 'a', grp: 'a' },
    { label: "CPO", value: '1000', buy: 560, sell: 562, cat: 'a', grp: 'a' },
    { label: "CRUDEOIL", value: '100', buy: 3170, sell: 3190, cat: 'a', grp: 'n' },
    { label: "CRUDEOILM", value: '10', buy: 3170, sell: 3190, cat: 'a', grp: 'n' },
    { label: "GOLD", value: '100', buy: 30950, sell: 30980, cat: 'a', grp: 'n' },
    { label: "GOLDGUINEA", value: '1', buy: 24650, sell: 24700, cat: 'a', grp: 'n' },
    { label: "GOLDM", value: '10', buy: 30950, sell: 30980, cat: 'a', grp: 'n' },
    { label: "GOLDPETAL", value: '1', buy: 3060, sell: 3090, cat: 'a', grp: 'n' },
    { label: "KAPAS", value: '200', buy: 20100, sell: 20150, cat: 'a', grp: 'a' },
    { label: "LEAD", value: '5000', buy: 125, sell: 128, cat: 'a', grp: 'n' },
    { label: "LEADMINI", value: '1000', buy: 125, sell: 128, cat: 'a', grp: 'n' },
    { label: "MCXBULLDEX", value: '50', buy: 50, sell: 55, cat: 'a', grp: 'n' },
    { label: "MCXENRGDEX", value: '125', buy: 125, sell: 130, cat: 'a', grp: 'n' },
    { label: "MCXMETLDEX", value: '50', buy: 50, sell: 55, cat: 'a', grp: 'n' },
    { label: "MENTHOIL", value: '360', buy: 900, sell: 950, cat: 'a', grp: 'a' },
    { label: "NATGASMINI", value: '250', buy: 195, sell: 198, cat: 'a', grp: 'n' },
    { label: "NATURALGAS", value: '1250', buy: 195, sell: 198, cat: 'a', grp: 'n' },
    { label: "NICKEL", value: '1500', buy: 660, sell: 665, cat: 'a', grp: 'n' },
    { label: "PEPPER", value: '10', buy: 40000, sell: 40100, cat: 'b', grp: 'a' },
    { label: "RBDPMOLEIN", value: '1000', buy: 520, sell: 525, cat: 'b', grp: 'a' },
    { label: "RUBBER", value: '10', buy: 15000, sell: 15100, cat: 'a', grp: 'n' },
    { label: "SILVER", value: '30', buy: 43750, sell: 43800, cat: 'a', grp: 'n' },
    { label: "SILVERM", value: '5', buy: 43750, sell: 43800, cat: 'a', grp: 'n' },
    { label: "SILVERMIC", value: '1', buy: 43750, sell: 43800, cat: 'a', grp: 'n' },
    { label: "ZINC", value: '5000', buy: 156, sell: 159, cat: 'a', grp: 'n' },
    { label: "ZINCMINI", value: '1000', buy: 156, sell: 159, cat: 'a', grp: 'n' }

]
class commodities extends Component {
    constructor(props) {
        super(props)


        this.state = {
            b1: 110,
            s1: 112,
            q1: 1,
            comtype1: '1000',
            cat: 'a',
            grp1: 'n',
            brokerage_buy1: 0,
            turnover1: 0,
            comtype: 'ALUMINI',
            exc_trans_charge1: 0,
            stt_total1: 0,
            sebi_charges1: 0,

            gst1: 0,
            nse1: true,
            bse1: false,
            brokerage_buy2: 0,
            optcomtype: 'COPPER',

            optcomtype1: '0',
            st2: 60.75,
            b2: 0.0625,
            s2: 0.0675,
            q2: 1,

            nse2: true,
            bse2: false,

            b3: 1000,
            s3: 1100,
            q3: 400,
            nse3: true,
            bse3: false,
            brokerage_buy3: 0,
            b4: 100,
            s4: 110,
            q4: 400,
            nse4: true,
            bse4: false,
            brokerage_buy4: 0,

        }



    }
    componentDidMount = async () => {
        this.state.comtype = "ALUMINI"
        this.state.comtype1 = "1000"
        this.state.b1 = 110
        this.state.s1 = 112
        this.state.cat = 'a'
        this.state.grp1 = 'a'

        this.equity()


        this.state.optcomtype = "COPPER"
        this.state.optcomtype1 = "2500"
        this.state.b2 = 310
        this.state.s2 = 315
        this.state.st2 = 400

        this.delivery_equity()

    }
    comm1(e) {
        this.state.comtype = e.label
        this.state.comtype1 = e.value
        this.state.b1 = e.buy
        this.state.s1 = e.sell
        this.state.cat = e.cat
        this.state.grp1 = e.grp



        this.equity()
    }
    eb1(e) {
        this.state.b1 = e.target.value
        this.equity()
    }
    es1(e) {
        this.state.s1 = e.target.value
        this.equity()
    }

    eq1(e) {
        this.state.q1 = e.target.value
        this.equity()
    }



    opt1(e) {

        this.state.comtype = e.label
        this.state.optcomtype1 = e.value
        this.state.b2 = e.buy
        this.state.s2 = e.sell
        this.state.st2 = e.st


        this.delivery_equity()
    }
    est2(e) {
        this.state.st2 = e.target.value
        this.delivery_equity()
    }
    eb2(e) {
        this.state.b2 = e.target.value
        this.delivery_equity()
    }
    es2(e) {
        this.state.s2 = e.target.value
        this.delivery_equity()
    }

    eq2(e) {
        this.state.q2 = e.target.value
        this.delivery_equity()

    }



    equity() {

        let { nse1, bse1, comtype1, cat, comtype, grp1 } = this.state
        let b1 = this.state.b1
        let s1 = this.state.s1
        let q1 = this.state.q1
        console.log('comtype', comtype1)

        var turnover = (parseFloat(b1) + parseFloat(s1)) * parseFloat(comtype1) * parseFloat(q1).toFixed(2);
        console.log('turnover', turnover)

        if ((b1 * comtype1 * q1) > 200000) {
            var brokerage_buy = 20;
        }
        else {
            var brokerage_buy = ((b1 * comtype1 * q1 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(b1 * comtype1 * q1 * 0.0003).toFixed(2));
        }
        if ((s1 * comtype1 * q1) > 200000) {
            var brokerage_sell = 20;
        }
        else {
            var brokerage_sell = ((s1 * comtype1 * q1 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(s1 * comtype1 * q1 * 0.0003).toFixed(2));
        }

        var brokerage = brokerage_buy + brokerage_sell;

        var ctt = 0;
        if (cat === 'a') {
            ctt = parseFloat(parseFloat(0.0001 * s1 * q1 * comtype1).toFixed(2));
        }

        var sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        if (grp1 === "a") {
            sebi_charges = parseFloat(parseFloat(turnover * 0.0000001).toFixed(2));
        }
        var etc = 0;
        var cc = 0;

        let exc_trans_charge = 0
        if (cat === "a") {
            exc_trans_charge = parseFloat(parseFloat(0.000026 * turnover).toFixed(2))
        } else {
            exc_trans_charge = parseFloat(parseFloat(0.0000005 * turnover).toFixed(2));
        }

        if (comtype === "RBDPMOLEIN") {
            var rbd_multiplier = parseInt(turnover / 100000);
            exc_trans_charge = parseFloat(parseFloat(rbd_multiplier).toFixed(2));
        }
        if (comtype === "CASTORSEED") {

            exc_trans_charge = parseFloat(parseFloat(0.000005 * turnover).toFixed(2))
        }

        if (comtype === "RBDPMOLEIN") {

            exc_trans_charge = parseFloat(parseFloat(0.00001 * turnover).toFixed(2))
        }
        if (comtype === "PEPPER") {

            exc_trans_charge = parseFloat(parseFloat(0.0000005 * turnover).toFixed(2))
        }
        if (comtype === "KAPAS") {

            exc_trans_charge = parseFloat(parseFloat(0.000026 * turnover).toFixed(2))
        }

        var gst = parseFloat(parseFloat(0.18 * (brokerage + exc_trans_charge + sebi_charges)).toFixed(2));


        var stamp_charges = Math.round(parseFloat(parseFloat(b1 * q1 * comtype1 * 0.00002).toFixed(2)));

        var total_tax = parseFloat(parseFloat(brokerage + ctt + exc_trans_charge + gst + sebi_charges + stamp_charges)).toFixed(2);
        var breakeven = parseFloat(parseFloat(total_tax / (q1 * comtype1)).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven

        var net_profit = parseFloat(parseFloat(((s1 - b1) * q1 * comtype1) - total_tax).toFixed(2));

        this.setState({ stamp_charges1: stamp_charges, total_tax1: total_tax, breakeven1: breakeven, net_profit1: net_profit })
        this.setState({ turnover1: turnover, brokerage_buy1: brokerage, sebi_charges1: sebi_charges, ctt1: ctt, gst1: gst, exc_trans_charge1: exc_trans_charge })

    }
    delivery_equity() {
        let { nse2, bse2, optcomtype1 } = this.state
        let b2 = this.state.b2
        let s2 = this.state.s2
        let q2 = this.state.q2
        let st2 = this.state.st2
        var total_turnover = parseFloat(parseFloat((b2 + s2) * q2 * optcomtype1) + parseFloat((s2 + st2) * q2 * optcomtype1)).toFixed(2);
        var turnover = parseFloat(parseFloat((b2 + s2) * optcomtype1 * q2).toFixed(2));

        let brokerage_buy = 0
        let brokerage_sell = 0

        if (b2 > 0) {
            brokerage_buy = (((b2 + st2) * q2 * optcomtype1 * 0.0003) > 20) ? 20 : parseFloat(parseFloat((b2 + st2) * q2 * optcomtype1 * 0.0003).toFixed(2));
        }
        if (s2 > 0) {
            brokerage_sell = (((s2 + st2) * q2 * optcomtype1 * 0.0003) > 20) ? 20 : parseFloat(parseFloat((s2 + st2) * q2 * optcomtype1 * 0.0003).toFixed(2));
        }
        var brokerage = parseFloat(brokerage_buy)
        brokerage = brokerage + parseFloat(brokerage_sell)

        console.log(brokerage_buy)
        console.log(brokerage_sell)

        var sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        var ctt = 0;
        ctt = parseFloat(parseFloat(0.0005 * s2 * q2 * optcomtype1).toFixed(2));
        var exc_trans_charge = parseFloat(parseFloat(0.0005 * turnover).toFixed(2));
        var gst = parseFloat(parseFloat(0.18 * (brokerage + exc_trans_charge + sebi_charges)).toFixed(2));
        var stamp_charges = Math.round(parseFloat(parseFloat(b2 * q2 * optcomtype1 * 0.00003).toFixed(2)));
        var total_tax = parseFloat(parseFloat(brokerage + ctt + exc_trans_charge + gst + sebi_charges + stamp_charges)).toFixed(2);
        var breakeven = parseFloat(parseFloat(total_tax / (q2 * optcomtype1)).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven
        var net_profit = parseFloat(parseFloat(((s2 - b2) * q2 * optcomtype1) - total_tax).toFixed(2));

        this.setState({ gst2: gst, stamp_charges2: stamp_charges, total_tax2: total_tax, breakeven2: breakeven, net_profit2: net_profit })
        this.setState({ turnover2: turnover, brokerage_buy2: brokerage, ctt2: ctt, sebi_charges2: sebi_charges, exc_trans_charge2: exc_trans_charge })



    }
    render() {
        return (
            <div style={{ width: '100%', display: 'flex', marginBottom: '50px', justifyContent: "center" }}>
                <Grid className='subcard1'>
                    <Typography className='subtit'>Futures</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '35%' }}>
                            <Typography className='subtit1'

                                onClick={(e) => this.equity()}
                            >
                                COMMODITY
                            </Typography>
                        </Grid>



                        <Grid style={{ width: '25%' }}>
                            <Typography className='subtit1'

                                onClick={(e) => this.equity()}
                            >
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '25%' }}>
                            <Typography className='subtit1'>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '15%' }}>
                            <Typography className='subtit1'>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '35%' }}>
                            <Select
                                options={comtype}
                                values={this.state.comtype1}
                                defaultValue={{ label: "ALUMINI", value: '1000', buy: 100, sell: 112, cat: 'a', grp: 'n' }}
                                styles={customstyles}
                                fullWidth


                                onChange={e => {

                                    this.comm1(e)

                                }}
                            >
                            </Select>
                        </Grid>
                        <Grid style={{ width: '23%' }}>
                            <CssTextField
                                value={this.state.b1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb1(e)}
                            />

                        </Grid>
                        <Grid style={{ width: '23%' }}>
                            <CssTextField
                                value={this.state.s1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es1(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '12%' }}>
                            <CssTextField
                                value={this.state.q1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq1(e)}
                            />
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '25px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className='bsubtit1'>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.turnover1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className='bsubtit1'>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.brokerage_buy1}</Typography>
                        </Grid>

                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.exc_trans_charge1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>0</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.gst1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>CTT</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.ctt1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.sebi_charges1}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.stamp_charges1}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.total_tax1}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.breakeven1}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='nettit'>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit1 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit1}</Typography>
                        </Grid>


                    </Grid>






                </Grid>
                <Grid className='subcard2' style={{ marginLeft: '5%' }}>
                    <Typography className='subtit'>Options</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '25%' }}>
                            <Typography className='subtit1'>
                                COMMODITY
                            </Typography>
                        </Grid>


                        <Grid style={{ width: '22%' }}>
                            <Typography className='subtit1'>
                                STRIKE PRICE
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '15%' }}>
                            <Typography className='subtit1'>
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '15%' }}>
                            <Typography className='subtit1'>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '12%' }}>
                            <Typography className='subtit1'>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '25%' }}>
                            <Select
                                options={optcomtype}
                                values={this.state.optcomtype1}
                                defaultValue={{ label: "COPPER", value: '2500', buy: 310, sell: 315, st: '400' }}
                                styles={customstyles}
                                fullWidth


                                onChange={e => {

                                    this.opt1(e)

                                }}
                            >
                            </Select>
                        </Grid>
                        <Grid style={{ width: '22%' }}>
                            <CssTextField
                                value={this.state.st2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.est2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '15%' }}>
                            <CssTextField
                                value={this.state.b2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '15%' }}>
                            <CssTextField
                                value={this.state.s2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '12%' }}>
                            <CssTextField
                                value={this.state.q2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "5px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq2(e)}
                            />
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '25px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className='bsubtit1'>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.turnover2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className='bsubtit1'>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.brokerage_buy2}</Typography>
                        </Grid>

                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.exc_trans_charge2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>0</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.gst2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>CTT</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.ctt2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.sebi_charges2}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.stamp_charges2}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.total_tax2}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='bsubtit1'>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className='bsubtit2'>{this.state.breakeven2}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className='nettit'>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit2 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit2}</Typography>
                        </Grid>


                    </Grid>


                </Grid>


            </div >
        )
    }
}
export default (withStyles(styles)((commodities)));