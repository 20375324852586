import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { Grid, Typography, } from '@mui/material'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Terms and Conditions | Risk disclosure | BullForce',
    description: 'Bullforce Rights & Obligations: Understand your trading rights, demat account responsibilities, and compliance with NSE, BSE regulations. Secure, transparent trading. Bullforce Risk Disclosure: Understand the risks in trading stocks, bonds, and IPOs. Ensure informed decisions with NSE, BSE compliance and secure trading practices.',
    canonical: 'https://bullforce.co/termsandconditions, https://bullforce.co/risk_disclosure',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, terms, conditions, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}


const styles = theme => ({

    heading: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important', // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',
        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '20px !important',
        }


    },
    p_body: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",
        "@media only screen and (max-device-width: 480px)": {
            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    },
    p_body1: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important', lineHeight: "22px !important", marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',
        "@media only screen and (max-device-width: 480px)": {
            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    },

})

class obligations extends Component {



    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: 'auto' }}>

                <DocumentMeta {...meta} /> 

                <Grid style={{ padding: '20px 0px 0px 0px' }}>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In pursuant to the SEBI circular no CIR/MIRSD/16/2011 dated August 22, 2011 regarding simplification and
                        rationalization of trading account opening process, the uniform documentation set is to be followed by all the
                        stock brokers / trading members. In this regard please find the information on the Rights & Obligations, Risk
                        Disclosure Document, Guidance Note and Policies and Procedures for your reference.
                    </Typography>
                    {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                    <h1 className={classes.heading} style={{ textAlign: 'center', marginTop: '25px' }} >

                        Rights and Obligations of Stock Brokers, Sub-Brokers and Clients
                    </h1>
                    <Typography className={classes.p_body1} style={{ textAlign: 'center', marginTop: '5px' }} >
                        as prescribed by SEBI and Stock Exchanges
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. The client shall invest/trade in those securities/contracts/other instruments admitted to dealings on the
                        Exchanges as defined in the Rules, Byelaws and Regulations of Exchanges/Securities and Exchange Board of
                        India (SEBI) and circulars/notices issued there under from time to time.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. The stock broker, sub-broker and the client shall be bound by all the Rules, Byelaws and Regulations of the
                        Exchanges and circulars/notices issued there under and Rules and Regulations of SEBI and relevant
                        notifications of Government authorities as may be in force from time to time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. The client shall satisfy itself of the capacity of the stock broker to deal in securities and/or deal in derivatives
                        contracts and wishes to execute its orders through the stock broker and the client shall from time to time
                        continue to satisfy itself of such capacity of the stock broker before executing orders through the stock broker.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4. The stock broker shall continuously satisfy itself about the genuineness and financial soundness of the client
                        and investment objectives relevant to the services to be provided.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        5. The stock broker shall take steps to make the client aware of the precise nature of the Stock broker's liability
                        for business to be conducted, including any limitations, the liability and the capacity in which the stock broker
                        acts.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        6. The sub-broker shall provide necessary assistance and co-operate with the stock broker in all its dealings
                        with the client(s).
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        CLIENT INFORMATION
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        7. The client shall furnish all such details in full as are required by the stock broker in "Account Opening Form"
                        with supporting details, made mandatory by stock exchanges/SEBI from time to time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        8. The client shall familiarize himself with all the mandatory provisions in the Account Opening documents. Any
                        additional clauses or documents specified by the stock broker shall be non-mandatory, as per terms &
                        conditions accepted by the client.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        9. The client shall immediately notify the stock broker in writing if there is any change in the information in the
                        'account opening form' as provided at the time of account opening and thereafter; including the information on
                        winding up petition/insolvency petition or any litigation which may have material bearing on his capacity. The
                        client shall provide/update the financial information to the stock broker on a periodic basis.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        10.The stock broker and sub-broker shall maintain all the details of the client as mentioned in the account
                        opening form or any other information pertaining to the client, confidentially and that they shall not disclose the
                        same to any person/authority except as required under any law/regulatory requirements. Provided however
                        that the stock broker may so disclose information about his client to any person or authority with the express
                        permission of the client.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        MARGINS
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        11.The client shall pay applicable initial margins, withholding margins, special margins or such other margins as
                        are considered necessary by the stock broker or the Exchange or as may be directed by SEBI from time to time
                        as applicable to the segment(s) in which the client trades. The stock broker is permitted in its sole and absolute
                        discretion to collect additional margins (even though not required by the Exchange, Clearing House/Clearing
                        Corporation or SEBI) and the client shall be obliged to pay such margins within the stipulated time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        12.The client understands that payment of margins by the client does not necessarily imply complete
                        satisfaction of all dues. In spite of consistently having paid margins, the client may, on the settlement of its trade,
                        be obliged to pay (or entitled to receive) such further sums as the contract may dictate/require.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        TRANSACTIONS AND SETTLEMENTS
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        13.The client shall give any order for buy or sell of a security/derivatives contract in writing or in such form or
                        manner, as may be mutually agreed between the client and the stock broker. The stock broker shall ensure to
                        place orders and execute the trades of the client, only in the Unique Client Code assigned to that client.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        14. The stock broker shall inform the client and keep him apprised about trading/settlement cycles,
                        delivery/payment schedules, any changes therein from time to time, and it shall be the responsibility in turn of
                        the client to comply with such schedules/procedures of the relevant stock exchange where the trade is
                        executed.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        15. The stock broker shall ensure that the money/securities deposited by the client shall be kept in a separate
                        account, distinct from his/its own account or account of any other client and shall not be used by the stock broker
                        for himself/itself or for any other client or for any purpose other than the purposes mentioned in Rules,
                        Regulations, circulars, notices, guidelines of SEBI and/or Rules, Regulations, Bye-laws, circulars and notices of
                        Exchange.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        16. Where the Exchange(s) cancels trade(s) suo moto all such trades including the trade/s done on behalf of the
                        client shall ipso facto stand cancelled, stock broker shall be entitled to cancel the respective contract(s) with
                        client(s).
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        17. The transactions executed on the Exchange are subject to Rules, Byelaws and Regulations and
                        circulars/notices issued thereunder of the Exchanges where the trade is executed and all parties to such trade
                        shall have submitted to the jurisdiction of such court as may be specified by the Byelaws and Regulations of the
                        Exchanges where the trade is executed for the purpose of giving effect to the provisions of the Rules, Byelaws
                        and Regulations of the Exchanges and the circulars/notices issued thereunder
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        BROKERAGE
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        18. The Client shall pay to the stock broker brokerage and statutory levies as are prevailing from time to time and
                        as they apply to the Client's account, transactions and to the services that stock broker renders to the Client. The
                        stock broker shall not charge brokerage more than the maximum brokerage permissible as per the rules,
                        regulations and bye-laws of the relevant stock exchanges and/or rules and regulations of SEBI.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        LIQUIDATION AND CLOSE OUT OF POSITION
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        19. Without prejudice to the stock broker's other rights (including the right to refer a matter to arbitration), the
                        client understands that the stock broker shall be entitled to liquidate/close out all or any of the client's positions
                        for non-payment of margins or other amounts, outstanding debts, etc. and adjust the proceeds of such
                        liquidation/close out, if any, against the client's liabilities/obligations. Any and all losses and financial charges on
                        account of such liquidation/closing-out shall be charged to and borne by the client.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        20. In the event of death or insolvency of the client or his/its otherwise becoming incapable of receiving and
                        paying for or delivering or transferring securities which the client has ordered to be bought or sold, stock broker
                        may close out the transaction of the client and claim losses, if any, against the estate of the client. The client or
                        his nominees, successors, heirs and assignee shall be entitled to any surplus which may result there from. The
                        client shall note that transfer of funds/securities in favor of a Nominee shall be valid discharge by the stock
                        broker against the legal heir.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        21. The stock broker shall bring to the notice of the relevant Exchange the information about default in
                        payment/delivery and related aspects by a client. In case where defaulting client is a corporate
                        entity/partnership/proprietary firm or any other artificial legal entity, then the name(s) of
                        Director(s)/Promoter(s)/Partner(s)/Proprietor as the case may be, shall also be communicated by the stock
                        broker to the relevant Exchange(s).
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        DISPUTE RESOLUTION
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        22. The stock broker shall provide the client with the relevant contact details of the concerned Exchanges and
                        SEBI.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        23. The stock broker shall co-operate in redressing grievances of the client in respect of all transactions routed
                        through it and in removing objections for bad delivery of shares, rectification of bad delivery, etc.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        24. The client and the stock broker shall refer any claims and/or disputes with respect to deposits, margin
                        money, etc., to arbitration as per the Rules, Byelaws and Regulations of the Exchanges where the trade is
                        executed and circulars/notices issued thereunder as may be in force from time to time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        25. The stock broker shall ensure faster settlement of any arbitration proceedings arising out of the transactions
                        entered into between him vis-à-vis the client and he shall be liable to implement the arbitration awards made in
                        such proceedings.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        26. The client/stock-broker understands that the instructions issued by an authorized representative for dispute
                        resolution, if any, of the client/stock-broker shall be binding on the client/stock-broker in accordance with the
                        letter authorizing the said representative to deal on behalf of the said client/stock-broker
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        TERMINATION OF RELATIONSHIP
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        27. This relationship between the stock broker and the client shall be terminated; if the stock broker for any
                        reason ceases to be a member of the stock exchange including cessation of membership by reason of the stock
                        broker's default, death, resignation or expulsion or if the certificate is cancelled by the Board.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        28. The stock broker, sub-broker and the client shall be entitled to terminate the relationship between them
                        without giving any reasons to the other party, after giving notice in writing of not less than one month to the other
                        parties. Notwithstanding any such termination, all rights, liabilities and obligations of the parties arising out of or
                        in respect of transactions entered into prior to the termination of this relationship shall continue to subsist and
                        vest in/be binding on the respective parties or his/its respective heirs, executors, administrators, legal
                        representatives or successors, as the case may be.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        29. In the event of demise/insolvency of the sub-broker or the cancellation of his/its registration with the Board
                        or/withdrawal of recognition of the sub-broker by the stock exchange and/or termination of the agreement with
                        the sub broker by the stock broker, for any reason whatsoever, the client shall be informed of such termination
                        and the client shall be deemed to be the direct client of the stock broker and all clauses in the 'Rights and
                        Obligations' document(s) governing the stock broker, sub-broker and client shall continue to be in force as it is,
                        unless the client intimates to the stock broker his/its intention to terminate their relationship by giving a notice in
                        writing of not less than one month.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        ADDITIONAL RIGHTS AND OBLIGATIONS
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        30. The stock broker shall ensure due protection to the client regarding client's rights to dividends, rights or
                        bonus shares, etc. in respect of transactions routed through it and it shall not do anything which is likely to harm
                        the interest of the client with whom and for whom they may have had transactions in securities.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        31. The stock broker and client shall reconcile and settle their accounts from time to time as per the Rules,
                        Regulations, Bye Laws, Circulars, Notices and Guidelines issued by SEBI and the relevant Exchanges where
                        the trade is executed.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        32. The stock broker shall issue a contract note to his constituents for trades executed in such format as may be
                        prescribed by the Exchange from time to time containing records of all transactions including details of order
                        number, trade number, trade time, trade price, trade quantity, details of the derivatives contract, client code,
                        brokerage, all charges levied etc. and with all other relevant details as required therein to be filled in and issued
                        in such manner and within such time as prescribed by the Exchange. The stock broker shall send contract notes
                        to the investors within one working day of the execution of the trades in hard copy and/or in electronic form using
                        digital signature.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        33. The stock broker shall make pay out of funds or delivery of securities, as the case may be, to the Client within
                        one working day of receipt of the payout from the relevant Exchange where the trade is executed unless
                        otherwise specified by the client and subject to such terms and conditions as may be prescribed by the relevant
                        Exchange from time to time where the trade is executed.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        34. The stock broker shall send a complete `Statement of Accounts' for both funds and securities in respect of
                        each of its clients in such periodicity and format within such time, as may be prescribed by the relevant
                        Exchange, from time to time, where the trade is executed. The Statement shall also state that the client shall
                        report errors, if any, in the Statement within such time as may be prescribed by the relevant Exchange from time
                        to time where the trade was executed, from the receipt thereof to the Stock broker.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        35. The stock broker shall send daily margin statements to the clients. Daily Margin statement should include,
                        inter-alia, details of collateral deposited, collateral utilized and collateral status (available balance/due from
                        client) with break up in terms of cash, Fixed Deposit Receipts (FDRs), Bank Guarantee and securities.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        36. The Client shall ensure that it has the required legal capacity to, and is authorized to, enter into the
                        relationship with stock broker and is capable of performing his obligations and undertakings hereunder. All
                        actions required to be taken to ensure compliance of all the transactions, which the Client may enter into shall be
                        completed by the Client prior to such transaction being entered into.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        ELECTRONIC CONTRACT NOTES (ECN)
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        37. In case, client opts to receive the contract note in electronic form, he shall provide an appropriate e-mail id to
                        the stock broker. The client shall communicate to the stock broker any change in the email-id through a physical
                        letter. If the client has opted for internet trading, the request for change of email id may be made through the
                        secured access by way of client specific user id and password.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        38. The stock broker shall ensure that all ECNs sent through the e-mail shall be digitally signed, encrypted, non-
                        tamper able and in compliance with the provisions of the ITAct, 2000. In case, ECN is sent through e-mail as
                        an attachment, the attached file shall also be secured with the digital signature, encrypted and nontamperable.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        39. The client shall note that non-receipt of bounced mail notification by the stock broker shall amount to
                        delivery of the contract note at the e-mail ID of the client.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        40. The stock broker shall retain ECN and acknowledgement of the e-mail in a soft and non-tamperable form
                        in the manner prescribed by the exchange in compliance with the provisions of the ITAct, 2000 and as per the
                        extant rules/regulations/circulars/guidelines issued by SEBI/Stock Exchanges from time to time. The proof of
                        delivery i.e., log report generated by the system at the time of sending the contract notes shall be maintained
                        by the stock broker for the specified period under the extant regulations of SEBI/stock exchanges. The log
                        report shall provide the details of the contract notes that are not delivered to the client/e-mails rejected or
                        bounced back. The stock broker shall take all possible steps to ensure receipt of notification of bounced mails
                        by him at all times within the stipulated time period under the extant regulations of SEBI/stock exchanges.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        41. The stock broker shall continue to send contract notes in the physical mode to such clients who do not opt
                        to receive the contract notes in the electronic form. Wherever the ECNs have not been delivered to the client
                        or has been rejected (bouncing of mails) by the e-mail ID of the client, the stock broker shall send a physical
                        contract note to the client within the stipulated time under the extant regulations of SEBI/stock exchanges and
                        maintain the proof of delivery of such physical contract notes.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        42. In addition to the e-mail communication of the ECNs to the client, the stock broker shall simultaneously
                        publish the ECN on his designated web-site, if any, in a secured way and enable relevant access to the clients
                        and for this purpose, shall allot a unique user name and password to the client, with an option to the client to
                        save the contract note electronically and/or take a print out of the same.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        LAW AND JURISDICTION
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        43. In addition to the specific rights set out in this document, the stock broker, sub-broker and the client shall
                        be entitled to exercise any other rights which the stock broker or the client may have under the Rules, Byelaws and Regulations of the Exchanges in which the client chooses to trade and circulars/notices issued
                        thereunder or Rules and Regulations of SEBI.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        44. The provisions of this document shall always be subject to Government notifications, any rules,
                        regulations, guidelines and circulars/notices issued by SEBI and Rules, Regulations and Bye laws of the
                        relevant stock exchanges, where the trade is executed, that may be in force from time to time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        45. The stock broker and the client shall abide by any award passed by the Arbitrator(s) under the Arbitration
                        and Conciliation Act, 1996. However, there is also a provision of appeal within the stock exchanges, if either
                        party is not satisfied with the arbitration award.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        46. Words and expressions which are used in this document but which are not defined herein shall, unless the
                        context otherwise requires, have the same meaning as assigned thereto in the Rules, Byelaws and
                        Regulations and circulars/notices issued thereunder of the Exchanges/SEBI.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        47. All additional voluntary clauses/document added by the stock broker should not be in contravention with
                        rules/regulations/notices/circulars of Exchanges/SEBI. Any changes in such voluntary clauses/document(s)
                        need to be preceded by a notice of 15 days. Any changes in the rights and obligations which are specified by
                        Exchanges/SEBI shall also be brought to the notice of the clients.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        48. If the rights and obligations of the parties hereto are altered by virtue of change in Rules and regulations of
                        SEBI or Bye-laws, Rules and Regulations of the relevant stock Exchanges where the trade is executed, such
                        changes shall be deemed to have been incorporated herein in modification of the rights and obligations of the
                        parties mentioned in this document.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        INTERNET & WIRELESS TECHNOLOGY BASED TRADING FACILITY PROVIDED BY STOCK
                        BROKERS TO CLIENT
                    </h1>
                    <Typography className={classes.p_body1} style={{ padding: '20px 0px 0px 0px' }} >
                        (All the clauses mentioned in the 'Rights and Obligations' document(s) shall be applicable.
                        Additionally, the clauses mentioned herein shall also be applicable.)
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. Stock broker is eligible for providing Internet based trading (IBT) and securities trading through the use of
                        wireless technology that shall include the use of devices such as mobile phone, laptop with data card, etc.
                        which use Internet Protocol (IP). The stock broker shall comply with all requirements applicable to internet
                        based trading/securities trading using wireless technology as may be specified by SEBI & the Exchanges from
                        time to time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. The client is desirous of investing/trading in securities and for this purpose, the client is desirous of using
                        either the internet based trading facility or the facility for securities trading through use of wireless technology.
                        The Stock broker shall provide the Stock broker's IBT Service to the Client, and the Client shall avail of the
                        Stock broker's IBT Service, on and subject to SEBI/Exchanges Provisions and the terms and conditions
                        specified on the Stock broker's IBT Web Site provided that they are in line with the norms prescribed by
                        Exchanges/SEBI.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. The stock broker shall bring to the notice of client the features, risks, responsibilities, obligations and
                        liabilities associated with securities trading through wireless technology/internet/smart order routing or any
                        other technology should be brought to the notice of the client by the stock broker.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4. The stock broker shall make the client aware that the Stock Broker's IBT system itself generates the initial
                        password and its password policy as stipulated in line with norms prescribed by Exchanges/SEBI.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        5. The Client shall be responsible for keeping the Username and Password confidential and secure and shall
                        be solely responsible for all orders entered and transactions done by any person whosoever through the Stock
                        broker's IBTSystem using the Client's Username and/or Password whether or not such person was authorized
                        to do so. Also the client is aware that authentication technologies and strict security measures are required for
                        the internet trading/securities trading through wireless technology through order routed system and
                        undertakes to ensure that the password of the client and/or his authorized representative are not revealed to
                        any third party including employees and dealers of the stock broker
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        6. The Client shall immediately notify the Stock broker in writing if he forgets his password, discovers security
                        flaw in Stock Broker's IBT System, discovers/suspects discrepancies/ unauthorized access through his
                        username/password/account with full details of such unauthorized use, the date, the manner and the
                        transactions effected pursuant to such unauthorized use, etc.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        7. The Client is fully aware of and understands the risks associated with availing of a service for routing orders
                        over the internet/securities trading through wireless technology and Client shall be fully liable and responsible
                        for any and all acts done in the Client's Username/password in any manner whatsoever.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        8. The stock broker shall send the order/trade confirmation through email to the client at his request. The client
                        is aware that the order/ trade confirmation is also provided on the web portal. In case client is trading using
                        wireless technology, the stock broker shall send the order/trade confirmation on the device of the client.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        9. The client is aware that trading over the internet involves many uncertain factors and complex hardware,
                        software, systems, communication lines, peripherals, etc. are susceptible to interruptions and dislocations.
                        The Stock broker and the Exchange do not make any representation or warranty that the Stock broker's IBT
                        Service will be available to the Client at all times without any interruption.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        10. The Client shall not have any claim against the Exchange or the Stock broker on account of any suspension,
                        interruption, non-availability or malfunctioning of the Stock broker's IBT System or Service or the Exchange's
                        service or systems or non-execution of his orders due to any link/system failure at the Client/Stock
                        brokers/Exchange end for any reason beyond the control of the stock broker/Exchanges.
                    </Typography>

                            {/* // risk disclosure added - 02-09-24 - Dev Srini */}
                    <h1
                        id='risk-disclosure-content'
                        className={classes.heading}
                        style={{ padding: '20px 0px 0px 0px' }}
                    >
                        RISK DISCLOSURE DOCUMENT FOR CAPITAL MARKET AND DERIVATIVES SEGMENTS
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        This document contains important information on trading in Equities/Derivatives Segments of the stock
                        exchanges. All prospective constituents should read this document before trading in Equities/Derivatives
                        Segments of the Exchanges.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Stock exchanges/SEBI does neither singly or jointly and expressly nor impliedly guarantee nor make any
                        representation concerning the completeness, the adequacy or accuracy of this disclosure document nor have
                        Stock exchanges /SEBI endorsed or passed any merits of participating in the trading segments. This brief
                        statement does not disclose all the risks and other significant aspects of trading.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In the light of the risks involved, you should undertake transactions only if you understand the nature of the
                        relationship into which you are entering and the extent of your exposure to risk.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        You must know and appreciate that trading in Equity shares, derivatives contracts or other instruments traded
                        on the Stock Exchange, which have varying element of risk, is generally not an appropriate avenue for
                        someone of limited resources/limited investment and/or trading experience and low risk tolerance. You should
                        therefore carefully consider whether such trading is suitable for you in the light of your financial condition. In
                        case you trade on Stock exchanges and suffer adverse consequences or loss, you shall be solely responsible
                        for the same and Stock exchanges/its Clearing Corporation and/or SEBI shall not be responsible, in any
                        manner whatsoever, for the same and it will not be open for you to take a plea that no adequate disclosure
                        regarding the risks involved was made or that you were not explained the full risk involved by the concerned
                        stock broker. The constituent shall be solely responsible for the consequences and no contract can be
                        rescinded on that account. You must acknowledge and accept that there can be no guarantee of profits or no
                        exception from losses while executing orders for purchase and/or sale of a derivative contract being traded on
                        Stock exchanges.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        It must be clearly understood by you that your dealings on Stock exchanges through a stock broker shall be
                        subject to your fulfilling certain formalities set out by the stock broker, which may inter alia include your filling the
                        know your client form, reading the rights and obligations, do's and don'ts, etc., and are subject to the Rules,
                        Byelaws and Regulations of relevant Stock exchanges, its Clearing Corporation, guidelines prescribed by SEBI
                        and in force from time to time and Circulars as may be issued by Stock exchanges or its Clearing Corporation
                        and in force from time to time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Stock exchanges does not provide or purport to provide any advice and shall not be liable to any person who
                        enters into any business relationship with any stock broker of Stock exchanges and/or any third party based on
                        any information contained in this document. Any information contained in this document must not be construed
                        as business advice. No consideration to trade should be made without thoroughly understanding and reviewing
                        the risks involved in such trading. If you are unsure, you must seek professional advice on the same.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In considering whether to trade or authorize someone to trade for you, you should be aware of or must get
                        acquainted with the following:-
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        1. BASIC RISKS:
                    </h1>

                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.1 Risk of Higher Volatility:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Volatility refers to the dynamic changes in price that a security/derivatives contract undergoes when trading
                        activity continues on the Stock Exchanges. Generally, higher the volatility of a security/derivatives contract,
                        greater is its price swings. There may be normally greater volatility in thinly traded securities / derivatives
                        contracts than in active securities /derivatives contracts. As a result of volatility, your order may only be partially
                        executed or not executed at all, or the price at which your order got executed may be substantially different from
                        the last traded price or change substantially thereafter, resulting in notional or real losses.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.2 Risk of Lower Liquidity:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Liquidity refers to the ability of market participants to buy and/or sell securities / derivatives contracts
                        expeditiously at a competitive price and with minimal price difference. Generally, it is assumed that more the
                        numbers of orders available in a market, greater is the liquidity. Liquidity is important because with greater
                        liquidity, it is easier for investors to buy and/or sell securities / derivatives contracts swiftly and with minimal price
                        difference, and as a result, investors are more likely to pay or receive a competitive price for securities /
                        derivatives contracts purchased or sold. There may be a risk of lower liquidity in some securities / derivatives
                        contracts as compared to active securities / derivatives contracts. As a result, your order may only be partially
                        executed, or may be executed with relatively greater price difference or may not be executed at all.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1.2.1 Buying or selling securities / derivatives contracts as part of a day trading strategy may also result into
                        losses, because in such a situation, securities / derivatives contracts may have to be sold / purchased at low /
                        high prices, compared to the expected price levels, so as not to have any open position or obligation to deliver or
                        receive a security / derivatives contract.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.3 Risk of Wider Spreads:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Spread refers to the difference in best buy price and best sell price. It represents the differential between the
                        price of buying a security / derivatives contract and immediately selling it or vice versa. Lower liquidity and
                        higher volatility may result in wider than normal spreads for less liquid or illiquid securities / derivatives
                        contracts. This in turn will hamper better price formation.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.4 Risk-reducing orders:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The placing of orders (e.g., "stop loss" orders, or "limit" orders) which are intended to limit losses to certain
                        amounts may not be effective many a time because rapid movement in market conditions may make it
                        impossible to execute such orders.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1.4.1 A "market" order will be executed promptly, subject to availability of orders on opposite side, without
                        regard to price and that, while the customer may receive a prompt execution of a "market" order, the execution
                        may be at available prices of outstanding orders, which satisfy the order quantity, on price time priority. It may be
                        understood that these prices may be significantly different from the last traded price or the best price in that
                        security / derivatives contract.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1.4.2 A "limit" order will be executed only at the "limit" price specified for the order or a better price. However,
                        while the customer receives price protection, there is a possibility that the order may not be executed at all.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1.4.3 A stop loss order is generally placed "away" from the current price of a stock / derivatives contract, and
                        such order gets activated if and when the security / derivatives contract reaches, or trades through, the stop
                        price. Sell stop orders are entered ordinarily below the current price, and buy stop orders are entered ordinarily
                        above the current price. When the security / derivatives contract reaches the pre -determined price, or trades
                        through such price, the stop loss order converts to a market/limit order and is executed at the limit or better.
                        There is no assurance therefore that the limit order will be executable since a security / derivatives contract
                        might penetrate the pre-determined price, in which case, the risk of such order not getting executed arises, just
                        as with a regular limit order.
                    </Typography>

                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.5 Risk of News Announcements:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        News announcements that may impact the price of stock / derivatives contract may occur during trading, and
                        when combined with lower liquidity and higher volatility, may suddenly cause an unexpected positive or
                        negative movement in the price of the security / contract.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.6 Risk of Rumors:
                    </h3>

                    <Typography className={classes.p_body} style={{ padding: '2px 0px 0px 0px' }} >
                        Rumors about companies / currencies at times float in the market through word of mouth, newspapers,
                        websites or news agencies, etc. The investors should be wary of and should desist from acting on rumors.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.7 System Risk:
                    </h3>

                    <Typography className={classes.p_body} style={{ padding: '2px 0px 0px 0px' }} >
                        High volume trading will frequently occur at the market opening and before market close. Such high volumes
                        may also occur at any point in the day. These may cause delays in order execution or confirmation.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '2px 0px 0px 0px' }} >
                        1.7.1 During periods of volatility, on account of market participants continuously modifying their order quantity
                        or prices or placing fresh orders, there may be delays in order execution and its confirmations.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '2px 0px 0px 0px' }} >
                        1.7.2 Under certain market conditions, it may be difficult or impossible to liquidate a position in the market at a
                        reasonable price or at all, when there are no outstanding orders either on the buy side or the sell side, or if
                        trading is halted in a security / derivatives contract due to any action on account of unusual trading activity or
                        security / derivatives contract hitting circuit filters or for any other reason.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        1.8 System/Network Congestion:
                    </h3>

                    <Typography className={classes.p_body} style={{ padding: '2px 0px 0px 0px' }} >
                        Trading on exchanges is in electronic mode, based on satellite/leased line based communications,
                        combination of technologies and computer systems to place and route orders. Thus, there exists a possibility of
                        communication failure or system problems or slow or delayed response from system or trading halt, or any such
                        other problem/glitch whereby not being able to establish access to the trading system/network, which may be
                        beyond control and may result in delay in processing or not processing buy or sell orders either in part or in full.
                        You are cautioned to note that although these problems may be temporary in nature, but when you have
                        outstanding open positions or unexecuted orders, these represent a risk because of your obligations to settle all

                        executed transactions.
                    </Typography>

                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        2. As far as Derivatives segments are concerned, please note and get yourself acquainted with the
                        following additional features:-
                    </h3>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        2.1 Effect of "Leverage" or "Gearing":
                    </h3>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        In the derivatives market, the amount of margin is small relative to the value of the derivatives contract so the
                        transactions are 'leveraged' or 'geared'. Derivatives trading, which is conducted with a relatively small amount
                        of margin, provides the possibility of great profit or loss in comparison with the margin amount. But transactions
                        in derivatives carry a high degree of risk
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        You should therefore completely understand the following statements before actually trading in derivatives and
                        also trade with caution while taking into account one's circumstances, financial resources, etc. If the prices
                        move against you, you may lose a part of or whole margin amount in a relatively short period of time. Moreover,
                        the loss may exceed the original margin amount
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        A. Futures trading involve daily settlement of all positions. Every day the open positions are marked to market
                        based on the closing level of the index / derivatives contract. If the contract has moved against you, you will be
                        required to deposit the amount of loss (notional) resulting from such movement. This amount will have to be
                        paid within a stipulated time frame, generally before commencement of trading on next day.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        B. If you fail to deposit the additional amount by the deadline or if an outstanding debt occurs in your account,
                        the stock broker may liquidate a part of or the whole position or substitute securities. In this case, you will be
                        liable for any losses incurred due to such close-outs
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        C. Under certain market conditions, an investor may find it difficult or impossible to execute transactions. For
                        example, this situation can occur due to factors such as illiquidity i.e. when there are insufficient bids or offers or
                        suspension of trading due to price limit or circuit breakers etc.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        D. In order to maintain market stability, the following steps may be adopted: changes in the margin rate,
                        increases in the cash margin rate or others. These new measures may also be applied to the existing open
                        interests. In such conditions, you will be required to put up additional margins or reduce your positions.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        E. You must ask your broker to provide the full details of derivatives contracts you plan to trade i.e. the contract
                        specifications and the associated obligations.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        2.2 Currency specific risks:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. The profit or loss in transactions in foreign currency-denominated contracts, whether they are traded in your
                        own or another jurisdiction, will be affected by fluctuations in currency rates where there is a need to convert
                        from the currency denomination of the contract to another currency.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. Under certain market conditions, you may find it difficult or impossible to liquidate a position. This can occur,
                        for example when a currency is deregulated or fixed trading bands are widened.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. Currency prices are highly volatile. Price movements for currencies are influenced by, among other things:
                        changing supply-demand relationships; trade, fiscal, monetary, exchange control programs and policies of
                        governments; foreign political and economic events and policies; changes in national and international interest
                        rates and inflation; currency devaluation; and sentiment of the market place. None of these factors can be
                        controlled by any individual advisor and no assurance can be given that an advisor's advice will result in
                        profitable trades for a participating customer or that a customer will not incur losses from such events.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ padding: '10px 0px 0px 0px' }} >
                        2.3 Risk of Option holders:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. An option holder runs the risk of losing the entire amount paid for the option in a relatively short period of time.
                        This risk reflects the nature of an option as a wasting asset which becomes worthless when it expires. An option
                        holder who neither sells his option in the secondary market nor exercises it prior to its expiration will necessarily
                        lose his entire investment in the option. If the price of the underlying does not change in the anticipated direction
                        before the option expires, to an extent sufficient to cover the cost of the option, the investor may lose all or a
                        significant part of his investment in the option.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. The Exchanges may impose exercise restrictions and have absolute authority to restrict the exercise of
                        options at certain times in specified circumstances.
                    </Typography>
                    <h3 className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2.4 Risks of Option Writers:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. If the price movement of the underlying is not in the anticipated direction, the option writer runs the risks of
                        losing substantial amount.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. The risk of being an option writer may be reduced by the purchase of other options on the same underlying
                        interest and thereby assuming a spread position or by acquiring other types of hedging positions in the options
                        markets or other markets. However, even where the writer has assumed a spread or other hedging position, the
                        risks may still be significant. Aspread position is not necessarily less risky than a simple 'long' or 'short' position.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. Transactions that involve buying and writing multiple options in combination, or buying or writing options in
                        combination with buying or selling short the underlying interests, present additional risks to investors.
                        Combination transactions, such as option spreads, are more complex than buying or writing a single option.
                        And it should be further noted that, as in any area of investing, a complexity not well understood is, in itself, a risk
                        factor. While this is not to suggest that combination strategies should not be considered, it is advisable, as is the
                        case with all investments in options, to consult with someone who is experienced and knowledgeable with
                        respect to the risks and potential rewards of combination transactions under various market circumstances.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        3. TRADING THROUGH WIRELESS TECHNOLOGY/ SMART ORDER ROUTING OR ANY OTHER
                        TECHNOLOGY:
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Any additional provisions defining the features, risks, responsibilities, obligations and liabilities associated with
                        securities trading through wireless technology/ smart order routing or any other technology should be brought
                        to the notice of the client by the stock broker.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        4. GENERAL
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4.1 The term 'constituent' shall mean and include a client, a customer or an investor, who deals with a stock
                        broker for the purpose of acquiring and/or selling of securities / derivatives contracts through the mechanism
                        provided by the Exchanges.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4.2 The term 'stock broker' shall mean and include a stock broker, a broker or a stock broker, who has been
                        admitted as such by the Exchanges and who holds a registration certificate from SEBI.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        GUIDANCE NOTE - DO's AND DON'Ts FOR TRADING ON THE EXCHANGE(S) FOR INVESTORS
                    </h1>
                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        BEFORE YOU BEGIN TO TRADE
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. Ensure that you deal with and through only SEBI registered intermediaries. You may check their SEBI
                        registration certificate number from the list available on the Stock exchanges
                        www.nseindia.com/www.bseindia.com and SEBI website www.sebi.gov.in
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. Ensure that you fill the KYC form completely and strike off the blank fields in the KYC form
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. Ensure that you have read all the mandatory documents viz. Rights and Obligations, Risk Disclosure
                        Document, Policy and Procedure document of the stock broker.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4. Ensure to read, understand and then sign the voluntary clauses, if any, agreed between you and the
                        stock broker. Note that the clauses as agreed between you and the stock broker cannot be changed without
                        your consent.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        5. Get a clear idea about all brokerage, commissions, fees and other charges levied by the broker on you for
                        trading and the relevant provisions/ guidelines specified by SEBI/Stock exchanges.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        6. Obtain a copy of all the documents executed by you from the stock broker free of charge.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        7. In case you wish to execute Power of Attorney (POA) in favour of the Stock broker, authorizing it to
                        operate your bank and demat account, please refer to the guidelines issued by SEBI/Exchanges in this
                        regard.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        TRANSACTIONS AND SETTLEMENTS
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        8. The stock broker may issue electronic contract notes (ECN) if specifically authorized by you in writing.
                        You should provide your email id to the stock broker for the same. Don't opt for ECN if you are not familiar
                        with computers.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        9. Don't share your internet trading account's password with anyone.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        10. Don't make any payment in cash to the stock broker.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        11. Make the payments by account payee cheque in favour of the stock broker. Don't issue cheques in the
                        name of sub-broker. Ensure that you have a documentary proof of your payment/deposit of securities with
                        the stock broker, stating date, scrip, quantity, towards which bank/ demat account such money or securities
                        deposited and from which bank/ demat account.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        12. Note that facility of Trade Verification is available on stock exchanges' websites, where details of trade
                        as mentioned in the contract note may be verified. Where trade details on the website do not tally with the
                        details mentioned in the contract note, immediately get in touch with the Investors Grievance Cell of the
                        relevant Stock exchange.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        13. In case you have given specific authorization for maintaining running account, payout of funds or
                        delivery of securities (as the case may be), may not be made to you within one working day from the receipt
                        of payout from the Exchange. Thus, the stock broker shall maintain running account for you subject to the
                        following conditions:
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        a) Such authorization from you shall be dated, signed by you only and contains the clause that you may
                        revoke the same at any time.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        b) The actual settlement of funds and securities shall be done by the stock broker, at least once in a
                        calendar quarter or month, depending on your preference. While settling the account, the stock broker shall
                        send to you a 'statement of accounts' containing an extract from the client ledger for funds and an extract
                        from the register of securities displaying all the receipts/deliveries of funds and securities. The statement
                        shall also explain the retention of funds and securities and the details of the pledged shares, if any.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        c) On the date of settlement, the stock broker may retain the requisite securities/funds towards outstanding
                        obligations and may also retain the funds expected to be required to meet derivatives margin obligations for
                        next 5 trading days, calculated in the manner specified by the exchanges. In respect of cash market

                        transactions, the stock broker may retain entire pay-in obligation of funds and securities due from clients as
                        on date of settlement and for next day's business, he may retain funds/securities/margin to the extent of
                        value of transactions executed on the day of such settlement in the cash market.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        d) You need to bring any dispute arising from the statement of account or settlement so made to the notice
                        of the stock broker in writing preferably within 7 (seven) working days from the date of receipt of
                        funds/securities or statement, as the case may be. In case of dispute, refer the matter in writing to the
                        Investors Grievance Cell of the relevant Stock exchanges without delay.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        14. In case you have not opted for maintaining running account and pay-out of funds/securities is not
                        received on the next working day of the receipt of payout from the exchanges, please refer the matter to the
                        stock broker. In case there is dispute, ensure that you lodge a complaint in writing immediately with the
                        Investors Grievance Cell of the relevant Stock exchange.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        15. Please register your mobile number and email id with the stock broker, to receive trade confirmation
                        alerts/ details of the transactions through SMS or email, by the end of the trading day, from the stock
                        exchanges.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        IN CASE OF TERMINATION OF TRADING MEMBERSHIP
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        16. In case, a stock broker surrenders his membership, is expelled from membership or declared a
                        defaulter; Stock exchanges gives a public notice inviting claims relating to only the "transactions executed
                        on the trading system" of Stock exchange, from the investors. Ensure that you lodge a claim with the
                        relevant Stock exchanges within the stipulated period and with the supporting documents.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        17. Familiarize yourself with the protection accorded to the money and/or securities you may deposit with
                        your stock broker, particularly in the event of a default or the stock broker's insolvency or bankruptcy and the
                        extent to which you may recover such money and/or securities may be governed by the Bye-laws and
                        Regulations of the relevant Stock exchange where the trade was executed and the scheme of the Investors'
                        Protection Fund in force from time to time.
                    </Typography>
                    <h1 className={classes.heading} style={{ padding: '10px 0px 0px 0px' }} >
                        DISPUTES/ COMPLAINTS
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        18. Please note that the details of the arbitration proceedings, penal action against the brokers and investor
                        complaints against the stock brokers are displayed on the website of the relevant Stock exchange.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        19. In case your issue/problem/grievance is not being sorted out by concerned stock broker/sub-broker
                        then you may take up the matter with the concerned Stock exchang
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        20. Note that all the stock broker/sub-brokers have been mandated by SEBI to designate an e-mail ID of the
                        grievance redressal division/compliance officer exclusively for the purpose of registering complaints.
                    </Typography>
                    <h1 className={classes.heading} style={{ textAlign: 'center', fontSize: '30px', padding: '10px 0px 0px 0px' }} >
                        POLICIES AND PROCEDURES
                    </h1>
                    <h3 className={classes.p_body1} style={{ textAlign: 'center', padding: '2px 0px 0px 0px' }} >
                        (Reference para 8 of SEBI Circular No. MIRSD/SE/CIR-19/2009 dated
                        December 03,2009
                    </h3>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        A. Refusal of orders penny stocks etc
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The stock broker may at his discretion refuse to execute any buy or sell on behalf of the client including but not
                        restricted to dealings in penny stocks, illiquid stocks, infrequently traded stocks/contract etc if the stock Broker
                        is of the view that such execution would adversely affect market integrity or give rise to regulatory / disciplinary
                        actions/ concerns. The penny stock would mean any stock trading at a price less than Rs. 10 or at a price less
                        than the face value or any other stock specified by the Regulatory Body/Stock Exchange as such or which are
                        appearing in the list of illiquid securities issued by the Exchanges every month. In general terms, a is a lowpriced, speculative security or a very small company, regardless of market capitalization.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        B. Setting up client's exposure limits
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The Stock Broker may at his discretion permit/allow such exposure limit for trading by Client as he deems fit.
                        Such exposure limit may operate specific to a security or contract and/or on an aggregate basis whether on the
                        buy or the sell side, based on the Stock Broker's assessment of the associated risks having due regard to all
                        relevant factors. Further, the Stock Broker may modify, change or alter such limit or the conditions attached
                        thereto from time to time as may be deemed fit.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        C. Brokerage Rates
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        Brokerage will be charged within the limits prescribed by SEBI/Exchange
                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        D. Imposition of penalty/Delayed Payment Charges:
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The Client shall pay the Stock Broker brokerage, commission, fees, account opening/ maintenance charges,
                        charges for availing research reports, charges for availing special facility for mobile broking /SMS facility or any
                        other charges for the special services/ facilities availed by the Client, inter settlement charges, auction charges,
                        penalties levied by the exchanges for client limit violation, charges for dishonour of cheque(s) given by the
                        client, statutory levies, service tax, and other taxes and trade/transaction expenses including inter alia
                        depository charges, settlement charges etc. as are applicable from time to time. If the Client fails to make
                        payment of the amount due within the time frame specified by the Bye-laws, Rules and Regulations of the
                        Exchange and/or as per the policy of the Stock Broker, Stock Broker shall be entitled to levy such charges by
                        way of penalty or delayed payment charges not exceeding 18% per annum on amount due as the Stock Broker
                        may deem fit and to directly debit the same to the Client account. The Client authorizes the Stock Broker to set
                        off a part or whole of the collateral/ ledger balances i.e. By way of appropriation of the relevant amount of cash
                        or by way of sales or transfer of all or some of the securities, without notice, or invoke the pledged shares placed
                        as margin/collateral with the Stock Broker, and/ or any credit in any account of the Client in any of the segment
                        of the Stock Exchange, against the outstanding/dues, to the extent of settlement / margin obligation in the
                        account of the Client for any segment of the Stock Exchanges. The adjustment, so done, shall be by way of a
                        passing necessary journal voucher entries. Further, the Stock Broker agree to pay penalty charges not
                        exceeding 18% Per annum on amount due to the client if the same is not refunded to the client in time after
                        receipt of pay out request from the Client
                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        E. The Right to sell Client's securities or to close Client's positions without notice to Client
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        If a client fails to make payment of consideration to the Stock Broker in respect of any one or more securities
                        purchased by him before the pay-in date notified by the Exchange from time to time, the Stock Broker shall be at
                        liberty to sell / liquidate the securities received in pay-out, as per the policy of the Stock Broker, after taking into
                        account any amount lying to the credit of the Client. The loss, if any on account of liquidation shall any on
                        account of liquidation shall be to the account of the Client. Without prejudice to the Stock Broker's other rights
                        the Stock broker shall be entitled to liquidate/close out all or any of the Client's positions, without giving notice to
                        the Client, for non-payment of margins or other amounts, outstanding debts, etc. Any and all losses and
                        financial charges on account of such liquidation/closing-out shall be charged to and borne by the Client.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        G. Conditions under which Client may not be allowed to take further position or the Stock Broker may
                        close the existing position of the Client
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The Stock Broker may at any time at his discretion disallow the Client from creating further/fresh position in
                        any segment on any exchange or close out any existing position of the Client based on his risk assessment or
                        if so directed by any government / regulatory stock exchange authority.
                    </Typography>

                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        H. Temporary Suspension /Closure of a client's Account
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The stock Broker shall if so required in writing by the Client temporarily suspend/ disable trading in the Client's
                        account unless so required for the purpose of closing of transactions referred to in (E)and (G) above and
                        maintain such suspension/disablement till such time the Client makes a specific request in that regard. Stock
                        Broker as a protective measure and also good governance carries out internal checks on the transactional
                        activities in the clients accounts to verify trading in dormant accounts, trading in illiquid scripts, any
                        manipulated activities by the Client's (detectable), ECN bounces and trading activities not commensurate with
                        income declared, other money laundering activities, first time trade in future and options, spurt in volumes and
                        any other activities which broker may feel is derogatory/detrimental to the market or client. These activities are
                        carried out as proactive measures and some of them as various regulatory requirements. In above cases,
                        Stock Broker may deactivate the client account in the system for the purpose of further trading activity with or
                        without notice. The Client agrees to co operate with the Stock Broker to maintain the integrity of the market and
                        also to facilitate Stock Broker to provide safe trading platform to all its clients. Stock Brokers or its employees
                        or employees of any its group companies will not be held liable for his acts for any of the losses or notional
                        losses that may occur in your account due to any of the above actions.
                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        I. Deregistration of a Client
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The Stock Broker may at any time, as it considers necessary in its sole discretion and shall be entitled, at his
                        absolute discretion to not to allow the Client to trade, unless such Client provides margins to the extent as may
                        be specified by the Stock Broker considering the risk factor
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The Stock Broker shall be entitled to suspend or terminate this agreement without prior notice if
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. The Client has breached this Agreement:
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. Upon the death, winding up, bankruptcy, liquidation or legal capaciation of the Client or is designated
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. The Client fails to maintain the Bank Account and/or the Securities Account (Or any replacement thereof) as
                        a defaulter by any credit rating agency or any action or proceedings have been initiated by the relevant
                        Regulator/Authority including without limitation SEBI:
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4. The Client has misrepresented facts at the time of entering into this Agreement or at the time of giving
                        instructions or otherwise.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        5. Any proceedings or investigations that involve the Client or his/its properties have been initiated or is
                        ongoing.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        6. The client fails to fulfill his/its payment obligations under this Agreement or otherwise due to the Stock
                        Broker;
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        7. The Client has violated the Applicable law particularly the securities Law and Bye-laws, Rules and
                        Regulations of the respective Stock Exchanges on which the Client trades,
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        8. If the client migrates to a jurisdiction which prohibits trading in Indian securities or otherwise subjects the
                        Stock Broker or any of its employees to any licensing or registration requirements.

                    </Typography>
                    <h3 className={classes.p_body1} style={{ textDecoration: 'underline', padding: '2px 0px 0px 0px' }} >
                        J. Inactive Accounts
                    </h3>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        ATrading Account is which no trades are done across in any segment of any exchange for Ninety days would
                        be termed as “Inactive” or “Dormant” trading account. The Stock Broker does not freeze any “Inactive” or
                        “Dormant” trading account. However, any trade emanating from such trading accounts pursuant to the same
                        being classified as “Inactive” or “Dormant” trading account would be subject to necessary due diligences and
                        confirmations as the Stock Broker may deem fit.
                        The Stock Broker shall be entitled to change or alter this Policy or any part thereof, without giving any reason
                        thereof to the Client. Notwithstanding any such change or alteration all rights, liabilities and obligations of the
                        parties arising out of or in respect of transactions entered into prior to such change or alteration shall continue
                        to subsist and vest in / be binding on the respective heirs, executors, administrators, legal representative or
                        successors, as the case may be. Notice of such alteration may be provided by the Stock Broker by updating
                        the same on the website. The Client is bound by such online change or alterations
                    </Typography>

                    <h3 className={classes.p_body1} style={{ textAlign: 'center', padding: '10px 0px 0px 0px' }} >
                        This is must read/ understood and to be complied by everybody dealing/ desirous in dealing in Capital
                        and / or Derivatives (including Currency Derivative)
                    </h3>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        1. Prevention of Money Laundering Act, 2002 (PMLA) is enacted to prevent the financing of terrorism and to
                        prevent laundering of money i.e. to legalize or officialize or canalise the money generated from illegal activities
                        like drug trafficking, organized crimes, hawala rackets and other serious crimes etc.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        2. PMLAis a part of the Global measures being taken by all the countries under the initiatives of United Nations.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        3. It is an obligation of individual/entities to whom PMLAis applicable, to report certain kind of transactions routed
                        through them to Financial Intelligence Unit (FIU), a department specially set up to administer PMLA under the
                        Ministry of Finance.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        4. PMLA is, inter-alia, applicable to various intermediaries which includes stock brokers, commodity brokers,
                        sub-brokers, authorised person and depository participant etc.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        5. As per PMLAthe following type of transaction are to be reported to FIU: -
                        (A). All cash transactions of the value of more than Rs. 10 Lacs or its equivalent in foreign currency. (B). All series
                        of cash transactions integrally connected to each other which have been valued below Rs. 10 Lacs or its
                        equivalent in foreign currency where such series of transactions takes place within one calendar month. (C). All
                        suspicious transactions whether or not made in cash and including, inter-alia, credits or debits into a n y n o n
                        monetary accounts such as demat account.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        6. Any such above transaction(s), though not executed but attempted and failed are also required to be reported
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        7. The suspicious transaction(s) can be related to the transaction(s) under the circumstances such as: -
                        (A). Client(s) whose identity verification seems difficult or client(s) that appear not to co-operate;(B). Asset
                        management services for client(s) where the source of the funds is not clear or not in keeping with client(s)
                        apparent standing /business activity; (C). Client(s) based in high risk jurisdictions; (D). Substantial increases in
                        business without apparent cause; (E). Client(s) transferring large sum of money to or from overseas locations
                        with instructions for payment in cash; (F). Attempted transfer of investment proceeds to apparently unrelated
                        third parties; (G). Businesses undertaken by offshore banks/financial services; (H). Businesses reported to be in
                        the nature of export/import of small items; (I). Unusual transactions by Clients of Special Categories (CSCs).
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        8. Clients of Special Categories includes: -
                        (A). Non-resident client; (B). High net-worth client (having annual income + networth of more than Rs. 1 Crore);
                        (C). Trust, Charities NGOs and organizations receiving donations; (D). Company having close family
                        shareholdings or beneficial ownership; (E). Civil Servant or family member or close relative of civil servant;
                        (F). Bureaucrat or family member or close relative of bureaucrat; (G). Current or Former MP or MLA or MLC or
                        their family member or close relative; (H). Politician or their family member or close relative; (I). Current or
                        Former Head of State or of Governments or their family member or close relative; (J). Senior government/judicial/
                        military officers or their family member or close relative; (K). Senior executives of state-owned corporations or
                        their family member or close relative; (L). Companies offering foreign exchange offerings;
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        9. While opening the new account all the prescribed procedures of KYC and Client Identifications should
                        strictly be followed in the context of ensuring the compliance under PMLA.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        10. All the record of transaction(s) and client identifications must be preserved in a manner which can be retrieved
                        promptly and reported to the authorities in the specified format as and when required.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        11. The Clients are advised to be fully conversant with the provisions of PMLAand any amendments thereto from
                        time to time and to co-operate with intermediaries by providing the additional information(s)/document(s), if
                        asked for, to ensure the compliance requirements under PMLA.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        12. The Client are advised to provide certain information which may be of personal nature or has hitherto never
                        been called for such information can include documents evidencing source of funds/income taxreturns/bank
                        records etc. You are advised to co-operate with us whenever such information is sought for from PMLA
                        perspective.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        13. The Clients are advised to be vigilant and to refrain from temptation of easy monetary gains, by knowingly or
                        unknowingly supporting the people who are involved in the activities which are endangering freedom and
                        causing damage to the nation. The Clients are supposed to provide their active co-operation in the due
                        compliance of the law.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        14. Please visit the website of Financial Intelligence Unit ( ) and Securities and Exchange
                        Board of India ( ) for any further information on the subject.
                    </Typography>
                </Grid>

            </div >
        )
    }
}
export default (withStyles(styles)((obligations)));