import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'
import { Close, CurrencyRupee } from '@mui/icons-material';
import { signup_funcation } from './signup_funcation';
import verfiy from '../../../ui-assets/verfiy.svg'
import unverfiy from '../../../ui-assets/unverfiy.svg'
import hdfc from '../../../ui-assets/hdfc1.svg'
import atom from '../../../ui-assets/atom.svg'
import * as CryptoJS from 'crypto-js';
import {
    Typography, Grid, Button, Modal, Box, FormHelperText, TextField, FormControl,
    Checkbox, Radio, RadioGroup, FormControlLabel, Snackbar, SnackbarContent, IconButton
} from '@mui/material'
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'

        }
    },
    sigCanvas: {
        background: '#ffffff'
    },
    shutter: {
        marginTop: '50px',
        width: '100%',
        height: '100vh',
        background: 'red',
        borderTopLeftRadius: '8px',
        borderTopRightRaduis: '8px',
    }
}


const styles = theme => ({
    paymentcard: {
        width: '100%',
        
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '70px 1px',
            marginLeft: '0%',
          
        }
    },
    payusecard: {

        width: '100%', display: 'flex', marginTop: '25px', justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            justifyContent: 'center',
            padding: '1px',
            marginLeft: '0%',

        }

    },
    payusecard1: {

        width: '100%', display: 'flex',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%',
            justifyContent: 'center',
            padding: '1px',
            marginLeft: '0%',

        }

    },
    pay_amount_card: {

        width: '50%',

        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },

    pay_amount_card1: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '15px',

        }
    },
    pay_amount_opt: {
        width: '50%',

        "@media only screen and (max-device-width: 480px)": {

            width: '100%',
        justifyContent:'center',
        display:'grid',
            marginTop: '10px',
            marginLeft: '-3%'
        }
    },
    pay_amount_opt1: {
        width: '100%', display: 'flex', alignItems: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            display: 'flex', alignItems: 'center',



        }
    },

    pay_amount_upi: {
        width: '60%', marginLeft: '5px',
        "@media only screen and (max-device-width: 480px)": {

            width: '100%', marginLeft: '0px',



        }

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    payment_succ_txt: {
        padding: '2% 0% 0% 0%', textAlign: 'center', color: '#3E3E3E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '24px !important',
    },
    payment_succ_txt1: {
        padding: '5px', textAlign: 'center', color: '#3E3E3E !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
    },

    payment_ok: {
        background: "#F74542 !important",
        padding: "10px 24px !important",
        borderRadius: "24px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        width: '40%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',

            marginLeft: '0%',

        },

    },
    bankcard: {
        width: '100%',
        display: 'flex',

        marginTop: '25px',
        "@media only screen and (max-device-width: 480px)": {
            width: '110%',

            display: 'flex',

        },

    },
    payment_dig: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: 'auto',

        outline: 'none',
        background: '#ffffff',
        p: 4,
        borderRadius: '8px',
        padding: '2%',
        justifyContent: 'center', alignItems: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%',


        }

    }

})

class exchanges extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            equity: true,
            fando: true,
            currency: false,
            paymentvalue: 'upi',
            upipay: 1,
            payment_succ: false,

            bankvalue: 'atom'
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {

        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)

        window.scrollTo(0, 0);

        this.setState({ fando: rd2.fando }) //To store fando Value To the state variable From DB
      

    }
    Continue9_1 = async () => {
        const { equity, fando, currency, commodity } = this.state


        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid = rd2.emailid
        let mobileno = rd2.mobileno
        let skipaa = rd2.skipaa //To cheque fando option after payment successful. 
        let isok = 0
        //Added to skip account aggregator for NRI For fando selection 
        let citizen=rd2.citizen
     
          if(citizen===1 || rd2.ifsc===null){
            skipaa=true
          }
          //added on 30/08/2024 
          if(citizen===0 && rd2.ifsc!==null && fando === true){
            skipaa=false
          }

        if (equity === false && fando === false && currency === false && commodity === false) {
            isok = 1
            this.setState({ showerr: true, errorMsg: "Select Account Type", mysnack: snackeror.myerror })

        }
        if (isok === 0) {

            signup_funcation(rd1, "stage", 0)
            let payload = {
                "equity": equity,
                "fando": fando,
                "currency": currency,
                "commodity": commodity,
                "emailid": emailid,
                "mobileno": mobileno
            }
            try {
                const otp = await httpRequest({
                    endPoint: `/api/registration/exchange`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }

                this.setState({ islodding: false })
                if (otp.status === 200) {

                    let obj = {}
                    let maindata = JSON.parse(rd1)
                    for (var key in maindata) {
    
                        let f = 0
                      
                        if (key === "fando") {
                            obj[key] = this.state.fando //To store Fndo Values into local variable. 
                            f = 1
                            if(citizen===1)
                                {
                                    obj[key] = true
                                }
                        }
                      
                        if (key === "commodity") {
                            obj[key] = commodity //To store Fndo Values into local variable. 
                            f = 1
                        }
                        if (key === "currency") {
                            obj[key] =currency //To store Fndo Values into local variable. 
                            f = 1
                        }
                        if (key === "equity") {
                            obj[key] = equity //To store Fndo Values into local variable. 
                            f = 1
                        }
                     

                        if (f === 0) {
                            obj[key] = maindata[key]
                        }
    
                    }
    
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))

if(citizen!==1){
                    if (skipaa === true) {
                        window.ChangePageNo(16.1)
                    }else
                    {
                        window.ChangePageNo(17.1)
                    }

                    
                }else{
                    window.ChangePageNo(16.1)
                }
                    this.setState({ payment_succ: false, backimg_style: 1 })

                } else {
                    this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
                }
            } catch (error) {

                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

            }

        }

    }




    render() {

        const { classes } = this.props;
        const { paymentvalue, upipay, bankname, bankvalue } = this.state;
        return (
            <div>   <Grid className={classes.paymentcard} >
                <Typography style={{ color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '24px',textAlign:'center' }}>Select Segments</Typography>

          

                <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }} >
                    <Grid className={classes.pay_amount_opt} >
                        <Grid className={classes.pay_amount_opt1} >
                            <Checkbox
                                checked={this.state.equity}
                               size={ window.innerWidth<600 ? 'large' :'medium'}
                                sx={{
                                    color: '#999999',
                                    borderRadius: '4px',
                                    '&.Mui-checked': {
                                        color: '#F74542',
                                    },
                                }}
                                onChange={() => this.setState({ equity: !this.state.equity })}
                            />
                            <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '16px' }}>Equity</Typography>
                        </Grid>
                        <Grid className={classes.pay_amount_opt1} >
                            <Checkbox
                                checked={this.state.fando}
                                size={ window.innerWidth<600 ? 'large' :'medium'}
                                sx={{
                                    color: '#999999',
                                    borderRadius: '4px',
                                    '&.Mui-checked': {
                                        color: '#F74542',
                                    },
                                }}
                                onChange={() => this.setState({ fando: !this.state.fando })}
                            />
                            <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>F&O</Typography>
                        </Grid>

                        <Grid className={classes.pay_amount_opt1} >

                            <Checkbox
                                checked={this.state.commodity}
                                size={ window.innerWidth<600 ? 'large' :'medium'}
                                sx={{
                                    color: '#999999',
                                    borderRadius: '4px',
                                    '&.Mui-checked': {
                                        color: '#F74542',


                                    },
                                    '&.MuiSvgIcon-root': {
                                        fill: '#ffffff'
                                    }
                                }}
                                onChange={() => this.setState({ commodity: !this.state.commodity })}
                            />
                            <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Commodity</Typography>
                        </Grid>
                        {/*<Grid className={classes.pay_amount_opt1} >
                            <Checkbox

                                checked={this.state.currency}
                                size='small'
                                sx={{
                                    color: '#999999',
                                    borderRadius: '4px',
                                    '&.Mui-checked': {
                                        color: '#F74542',
                                    },
                                }}
                                onChange={() => this.setState({ currency: !this.state.currency })}
                            />
                            <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Currency</Typography>
                        </Grid>*/}
                    </Grid>

                </Grid>
                {/*  <Typography style={{ marginTop: '6%', color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '24px' }}>Pay using</Typography>
           
             <Grid className={classes.payusecard} >
          <RadioGroup
              row
              defaultValue="upi"
              value={paymentvalue}


              onChange={e =>
                  this.handlePaymentMode(e)


              }
          >
              <FormControlLabel value="upi" control={<Radio
                  disableripple
                
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={<Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>UPI</Typography>} />
              <FormControlLabel value="onlinetransfer" control={<Radio

               
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={<Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Card / Net banking</Typography>} />







          </RadioGroup>
                </Grid>*/}



                <Grid className={classes.payusecard} >
                    <Grid className={classes.pay_amount_upi} >

                        <Button fullWidth className={classes.joinDiplomtsBtn}
                            style={{ marginTop: '20px', }}
                            onClick={() => this.Continue9_1()}
                        >Continue

                        </Button>

                    </Grid>
                </Grid>
                <form id="nonseamless" name='redirect' action='https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction' method='POST'>

                    <input id="encRequest" type='hidden' name='encRequest' />
                    <input type="hidden" name="access_code" id="access_code" value="AVZB26KJ67CH57BZHC" />
                    <input type="hidden" name="response_type" id="response_type" value="JSON" />

                    <input style={{ display: 'none' }} id="loginSubmit" type="submit" />
                </form>
            </Grid>
                <div style={{ width: '100%' }}>
                    <Typography style={{ textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography></div>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>


                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div>
        )
    }
}
export default (withStyles(styles)((exchanges)));
