import React, { Component } from 'react'
import cong_bull from '../../ui-assets/cong_bull.svg'
import { withStyles, } from "@mui/styles";
import { Grid, Typography, FormControl, TextField,
    MenuItem,Select,InputLabel,Snackbar, SnackbarContent,IconButton,
    FormHelperText, Button} from '@mui/material'
const styles = theme => ({
    card:{
        width:'100%',
        display:'flex',
        height:'100vh',
    justifyContent:'center',
    alignItems:'center',
    "@media only screen and (max-device-width: 480px)": {
        marginTop:'20%',
        
        alignItems:'start'
    },
        
    },
        card_tit: {
            color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',textAlign:'center',
    
            "@media only screen and (max-device-width: 480px)": {
                fontSize: '24px !important',marginTop:'5%'
            },
    
        },
        email_sub_tit:{
            marginTop: '15px', color: '#DFE0EB', fontFamily: 'cardiuma-M !important', fontSize: '20px !important',textAlign:'center',
            "@media only screen and (max-device-width: 480px)": {
                display: 'flex'
            }
        },
        email_sub_tit1:{
            marginTop: '15px', color: '#DFE0EB', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',textAlign:'center',
            "@media only screen and (max-device-width: 480px)": {
                display: 'flex',
            }
        },
        errtxt: {
            color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
        },
        joinDiplomtsBtn: {
            background: "#F74542 !important",
            padding: "12px 45px !important",
            borderRadius: "20px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#FFFFFF !important",
            textTransform: "initial !important",
            fontFamily: "cardiuma-M !important",
            marginTop:'5%',
            width:'50%'
        },
        joinDiplomtsBtn1: {
    
            background: "#ffffff !important",
            padding: "12px 45px !important",
            borderRadius: "20px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#F74542 !important",
            borderBottomColor:'1px solid #F74542',
            textTransform: "initial !important",
            fontFamily: "cardiuma-M !important",
            marginTop:'5%',
            width:'50%'
        },
    
        joinDiplomtsBtn2: {
            background: 'transparent !important',
            padding: "12px 45px !important",
            borderRadius: "20px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#0083CA        !important",
            
            textTransform: "initial !important",
            fontFamily: "cardiuma-M !important",
            marginTop:'5%',
            width:'50%'
        },
        texthear:{
            textAlign: 'center', color: '#F3D45E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '24px' ,
            "@media only screen and (max-device-width: 480px)": {
               fontSize: '20px' 
            }
           
       },
       texthear1:{
           textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'4%', 
           "@media only screen and (max-device-width: 480px)": {
               fontSize: '16px' 
            }
          
       },
       texthear2:{
           textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'3%', 
           "@media only screen and (max-device-width: 480px)": {
               fontSize: '16px' 
            }
          
       },
       texthear3:{
           textAlign: 'center', color: '#9A9A9A !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px',
           "@media only screen and (max-device-width: 480px)": {
               fontSize: '12px' 
            }
       },
       cong_card: {
        
        width: '30%', display:'grid', marginTop: '20px',
        "@media only screen and (max-device-width: 480px)": {
           
            width: '90%',
        }
    },
    cong_card1: {
        width:'60%',
        
        "@media only screen and (max-device-width: 480px)": {
            width:'90%',
        }
    },
    })
    
 class account_agg extends Component {
    
  render() {
    const { classes } = this.props;
    return (
        <div>
        <Grid className={classes.card}>
          <Grid className={classes.cong_card1}>
  
              <Typography className={classes.card_tit}>Task Completed</Typography>
              <div style={{display:'flex',justifyContent:'center'}}>
              <img src={cong_bull} alt="congbull"></img>
              </div>
  {/*            <Typography className={classes.email_sub_tit}>We acknowledge the receipt of your request for closing your account. We would process the request and confirm about the account closure through an email 
  Thank you very much for your patronage.</Typography>*/}
     </Grid>
     </Grid>
     
          </div>
    )
  }
}
export default (withStyles(styles)((account_agg)));