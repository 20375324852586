import React, { Component } from 'react'
import {
    Typography, Grid, Button,
    TextField, Snackbar, SnackbarContent, IconButton, Modal, Box
} from '@mui/material'
import { FileUploader } from "react-drag-drop-files";
import { withStyles, } from "@mui/styles";
import openfileimg from '../../ui-assets/openfolder.svg'
import aadhar_ok from '../../ui-assets/aadhar_ok.svg'
import sample_photo from '../../ui-assets/sample_photo.svg'

import upload from '../../ui-assets/upload.svg'


import { httpRequest } from '../../ui-utils/api'
import { Close } from '@mui/icons-material';
const styles = theme => ({


    verifybnt: {
        background: "#F74542 !important",
        padding: "8px 24px 8px 24px !important",
        borderRadius: "24px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important"
    },

})
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};
class kyc extends Component {
    constructor(props) {
        super(props)


        this.state = {
            aadharno: '220105783620',
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            astatus: 0,
            aadhardata: '',
            filename: '',
            refid: '',
            fullname: '',
            gender: '',
            dob: '',
            address: '',
            pageno: 2,
            mobileno: '',
            client_id: '',
            showotp: 0,
            otp: '',
            profile_img: '',
            panno: 'AHWPV4397G',
            htype: ''

        }



    }
    componentDidMount = async () => {

        window.Changemytab(3)



    }
    handleChange = (file) => {

        console.log("File Uploaded", file)
        this.setState({ aadhardata: file })
        this.setState({ filename: file.name });


    };
    closeContactModal() {
        this.setState({ showerr: false })

    }
    Getotp = async () => {
        const { aadharno } = this.state

        let payload = {
            "aadharno": aadharno,

        }
        const data = new FormData();
        data.append('file', this.state.aadhardata);
        try {
            const otp = await httpRequest({
                endPoint: `/api/verfy/Getaadharotp`,
                method: "post",
                instance: "instanceOne",
                requestBody: payload,
            });

            if (otp.status === 200) {

                if (otp.otp_sent === true) {
                    this.setState({

                        client_id: otp.client_id, showotp: 1

                    })
                }

            } else {
                this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
            }
        } catch (error) {

            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }




    }

    Verfyaadhar = async () => {
        const { client_id, otp } = this.state

        let payload = {
            "client_id": client_id,
            "otp": otp

        }
        const data = new FormData();
        data.append('file', this.state.aadhardata);
        try {
            const otp = await httpRequest({
                endPoint: `/api/verfy/aadharVerfy`,
                method: "post",
                instance: "instanceOne",
                requestBody: payload,
            });

            console.log(otp.profile_image)
            if (otp.status === 200) {

                this.setState({ fullname: otp.full_name, dob: otp.dob, gender: otp.gender, address: otp.address, profile_img: 'data:image/jpeg;base64,' + otp.profile_image })

            } else {
                this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
            }
        } catch (error) {

            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }




    }

    GetaadharNumber(e) {
        const re = /[0-9]+/g;

        if (e.target.value === '' || re.test(e.target.value)) {

            if (e.target.value.length <= 16) {
                this.setState({ aadharno: e.target.value });
            }

        }
    }
    AadharNext() {
        this.setState({ pageno: 1 })
    }
    VerifyPAN = async () => {
        const { panno } = this.state

        let payload = {
            "panno": panno,


        }
        const data = new FormData();
        data.append('file', this.state.aadhardata);
        try {
            const otp = await httpRequest({
                endPoint: `/api/verfy/PANVerfy`,
                method: "post",
                instance: "instanceOne",
                requestBody: payload,
            });


            if (otp.status === 200) {

                this.setState({ showotp: 2, fullname: otp.full_name, htype: otp.type_of_holder_code })

            } else {
                this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
            }
        } catch (error) {

            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }




    }

    PAN_Next() {
        this.setState({ pageno: 2 })
    }
    render() {
        const fileTypes = ["JPG", "PNG", "GIF", "jpeg"];
        const { classes } = this.props;
        return (


            <div>
                <Modal

                    sx={{ border: 'none' }}




                    open={true}
                >
                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '45%',
                            height: '80%',
                            background: '#fffbee',
                            outline: 'none',
                            p: 4,
                            borderRadius: '12px',
                            padding: '2%'
                        }}
                    >

                        {this.state.pageno === 2 &&
                            <div>

                                <Grid style={{ width: '100%' }}>
                                    <Typography style={{ color: '' }}>Enter Account  number</Typography>
                                    <TextField autoFocus fullWidth size='small'
                                        value={this.state.panno}
                                        InputProps={{

                                            maxLength: '16',
                                            mask: '####-####',
                                            style: { color: "#000000", fontFamily: 'cardiuma-regular !important', fontSize: '18px' }
                                        }}
                                        style={{ marginTop: '10px' }}

                                        onChange={(e) => this.setState({ panno: e.target.value })}
                                    >



                                    </TextField>
                                </Grid>
                                <Grid style={{ width: '100%' }}>
                                    <Typography style={{ color: '' }}>Enter Account IFSC</Typography>
                                    <TextField autoFocus fullWidth size='small'
                                        value={this.state.panno}
                                        InputProps={{

                                            maxLength: '16',
                                            mask: '####-####',
                                            style: { color: "#000000", fontFamily: 'cardiuma-regular !important', fontSize: '18px' }
                                        }}
                                        style={{ marginTop: '10px' }}

                                        onChange={(e) => this.setState({ panno: e.target.value })}
                                    >



                                    </TextField>
                                </Grid>
                            </div>
                        }
                        {this.state.pageno === 1 &&
                            <div>
                                <Grid style={{ width: '100%' }}>
                                    <Typography style={{ color: '' }}>Enter your PAN number</Typography>
                                    <TextField autoFocus fullWidth size='small'
                                        value={this.state.panno}
                                        InputProps={{

                                            maxLength: '16',
                                            mask: '####-####',
                                            style: { color: "#000000", fontFamily: 'cardiuma-regular !important', fontSize: '18px' }
                                        }}
                                        style={{ marginTop: '10px' }}

                                        onChange={(e) => this.setState({ panno: e.target.value })}
                                    >



                                    </TextField>
                                    <Grid style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button className={classes.verifybnt}
                                            onClick={() => this.VerifyPAN()}
                                        >
                                            Verify
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid style={{ background: '#F5F5F5' }}>
                                    {
                                        this.state.showotp === 2 &&

                                        <div>
                                            <Grid style={{ marginTop: '3%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <img src={aadhar_ok} alt='aadhar'></img>
                                                    <Typography style={{ marginLeft: '10px', color: '#007223', fontFamily: 'cardiuma-Bold !important', fontSize: '14px' }}>PAN data verified</Typography>


                                                </div>


                                            </Grid>
                                            <Grid style={{ width: '100%' }}>
                                                <Grid style={{ display: 'flex' }}>
                                                    <Grid style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Typography style={{ marginLeft: '10px', color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Name : </Typography>
                                                    </Grid>
                                                    <Grid style={{ width: '80%', marginLeft: '15px', }}>
                                                        <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.fullname}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid style={{ display: 'flex' }}>
                                                    <Grid style={{ width: '20%', display: 'flex', justifyContent: 'flex-end' }}>
                                                        <Typography style={{ marginLeft: '10px', color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Holder Type : </Typography>
                                                    </Grid>
                                                    <Grid style={{ width: '80%', marginLeft: '15px', }}>
                                                        <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.htype === 'P' ? 'Person' : 'Individual'}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid style={{ marginTop: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button className={classes.verifybnt}
                                                    onClick={() => this.PAN_Next()}
                                                >
                                                    Next
                                                </Button>
                                            </Grid>
                                        </div>

                                    }
                                </Grid>

                            </div>}
                        {
                            this.state.pageno === 0 &&
                            <div>
                                <Grid style={{ width: '100%' }}>
                                    <Typography style={{ color: '' }}>Enter your AADHAR number</Typography>
                                    <TextField autoFocus fullWidth size='small'
                                        value={this.state.aadharno}
                                        InputProps={{

                                            maxLength: '16',
                                            mask: '####-####',
                                            style: { color: "#000000", fontFamily: 'cardiuma-regular !important', fontSize: '18px' }
                                        }}
                                        style={{ marginTop: '10px' }}

                                        onChange={(e) => this.GetaadharNumber(e)}
                                    >



                                    </TextField>
                                    <Grid style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button className={classes.verifybnt}
                                            onClick={() => this.Getotp()}
                                        >
                                            Verify
                                        </Button>
                                    </Grid>
                                </Grid>
                                {this.state.showotp === 1 &&
                                    <div>
                                        <Grid style={{ width: '100%' }}>
                                            <Typography style={{ color: '' }}>Enter your AADHAR number</Typography>
                                            <TextField autoFocus fullWidth size='small'
                                                value={this.state.otp}
                                                InputProps={{

                                                    maxLength: '16',
                                                    mask: '####-####',
                                                    style: { color: "#000000", fontFamily: 'cardiuma-regular !important', fontSize: '18px' }
                                                }}
                                                style={{ marginTop: '10px' }}

                                                onChange={(e) => this.setState({ otp: e.target.value })}
                                            >


                                            </TextField>
                                        </Grid>
                                        <Grid style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button className={classes.verifybnt}
                                                onClick={() => this.Verfyaadhar()}
                                            >
                                                Verify
                                            </Button>
                                        </Grid>

                                    </div>
                                }

                                <Grid style={{ width: '100%', marginTop: '10px' }}>
                                    <FileUploader
                                        style={{}}
                                        multiple={false}
                                        maxSize={'1mb'}
                                        hoverTitle="rAI"
                                        label="Upload or drop a file right here"

                                        //   onChange={(e) => this.handleChange(e)}
                                        handleChange={this.handleChange}
                                        name="file"
                                        types={fileTypes}
                                        children={
                                            <Grid style={{ padding: '10px', display: 'flex', width: '100%', background: '#ffffff', borderRadius: '13px', border: '2px dotted #008BCA' }}>
                                                <Grid style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={openfileimg}>

                                                    </img>
                                                </Grid>
                                                <Grid style={{ width: '80%' }}>
                                                    <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and drop the invoice here!</Typography>
                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Grid style={{ width: '10%' }}>
                                                            <img src={upload}
                                                                height={'40px'}
                                                                width={'40px'}
                                                            >

                                                            </img>
                                                        </Grid>
                                                        <Grid style={{ width: '30%' }}>
                                                            <Typography style={{ marginLeft: '10px', color: '#093265', fontFamily: 'cardiuma-Bold !important', fontSize: '14px' }}>Upload Aadhar</Typography>
                                                        </Grid>
                                                        <Grid style={{ width: '60%', display: 'flex', justifyContent: 'flex-end' }}>
                                                            <Typography style={{ marginLeft: '10px', color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Only png,Jpege,Gif Allowed</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                        <Typography style={{ marginLeft: '10px', color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.filename}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>

                                <Grid style={{ background: '#F5F5F5' }}>
                                    <Grid style={{ marginTop: '3%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        {
                                            this.state.astatus === 0 &&
                                            <div style={{ display: 'flex' }}>
                                                <img src={aadhar_ok} alt='aadhar'></img>
                                                <Typography style={{ marginLeft: '10px', color: '#007223', fontFamily: 'cardiuma-Bold !important', fontSize: '14px' }}>Aadhaar data verified</Typography>
                                            </div>
                                        }

                                    </Grid>

                                    <Grid style={{ marginTop: '5%', width: '100%', display: 'flex' }}>
                                        <Grid style={{ width: '30%' }}>
                                            <img src={this.state.profile_img} alt='photo'></img>
                                        </Grid>
                                        <Grid style={{ width: '70%' }}>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography style={{ marginLeft: '10px', color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Name : </Typography>
                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.fullname}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography style={{ marginLeft: '10px', color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Gender : </Typography>
                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.gender}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography style={{ marginLeft: '10px', color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>DOB : </Typography>
                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.dob}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography style={{ marginLeft: '10px', color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Address : </Typography>
                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.address.house} {' '}{this.state.address.landmark}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.address.street} {' '}{this.state.address.po}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.address.vtc} {' '}{this.state.address.dist}{'(D)'}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ display: 'flex' }}>
                                                <Grid style={{ width: '25%', display: 'flex', justifyContent: 'flex-end' }}>

                                                </Grid>
                                                <Grid style={{ width: '75%', marginLeft: '15px', }}>
                                                    <Typography style={{ color: '#0B1420', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.address.state} {'-'}{this.state.address.country}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>




                                    </Grid>
                                </Grid>
                                <Grid style={{ marginTop: '8%', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button className={classes.verifybnt}
                                        onClick={() => this.AadharNext()}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </div>
                        }
                    </Box >
                </Modal >
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
            </div >

        )
    }
}
export default (withStyles(styles)((kyc)));