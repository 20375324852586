import React, { Component } from 'react'
import { withStyles, styled } from "@mui/styles";
import {
    Grid, Tab, Tabs

} from '@mui/material'
import Holiday_calendar from '../circular/holiday_calendar'
import Circular from '../circular/circular'

import Issue_disclosures from '../circular/issue_disclosures'
import Footer from '../landingpage/fotter'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Holiday calendar | Circular | Issue disclosures | Bulletin | BullForce',
    description: 'Bullforce Holiday Calendar: Plan ahead with our NSE, BSE trading holiday schedule. Stay informed on market closures for effective stock and bond trading. Bullforce Circular: Stay informed with the latest NSE, BSE circulars. Get essential updates on trading, compliance, and demat account regulations. Bullforce Issue Disclosures: Access detailed disclosures on stock, bond, and IPO issues. Stay compliant with NSE, BSE regulations for informed trading decisions. Bullforce Bulletin: Stay updated with the latest trading news, market insights, and stock analysis. Access expert reports on demat accounts, stocks, and more.',
    canonical: 'https://bullforce.co/holiday_calendar, https://bullforce.co/circular,  https://bullforce.co/issue_disclosures, https://bullforce.co/bulletin',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, holiday, circular, issue, bulletin, disclosures, calendar bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}


const StyledTabs = styled(Tab)({

    '&.Mui-selected': {
        fontFamily: 'cardiuma-Bold !important',
    },
});


const styles = theme => ({

    buttonlabel: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {

            cursor: 'pointer'

        },

        "@media only screen and (max-device-width: 480px)": {
            textTransform: 'none !important', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
        }
    },
    buttonlabel_bold: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important'

    },
    buttonlabel_bold1: {
        color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important',
        width:'25%',
        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer',
            width:'33%',

        },

    },
    main_tab:{
        marginTop: '100px', background: '#F2F2F2 !important',
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important',
            marginTop: '50px',
        }

    },
    tab_card:{
        width: '90%', background: '#F2F2F2' ,
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important',width: '100%',padding:'16px'
        }
    },
    tab_width:{
        width: '25%',
        "@media only screen and (max-device-width: 480px)": {
            width: '33%',
        }

    },
    tab_back:{
        background: '#F2F2F2 !important',
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important'
        }

    },
    tab_sub:{
        background: '#F2F2F2', height: 'auto',
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important'
        }

    },
    fotter_card:{
        width: '100%', marginTop: '25px',
        "@media only screen and (max-device-width: 480px)": {
            marginTop: '5px'
        }

    }
})
class circular extends Component {
    constructor(props) {
        super(props)


        this.state = {


            tabvalue: 0
        }



    }
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2"
        if (window.innerWidth < 960) {
            document.body.style.backgroundColor = "#1B2636"
        }
        if (window.location.pathname === "/holiday_calendar") {
            this.setState({ tabvalue: 0 })
        }
        if (window.location.pathname === "/circular") {
            this.setState({ tabvalue: 1 })
        }

        if (window.location.pathname === "/issue_disclosures") {
            this.setState({ tabvalue: 2 })
        }



    }
    handleTabChange = (event, value) => {


        this.setState({ tabvalue: value });
    };

    render() {
        const { classes } = this.props;
        const { tabvalue } = this.state
        return (
            <div className={classes.main_tab}>
                <DocumentMeta {...meta} />

                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', background: '#F2F2F2' }}>
                    <Grid className={classes.tab_card}>
                        <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} className={classes.tab_back}
                            indicatorColor='red'
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#F3D45E"


                                }
                            }}

                        >

                            <StyledTabs className={classes.buttonlabel} label="Holiday Calendar" />
                            <StyledTabs  className={classes.buttonlabel} label="Circular" />

                            <StyledTabs  className={classes.buttonlabel} label="Issue Disclosures" />


                        </Tabs>
                        {tabvalue === 0 &&
                            <div className={classes.tab_sub}>
                                <Holiday_calendar />
                            </div>
                        }
                        {tabvalue === 1 &&
                           <div className={classes.tab_sub}>
                                <Circular />
                            </div>
                        }

                        {tabvalue === 2 &&
                          <div className={classes.tab_sub}>
                                <Issue_disclosures />
                            </div>
                        }
                    </Grid>
                </Grid>
                <Grid style={{ width: '100%', marginTop: '25px' }}>
                    <Footer />
                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((circular)));