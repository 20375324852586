import React, { Component ,createRef} from 'react'
import { withStyles, } from "@mui/styles";
import bull from '../../../ui-assets/bull.svg'
import Webcam from "react-webcam";
import { QRCode } from 'react-qrcode-logo';
import { Close } from '@mui/icons-material';
import * as CryptoJS from 'crypto-js';
import Loader from "react-js-loader";
import { httpRequest } from '../../../ui-utils/api'
import backendHosts from '../../../ui-utils/apiConfig.js'
import { publicIpv4} from 'public-ip';
import {
    Typography, Grid, Button,Hidden,Snackbar, SnackbarContent, IconButton,Modal,Box
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
    cam_card: {
        width: '100%', 
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '52px 16px',
            marginLeft: '0%',

        }
    },
    card_tit1: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important' , fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important', textAlign: 'left'
        },

    },
    cam_tit: {
        width: '100%',

        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    cam_card_back: {
        borderRadius: '8px', paddingBottom: '25px',marginLeft:'15%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',

            marginLeft: '0%',
            background: "#1B2636",
            paddingBottom: '0px'

        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    cam_tit1: {
        display: 'none',


        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '2px 1px',
            marginLeft: '0%',
            display: 'flex',

        }
    },

})
function detectWebcam(callback) {
    let md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) return callback(false);
    md.enumerateDevices().then(devices => {
      callback(devices.some(device => 'videoinput' === device.kind));
    })
  }
  
  function detectWebcam1(callback) {
  navigator.permissions.query({ name: "camera" }).then(res => {
    if(res.state === "granted"){
        // has permission
    }
    callback(res.state);

})
  }

class liveness extends Component {
    ws = new WebSocket(backendHosts.WSURL);
    constructor(props) {
       
        super(props)
        this.webcamRef = createRef();
       
        this.state = {
            qrlink:'',
            retake_but: true,
            inner_pageno: 7,
            qrpage:0,
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            pub_ip:'0',
            selfcont_but:true, //added on 22/07/24 To int Continue button as Disable 
            citizen:''//added on 24/08/24 To validate NRI. 
        }



    }
    componentDidMount = async () => {
      //Added.  to get NRI status from local first. 
        let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
        
        this.setState({citizen:rd1.citizen})
       let pub_ip=await publicIpv4()
       this.setState({pub_ip:pub_ip})
       
       if (navigator.mediaDevices.getUserMedia !== null) {
        var options = {
          video: true,
          audio: true
        };
        navigator.getUserMedia(
          options,
          function (stream) {
            
            
           
            console.log(stream, "streaming");
          },
          function (e) {
          
          }
        );
      }
    

    
    }
    Continue5 = async (type) => {
        let isok=0
        let  imageSrc=null
        try {
            if(type===1)     //Desktop Selfie image 
                {
                imageSrc = this.webcamRef.current.getScreenshot({ width: 1280, height: 720, dpi: 96 });
                }
                if(type===2)     //Mobile. Selfie image 
                {
                imageSrc = this.webcamRef.current.getScreenshot({ width: 972, height: 1296, dpi: 96 });    
                }
        } catch (error) {
            this.setState({ showerr: true, errorMsg: "Not able to capture image from camera", mysnack: snackeror.myerror })
        }
        if(imageSrc===null)
            {
                isok=1
                this.setState({ showerr: true, errorMsg: "Not able to capture image from camera", mysnack: snackeror.myerror })
            }
           else
            {
                let rd1 = localStorage.getItem('reg_data') || '{}'
                let rd2 = JSON.parse(rd1)
                let emailid=rd2.emailid
        let mobileno=rd2.mobileno
        let profile_img=rd2.profile_img.toString()
        let aadharno=rd2.aadharno
        let dob=rd2.dob
                let similarity = 0
               
        
                    
                let payload = {
        
                    "img1": profile_img,
                    "img2": imageSrc,
                    "emailid": emailid,
                    "mobileno": mobileno,
                    "aadhar":aadharno.substring(8,12),
                    "pub_ip":this.state.pub_ip
                }
                let payload1 = {
        
                    "img2": imageSrc,
                    "emailid": emailid,
                    "mobileno": mobileno,
                    "aadhar":aadharno.substring(8,12),
                    "pub_ip":this.state.pub_ip
                }



                if(profile_img.toString().length>0)
                {


                    try {

                        this.setState({ islodding: true })
                        const otp = await httpRequest({
                            endPoint: `/api/verfy/Face`,
                            method: "post",
                            instance: "instanceTwo",
                            requestBody: payload,
                        });
                        this.setState({ islodding: false })
                        if (otp.status === 200) {
                            this.setState({ inner_pageno: 8, similarity: otp.similarity, })
            
                            similarity = otp.similarity
                            if (similarity < 74) {
                                this.setState({ selfcont_but: true, retake_but: false })
                            }
                            if (similarity >= 74) {
                                this.setState({  retake_but: true })
                                try {
                                    const check_liveness = await httpRequest({
                                        endPoint: `/api/verfy/check_liveness`,
                                        method: "post",
                                        instance: "instanceTwo",
                                        requestBody: payload1,
                                    });
            
                                    if (check_liveness.status === 200) {
                                        if (check_liveness.score < 59) {
                                            this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                                        }
                                        if (check_liveness.score >= 59) {
                                            this.setState({  retake_but: true, liveness: check_liveness.score ,selfcont_but: false}) //To enable continue button after percentage Display. 
                                        }
            
            
                                    }
            
                                    if (check_liveness.status === 201) {
                                        this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                                        this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                                    }
                                } catch (error) {
            
                                }
            
                            }
            
                        }
                        if (otp.status === 201) {
                            this.setState({ selfcont_but: true, retake_but: false, inner_pageno: 8, similarity: otp.similarity, })
                            this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
                        }

                    } catch (error) {
                        this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                    }

                }else{

                    this.setState({  retake_but: true })
                    try {
                        const check_liveness = await httpRequest({
                            endPoint: `/api/nri/check_liveness`,
                            method: "post",
                            instance: "instanceTwo",
                            requestBody: payload1,
                        });
    
                        if (check_liveness.status === 200) {
                            if (check_liveness.score < 59) {
                                this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                            }
                            if (check_liveness.score >= 59) {
                               
                                this.setState({inner_pageno: 8,  retake_but: true, liveness: check_liveness.score ,selfcont_but: false}) //To enable continue button after percentage Display. 
                            }
    
    
                        }
    
                        if (check_liveness.status === 201) {
                            this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                            this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                        }
                    } catch (error) {
                        this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                    }
                }



            }

    }
   /* Continue5 = async () => {

        let isok=0
     
        const imageSrc = this.webcamRef.current.getScreenshot({ width: 1280, height: 720, dpi: 96 });

        console.log(imageSrc)

        if(imageSrc===null)
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Not able to capture image from camera", mysnack: snackeror.myerror })
        }

        if(isok===0)
        {
       
       

if(this.state.citizen===0 || profile_img.lenght>0) //And it to hit face verification for Regular flow 
     {   
        try {

            this.setState({ islodding: true })
            const otp = await httpRequest({
                endPoint: `/api/verfy/Face`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            this.setState({ islodding: false })
            if (otp === "API_ERROR") {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
            if (otp.status === 200) {
                this.setState({ inner_pageno: 8, similarity: otp.similarity, })

                similarity = otp.similarity
                if (similarity < 74) {
                    this.setState({ selfcont_but: true, retake_but: false })
                }
                if (similarity > 74) {
                    this.setState({  retake_but: true })
                    try {
                        const check_liveness = await httpRequest({
                            endPoint: `/api/verfy/check_liveness`,
                            method: "post",
                            instance: "instanceTwo",
                            requestBody: payload1,
                        });

                        if (check_liveness.status === 200) {
                            if (check_liveness.score < 59) {
                                this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                            }
                            if (check_liveness.score > 59) {
                                this.setState({  retake_but: true, liveness: check_liveness.score ,selfcont_but: false}) //To enable continue button after percentage Display. 
                            }


                        }

                        if (check_liveness.status === 201) {
                            this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                            this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                        }
                    } catch (error) {

                    }

                }

            }
            if (otp.status === 201) {
                this.setState({ selfcont_but: true, retake_but: false, inner_pageno: 8, similarity: otp.similarity, })
                this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
            }

        } catch (error) {

        }}//I need to cheque only liveliness for NRI. 
        if(this.state.citizen===1)
            {   
                
                this.setState({  retake_but: true })
                try {
                    const check_liveness = await httpRequest({
                        endPoint: `/api/nri/check_liveness`,
                        method: "post",
                        instance: "instanceTwo",
                        requestBody: payload1,
                    });

                    if (check_liveness.status === 200) {
                        if (check_liveness.score < 59) {
                            this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                        }
                        if (check_liveness.score > 59) {
                           
                            this.setState({inner_pageno: 8,  retake_but: true, liveness: check_liveness.score ,selfcont_but: false}) //To enable continue button after percentage Display. 
                        }


                    }

                    if (check_liveness.status === 201) {
                        this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                        this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                    }
                } catch (error) {

                }
            }
    }


    }
    Continue5_MObile = async () => {
   
        let imageSrc=null
        try {
             imageSrc = this.webcamRef.current.getScreenshot({ width: 972, height: 1296, dpi: 96 });    
        } catch (error) {
            
            this.setState({ showerr: true, errorMsg: "Not able to Take image", mysnack: snackeror.myerror })
        }
        

        let isok=0
        if(imageSrc===null)
            {
                isok=1
                this.setState({ showerr: true, errorMsg: "Not able to capture image from camera", mysnack: snackeror.myerror })
            }


          if(isok===0)  
          {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
let profile_img=rd2.profile_img
let aadharno=rd2.aadharno
        let similarity = 0

        //  this.setState({ inner_pageno: 8, profile_img: imageSrc })
        let payload = {

            "img1": profile_img,
            "img2": imageSrc,
            "emailid": emailid,
            "mobileno": mobileno,
            "aadhar":aadharno.substring(8,12),
            "pub_ip":this.state.pub_ip
        }
        let payload1 = {

            "img2": imageSrc,
            "emailid": emailid,
            "mobileno": mobileno,
            "aadhar":aadharno.substring(8,12),
            "pub_ip":this.state.pub_ip

        }

if(this.state.citizen===0 || profile_img.lenght>0)
{



        try {

            this.setState({ islodding: true })
            const otp = await httpRequest({
                endPoint: `/api/verfy/Face`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            this.setState({ islodding: false })
          
            if (otp === "API_ERROR") {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
            if (otp.status === 200) {
                this.setState({ inner_pageno: 8, similarity: otp.similarity, })

                similarity = otp.similarity
                if (similarity < 74) {
                    this.setState({ selfcont_but: true, retake_but: false })
                }
                if (similarity > 74) {
                    this.setState({  retake_but: true })// added 16/07/24 remove selfcont_but to fix continue button
                    try {
                        const check_liveness = await httpRequest({
                            endPoint: `/api/verfy/check_liveness`,
                            method: "post",
                            instance: "instanceTwo",
                            requestBody: payload1,
                        });

                        if (check_liveness.status === 200) {
                            if (check_liveness.score < 59) {
                                this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                            }
                            if (check_liveness.score > 59) {
                                this.setState({ retake_but: true, liveness: check_liveness.score ,selfcont_but: false}) //To enable continue button after percentage Display. 
                            }


                        }

                        if (check_liveness.status === 201) {
                            this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                            this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                        }
                    } catch (error) {
                        this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                    }

                }

            }
            if (otp.status === 201) {
                this.setState({ selfcont_but: true, retake_but: false, inner_pageno: 8, similarity: otp.similarity, })
                this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
            }

        } catch (error) {
            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
        }
    }  else
        {   
            
            this.setState({  retake_but: true })
            try {
                const check_liveness = await httpRequest({
                    endPoint: `/api/nri/check_liveness`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload1,
                });

                if (check_liveness.status === 200) {
                    if (check_liveness.score < 59) {
                        this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                    }
                    if (check_liveness.score > 59) {
                       
                        this.setState({inner_pageno: 8,  retake_but: true, liveness: check_liveness.score ,selfcont_but: false}) //To enable continue button after percentage Display. 
                    }


                }

                if (check_liveness.status === 201) {
                    this.setState({ selfcont_but: true, retake_but: false, liveness: check_liveness.score })
                    this.setState({ showerr: true, errorMsg: check_liveness.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
        }
}

    }*/
    // wsconnect(){
    //     this.ws.onopen = () => {
    //         console.log("opened");
    //         this.ws.send("test",18000); // message to send on Websocket ready
    //     };

    // }
    //added on 02/08/24 Websocket.  implementation With the retry option . 
    startWebsocket() {
        var ws = new WebSocket(backendHosts.WSURL,null,50000,10)
        let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    let emailid = rd2.emailid
    let mobileno = rd2.mobileno
let citizen=rd2.citizen
        ws.onmessage = function(e){
          console.log('websocket message event:', e)
          let wsdata = JSON.parse(e.data)

                    console.log(wsdata.mobileno)
                    if (wsdata.mobileno === mobileno) {
                        if(citizen===0)
                        {
                        window.ChangePageNo(17)
                        }else{
                            window.ChangePageNo(11.1)
                        }

                        ws = null
                    
                        console.log(wsdata.wsstatus)
                    }
        }
        ws.onopen = () => {
            console.log('WebSocket connection established');
            ws.send("Liveness QR "+emailid);
            // sendMessage();
          };
        ws.onclose = ()=>{
          // connection closed, discard old websocket and create a new one in 5s
          ws = null
          console.log('WebSocket connection Closed');
          this.startWebsocket()
        
        }
      }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    Continue6 = async () => {
//And it to redirect NRI and regular flow. 
   
if(this.state.citizen===0)
    {
    window.ChangePageNo(17)
    }else{
        window.ChangePageNo(11.1)
    }
       
  
      }
      CreateQR= async () =>  {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
this.startWebsocket()
let qrid=""
let payload = {

    "emailid": emailid,
    "mobileno": mobileno,
}
try {
    const qrid_data = await httpRequest({
        endPoint: `/api/qrid`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });
    if(qrid_data.status===201)
    {
        this.setState({ showerr: true, errorMsg: qrid_data.message, mysnack: snackeror.myerror })
    }
    if(qrid_data.status===200)
    {
        qrid=qrid_data.qrid
       // this.wsconnect()
        //   this.ws.onmessage = (event) => {
        //       console.log("got message", event.data);
  
        //       let wsdata = JSON.parse(event.data)
  
        //       console.log(wsdata.mobileno)
        //       if (wsdata.mobileno === mobileno) {
        //         window.ChangePageNo (17)
        //           console.log(wsdata.wsstatus)
        //       }
  
        //   };
  
        //   this.ws.onclose = function (e) {
        //       console.log('socket closed try again');
        //       setTimeout(function() {
        //         this.wsconnect();
        //       }, 1000);
        //   }



        let dedata = CryptoJS.AES.encrypt(qrid, "96358214256984120").toString();

        let qrlink = backendHosts.QR + "selfie?refno=" + encodeURIComponent(dedata) 
      

        console.log(qrlink)
        this.setState({ qrpage: 1, qrlink: qrlink })
        
    }
} catch (error) {
    this.setState({ showerr: true, errorMsg:"Opps Something went wrong try after some time", mysnack: snackeror.myerror })    
}

console.log("connect to wss");
 
    }
  render() {
    const { classes } = this.props;
    const {inner_pageno}=this.state;
    return (
      <div>              <Grid className={classes.cam_card} >
      {this.state.qrpage === 1 &&
          <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
            <Grid>
              <Typography className={classes.card_tit1}>Scan QR for Selfie</Typography>

            





                  <Grid style={{ display: 'flex', justifyContent: 'center',marginTop:'20%' }}>

                      <div>
                          <QRCode value={this.state.qrlink} logoImage={bull} />,
                      </div>

                  </Grid>




                  <div>

                      <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center',  marginTop: '20%' }}>
                          
                              <Button  fullWidth className={classes.joinDiplomtsBtn}

                                  onClick={() => 
                                     this.setState({ inner_pageno: 7 ,qrpage:0})                                      
                                    
                                    }
                              >Cancel

                              </Button>
                          

                      </Grid>
                  </div>

              </Grid>
          </div>
      }
      {this.state.qrpage === 0 &&
          <div>
              <Typography className={classes.card_tit1}>Capture Selfie</Typography>
           
              <Grid className={classes.cam_card_back} >
                  <Grid className={classes.cam_tit1} >
                  
                      <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Photo should present full head from top of hair to bottom of chin with white background. Front view and eyes open.</Typography>


                  </Grid>

{/*Mobie*/}
                  <Hidden only={["lg", "md", "xl"]}>
                      <Grid style={{ display: 'flex', justifyContent: 'center' }} className={classes.webcamdesk}>

                          <div>
                              <Webcam

                                  screenshotFormat="image/jpeg"
                                  screenshotQuality={1}
                                  imageSmoothing={true}
                                  mirrored={true}

                                  height='400px' width='300px' ref={this.webcamRef} />
                          </div>
                        


                      </Grid>


                    

                      {inner_pageno === 7 &&
                          <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                              
                              <Grid style={{ width: '80%', display: 'flex', justifyContent: 'center', marginLeft: '5%' }}
                              
                              onClick={() => this.Continue5(2)}
                              >
                                  <Button fullWidth className={classes.joinDiplomtsBtn}

                                     
                                  >Continue

                                  </Button>
                              </Grid>
                          </Grid>
                      }
                      {inner_pageno === 8 &&
                          <div>
                              <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginLeft: '5px', marginTop: '5px' }}>
                                  <Grid style={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                                      <Typography style={{ color: '#7DAB1C', fontFamily: 'cardiuma-M', fontSize: '18px' }}>{this.state.similarity}{'%'}</Typography>
                                      <Typography style={{ marginLeft: '5px', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>matching with aadhar</Typography>
                                  </Grid>
                                  <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                      <Typography style={{ color: '#7DAB1C', fontFamily: 'cardiuma-M', fontSize: '18px' }}>{this.state.liveness}{'%'}</Typography>
                                      <Typography style={{ marginLeft: '5px', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Liveness</Typography>
                                  </Grid>
                              </Grid>
                              <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginLeft: '5px', marginTop: '15px' }}>
                                  <Grid style={{ width: '40%' }}>
                                      <Button disabled={this.state.retake_but} fullWidth className={this.state.retake_but ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                                          onClick={() => this.setState({ inner_pageno: 7,liveness:0,similarity:0 })}
                                      >Retake

                                      </Button>
                                  </Grid>
                                  <Grid style={{ width: '40%' }}>
                                      <Button disabled={this.state.selfcont_but} fullWidth className={this.state.selfcont_but ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                                          onClick={() => this.Continue6()}
                                      >Continue

                                      </Button>
                                  </Grid>
                              </Grid>
                          </div>
                      }
                  </Hidden>

                  <Hidden only={["xs", "sm"]}>
                      <Grid style={{ display: 'flex', justifyContent: 'center' }} className={classes.webcamdesk}>

                          <div>
                              <Webcam

                                  screenshotFormat="image/jpeg"
                                  screenshotQuality={1}
                                  imageSmoothing={true}
                                  mirrored={true}

                                  height='400px' width='400px' ref={this.webcamRef} />
                          </div>
                          <div style={{ marginTop: '80px', position: 'absolute', height: '200px', width: '200px', border: '2px solid #30B2EF' }}>

                          </div>


                      </Grid>

                      <Grid className={classes.cam_tit} >
                      <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}><li>If you don't have camera on desktop, please scan the QR code in mobile and capture photograph</li></Typography>
                                                        <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}><li>Photo should present full head from top of hair to bottom of chin with white background.</li></Typography>

                                                        <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px', marginTop: '5px' }}><li>Front view and eyes open.</li></Typography>
                                                    </Grid>

                  

                      {inner_pageno === 7 &&
                          <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                               <Grid style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
                              <Button fullWidth className={classes.joinDiplomtsBtn}

                                  onClick={() => this.CreateQR()}
                              >Scan for QR

                              </Button>
                      </Grid>
                              <Grid style={{ width: '45%', display: 'flex', justifyContent: 'center', marginLeft: '5%' }}>
                                  <Button fullWidth className={classes.joinDiplomtsBtn}

                                      onClick={() => this.Continue5(1)}
                                  >Continue

                                  </Button>
                              </Grid>
                          </Grid>
                      }
                      {inner_pageno === 8 &&
                          <div>
                              <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginLeft: '5px', marginTop: '5px' }}>
                                  <Grid style={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                                      <Typography style={{ color: '#7DAB1C', fontFamily: 'cardiuma-M', fontSize: '18px' }}>{this.state.similarity}{'%'}</Typography>
                                      <Typography style={{ marginLeft: '5px', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>matching with aadhar</Typography>
                                  </Grid>
                                  <Grid style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                                      <Typography style={{ color: '#7DAB1C', fontFamily: 'cardiuma-M', fontSize: '18px' }}>{this.state.liveness}{'%'}</Typography>
                                      <Typography style={{ marginLeft: '5px', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '12px' }}>Liveness</Typography>
                                  </Grid>
                              </Grid>
                              <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginLeft: '5px', marginTop: '15px' }}>
                                  <Grid style={{ width: '40%' }}>
                                      <Button disabled={this.state.retake_but} fullWidth className={this.state.retake_but ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                                          onClick={() => this.setState({ inner_pageno: 7,liveness:0,similarity:0 })}
                                      >Retake

                                      </Button>
                                  </Grid>
                                  <Grid style={{ width: '40%' }}>
                                      <Button disabled={this.state.selfcont_but} fullWidth className={this.state.selfcont_but ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                                          onClick={() => this.Continue6()}
                                      >Continue

                                      </Button>
                                  </Grid>
                              </Grid>
                          </div>
                      }
                  </Hidden>
                  
              </Grid>
          </div>
      }
  </Grid>
  <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: window.innerWidth <600 ? "top": "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
</div>
    )
  }
}
export default (withStyles(styles)((liveness)));