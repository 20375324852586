import React, { Component } from 'react'
import { withStyles, styled } from "@mui/styles";
import {
    Grid, Tab, Tabs
} from '@mui/material'
import Policies from '../policy/privacy_policy'
import Refund_policy from '../policy/refund_policy'
import Risk_disclosure from '../policy/risk_disclosure'
import Footer from '../landingpage/fotter'
import Obligations from '../policy/obligations'
import Cdsl from './cdsl'
// importing component - 24-07-24 -Dev Srini
import TranslateCode from './TranslateCode'

const StyledTabs = styled(Tab)({

    '&.Mui-selected': {
        fontFamily: 'cardiuma-Bold !important',
    },
});


const styles = theme => ({

    buttonlabel: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {

            cursor: 'pointer'

        },
        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important',fontSize: '12px !important',
        }
    },
    buttonlabel_bold: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important'

    },
    buttonlabel_bold1: {
        color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'

        },

    },
    p_card:{
        width: '90%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
        }
    },
    p_tab_back:{
        background: '#F7F9FA !important',
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important',
        }

    },
    p_tab_back1:{
        background: '#ffffff', height: 'auto', padding: '32px', borderRadius: '8px', marginTop: '15px' ,
        "@media only screen and (max-device-width: 480px)": {
            background: '#1B2636 !important',borderRadius: '0px', paddingTop: '15px'
        }

    },
    // removed paddingTop - 24-07-24 -Dev Srini
    main_tab:{
        "@media only screen and (max-device-width: 480px)": {
            paddingTop: '70px', height: 'auto',
        }

    }
})

class privacy_policy_main extends Component {
    constructor(props) {
        super(props)


        this.state = {

            // new state added - 24-07-24 -Dev Srini
            tabvalue: 0,
            selectedLanguage: false
        }



    }
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2"
        if (window.innerWidth < 960) {
            document.body.style.backgroundColor = "#1B2636"
        }


        if (window.location.pathname === "/Privacy_Policy") {
            this.setState({ tabvalue: 0 })
        }
        if (window.location.pathname === "/refund_policy") {
            this.setState({ tabvalue: 1 })
        }
        if (window.location.pathname === "/termsandconditions") {

            this.setState({ tabvalue: 2 })
        }
        if (window.location.pathname === "/cdsl") {

            this.setState({ tabvalue: 3 })
        }

            // Risk disclosure route added - 26-07-24 - Dev Srini
        if (window.location.pathname === "/risk_disclosure") {

            this.setState({ tabvalue: 2 }, () => {
                document.getElementById('risk-disclosure-content').scrollIntoView();
            });
          } else {
            document.getElementById('risk-disclosure-content').scrollIntoView();
          }

    }
    handleTabChange = (event, value) => {


        this.setState({ tabvalue: value });
    };
    // event handler update value - 24-07-24 -Dev Srini
    handleSelectedLanguage = (value) => {
        this.setState({ selectedLanguage: value })
    }

    
    render() {
        const { classes } = this.props;
        const { tabvalue, selectedLanguage } = this.state
        return (
            // rendering component
            <div className={classes.main_tab} style={{paddingTop: selectedLanguage ? '60px' : '100px', height: 'auto'}}>

                <Grid>
                    <TranslateCode handleSelectedLanguage={this.handleSelectedLanguage} />
                </Grid>

                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '30px' }}>
                    <Grid className={classes.p_card}>
                        <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} className={classes.p_tab_back}

                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#F3D45E"
                                }
                            }}

                        >
                            <StyledTabs style={{ width: '25%' }} className={classes.buttonlabel} label="Privacy Policy" />
                            <StyledTabs style={{ width: '20%' }} className={classes.buttonlabel} label="Refund Policy" />
                            <StyledTabs style={{ width: '30%' }} className={classes.buttonlabel} label="Rights & Obligations(Terms and Conditions)" />
                            <StyledTabs style={{ width: '25%' }} className={classes.buttonlabel} label="Rights & Obligations - CDSL" />
                        </Tabs>
                        {tabvalue === 0 &&
                            <div className={classes.p_tab_back1}>
                                <Policies />
                            </div>
                        }
                        {tabvalue === 1 &&
                             <div className={classes.p_tab_back1}>
                                <Refund_policy />

                            </div>
                        }
                        {tabvalue === 2 &&
                              <div className={classes.p_tab_back1}>
                                <Obligations />
                            </div>
                        }
                        {tabvalue === 3 &&
                              <div className={classes.p_tab_back1}>
                                <Cdsl/>
                            </div>
                        }

                    </Grid>
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Footer />
                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((privacy_policy_main)));