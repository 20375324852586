import React from 'react'
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import Landingpage from '../ui-pages/landingpage/index'

import Diplomat_main from '../ui-pages/diplomats/main'
import Contactus from '../ui-pages/contactus/contactus'
import Circular from '../ui-pages/landingpage/circular'
import Privacy_Policy from '../ui-pages/policy/index'
import Privacy from '../ui-pages/policy/privacy_policy'
import Signup from '../ui-pages/signup/newsignup.js'
import Obligations from '../ui-pages/policy/index'
import Login from '../ui-pages/landingpage/login';
import Kyc from '../ui-pages/signup/kyc';
import Aboutus from '../ui-pages/landingpage/aboutus'
import Pricing from '../ui-pages/pricing/pricing.js'
import Brokerage from '../ui-pages/brokerage/brokerage'
import Ourproducts from '../ui-pages/our_products/index'
import Margin_calculator from '../ui-pages/margin_calculator/margin_calculator';
import Selfie from '../ui-pages/signup/selfie'
import Payment from '../ui-pages/payment/index.js'
import Cdsl from '../ui-pages/policy/index';
import Signature from '../ui-pages/signup/pages/qrsignature.js'
import Fatca from '../ui-pages/policy/fatca.js'
import Faq from '../ui-pages/faq/FAQ.js'
import Account_closure from '../ui-pages/landingpage/accountclosure.js'
import Account_aggregator from '../ui-pages/landingpage/account_agg.js'
// import component -13/09/24 - Dev Srini
import Campaign from '../ui-pages/Campaign/Campaign .js'

// require functions imported - 05/09/24 - Dev Srini
import { useEffect } from 'react';
import { publicIpv4} from 'public-ip';
import { httpRequest } from '../ui-utils/api.js';


function MainLandingPage() {

// post api integrated for user count - 05/09/24 - Dev Srini
  useEffect(() => {
    const postVistorsData = async () => {
      try {
        let ipAddress = await publicIpv4()

        const result = await httpRequest({
          endPoint: `api/users/count`,
          method: "post",
          instance: "instanceOne",
          requestBody: {
            ipAddress, pageName: "bullforce.co"
          }
        })
        
        console.log(result, "PAGE VISITORS")

      }
      
      catch (err) {
        console.log("Error in visitors post API: ", err)
      }
    }

    if(window.location.pathname === '/'){
      postVistorsData()
    }
    
  }, [])

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Landingpage} />

          <Route path="/diplomats" component={Diplomat_main} />
          <Route path="/contactus" component={Contactus} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/holiday_calendar" component={Circular} />
          <Route path="/circular" component={Circular} />
          <Route path="/bulletin" component={Circular} />
          <Route path="/issue_disclosures" component={Circular} />

          <Route path="/login" component={Login} />
          <Route path="/Privacy_Policy" component={Privacy_Policy} />
          <Route path="/refund_policy" component={Privacy_Policy} />
          <Route path="/risk_disclosure" component={Privacy_Policy} />
          <Route path="/signup" component={Signup} />
          <Route path="/kyc" component={Kyc} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/brokerage" component={Brokerage} />
          <Route path="/margin" component={Margin_calculator} />
          <Route path="/ourproducts" component={Ourproducts} />
          <Route path="/termsandconditions" component={Obligations} />
          <Route path="/cdsl" component={Cdsl} />
          <Route path="/selfie" component={Selfie} />
          <Route path="/payment" component={Payment} />
          <Route path="/signature" component={Signature} />
          <Route path="/fatca" component={Fatca} />
          <Route path="/faqs" component={Faq} />
          <Route path="/account_closure" component={Account_closure} />
          <Route path="/account_aggregator" component={Account_aggregator} />
          {/* change in route -13/09/24 - Dev Srini */}
          <Route path="/ad_campaign" component={Campaign} />
          
          <Route component={Landingpage} />
        </Switch>
      </Router>
    </div>

  )
}

export default MainLandingPage