import { Typography } from '@mui/material'
import React, { Component } from 'react'

// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Margin | BullForce',
    description: 'Bullforce Margin: Maximize your trading potential with flexible margin options. Access higher leverage for stocks, bonds, and IPOs on our secure trading platform.',
    canonical: 'https://bullforce.co/margin',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, margin, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

export default class margin_calculator extends Component {

    
    render() {
        return (
            <div style={{ paddingTop: '120px', display: 'flex', justifyContent: 'center', height: '100vh', background: '#ffffff' }}>
                <DocumentMeta {...meta} />
                <Typography style={{ fontfamily: 'cardiuma-Bold !important', color: '#111111', fontSize: '28px' }}>Coming Soon</Typography>

            </div>
        )
    }
}
