import React, { Component } from 'react'
import { withStyles, styled } from "@mui/styles";
import {
    Grid, Tab, Tabs
} from '@mui/material'
import Footer from '../landingpage/fotter'
import Account from './Account';

const StyledTabs = styled(Tab)({

    '&.Mui-selected': {
        fontFamily: 'cardiuma-Bold !important',
    },
});


const styles = theme => ({

    buttonlabel: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {

            cursor: 'pointer'

        },
        // "@media only screen and (max-device-width: 480px)": {
        //     color: '#FFFFFF !important',fontSize: '12px !important',
        // }
    },
    buttonlabel_bold: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important'

    },
    buttonlabel_bold1: {
        color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'
        },

    },
    p_card:{
        width: '90%',
        
    },
    p_tab_back:{
        background: '#F7F9FA !important',
       
    },
    p_tab_back1:{
        background: '#ffffff', height: 'auto', padding: '32px', borderRadius: '8px', marginTop: '15px' ,

    },
    main_tab:{
        paddingTop: '100px', height: 'auto',
        "@media only screen and (max-device-width: 480px)": {
            paddingTop: '70px', height: 'auto',
        }

    }
})
class FAQ extends Component {
    constructor(props) {
        super(props)


        this.state = {
            tabvalue: 0
        }
    }
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2"
        if (window.innerWidth < 960) {
            document.body.style.backgroundColor = "#1B2636"
        }
    }
    handleTabChange = (event, value) => {


        this.setState({ tabvalue: value });
    };

    render() {
        const { classes } = this.props;
        const { tabvalue } = this.state
        return (
            <div className={classes.main_tab}>
                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: '30px' }}>
                    <Grid className={classes.p_card}>
                        <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} className={classes.p_tab_back}

                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#F3D45E"
                                }
                            }}

                        >
                            <StyledTabs style={{ width: '25%' }} className={classes.buttonlabel} label="Account" />
                            
                        </Tabs>
                        {tabvalue === 0 &&
                            <div className={classes.p_tab_back1}>
                                <Account/>
                            </div>
                        }

                    </Grid>
                </Grid>
                <Grid style={{ width: '100%' }}>
                    <Footer />
                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((FAQ)));