import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import OtpInput from "react-otp-input";
import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { Close } from '@mui/icons-material';
import * as CryptoJS from 'crypto-js';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import {
    Typography, Grid, Button,Modal,Box,Snackbar, SnackbarContent, IconButton
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
  

    page1card: {
       width: '100%',
     
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   resendtxt:{
    cursor: 'pointer', textAlign: 'center', marginLeft: '15px', color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '14px' 

},
resendtxtdis:{
     textAlign: 'center', marginLeft: '15px', color: '#999999', fontFamily: 'cardiuma-regular !important', fontSize: '14px' 
    
},
   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },

})
 class s_otp extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            resendstatus: false,
            emailid:'',
            otp: '',
            starttime:true,
        islodding:false,
            aadharnobuttonst: true,
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    ResendOTP(){
        
        this.setState({resendstatus:true})
    }
    Continue3 = async () => {
        const {  otp } = this.state
        let adata1 = ''
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
let citizen=rd2.citizen

        let isok = 0
        if (!otp.length && isok === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter the OTP Number", mysnack: snackeror.myerror })


        }




        if (otp.length !== 6 && isok === 0) {

            isok = 1
            this.setState({ showerr: true, errorMsg: "Enter valid OTP Number", mysnack: snackeror.myerror })


        }
        if (isok === 0) {
            this.setState({ islodding: true })
            let payload = {
              
                "otp": otp,
                "emailid": emailid,
                "mobileno": mobileno,

            }
            let payload1 = {
              
               
                "emailid": emailid,
                "mobileno": mobileno,

            }
            try {
                const otp = await httpRequest({
                    endPoint: `/api/otp/validate`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                this.setState({ islodding: false })
                if (otp.status === 200) {

try {
    const stagevalue = await httpRequest({
        endPoint: `/api/registration/stage`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });
    this.setState({ buttonlodder: false })
    if (stagevalue === "API_ERROR") {
        this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
    }
    if (stagevalue.count !== 0) {
               
        let adata = JSON.parse(CryptoJS.AES.decrypt(stagevalue.adata, "96358214256984120").toString(CryptoJS.enc.Utf8))
        let data = JSON.parse(CryptoJS.AES.decrypt(stagevalue.data, "96358214256984120").toString(CryptoJS.enc.Utf8))
        adata1 = JSON.parse(adata[0].data)
       
        let payload = {
            emailid: emailid,
            mobileno: mobileno,
          
        }
      let panverfy=0
    
      if(data[0].pan_verify===null)
      {
        panverfy=0
        }else{
            panverfy=1
      }



const reg_data = {
    emailid:emailid,
    mobileno: mobileno,
    stage:0,
    citizen:citizen,
    client_id:'',
    panverfy: panverfy, 
    aadharverfy: 1, 
    upi_id: data[0].upi_id, 
    bankaccno: data[0].bankaccount,
    ifsc: data[0].ifsc, 
tinvestment: data[0].tinvestment,
 Annual_Income: data[0].annual_income,
  Occupation: data[0].occupation,
  aadharno: CryptoJS.AES.decrypt(data[0].aadharno, "96358214256984120").toString(CryptoJS.enc.Utf8),
  //panno:CryptoJS.AES.decrypt( data[0].panno, "96358214256984120").toString(CryptoJS.enc.Utf8),
  fullname: data[0].full_name,
   dob: adata1.data.dob,
    gender: adata1.data.gender, 
    profile_img: 'data:image/jpeg;base64,' + adata1.data.profile_image,
     father_name: adata1.data.care_of 

}

      

        if (data[0].stage === "completed") {

            window.ChangePageNo (15)
        }
        if (data[0].stage === "nri") {
            window.ChangePageNo (13)
            
        }

        

        if (data[0].stage === "docupload") {
            if (this.state.citizen===0) {
                window.ChangePageNo (14)
            } else {  window.ChangePageNo (15) }
        }

        if (data[0].stage === "nominee") {
          
                window.ChangePageNo (13)
          
        }
        if (data[0].stage === "payment") {
            window.ChangePageNo (12)
        }


        if (data[0].stage === "esign OTP") {
            window.ChangePageNo (11)
        }
        if (data[0].stage === "esign-otp") {
           
            window.ChangePageNo (11)
        }

       
        if (data[0].stage === "liveness" || data[0].stage === "esign") {
            window.ChangePageNo (9)
        }

        if (data[0].stage === "Bank_verification" || data[0].stage === "Image_Failed") {
            window.ChangePageNo (8)
        }

        if (data[0].stage === "preferences") {
            window.ChangePageNo (7)
        }

        if (data[0].stage === "Annual_Income") {
            window.ChangePageNo (6)
        }

        if (data[0].stage === "Occupation") {
            window.ChangePageNo (5)
        }

        if (data[0].stage === "PAN verified") {
            window.ChangePageNo (4)
        }

        if (data[0].stage === "aadhaar verified") {

            window.ChangePageNo (3)
        }


        if (data[0].stage === "OTP_SENT") {



            window.ChangePageNo (1)
        }

       
    }
} catch (error) {
    
}








                } else {
                    this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
                }
            } catch (error) {

                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

            }

        }
    }
    handleOTPChange = (otp) => {
        this.setState({ otp })


    }
  
  render() {
    const { classes } = this.props;
    return (
      <div> <Grid className={classes.page1card} >
      <div style={{ visibility: 'hidden' }}>
       <CountdownCircleTimer
           isPlaying={this.state.starttime}
           style={{ visibility: 'hidden' }}
           duration={60}
           size={100}
           colors={['#004777', '#F7B801', '#F7B801', 'red']}
           colorsTime={[15, 10, 5, 0]}
           onComplete={() => {
               this.ResendOTP()
           }}
       >
           {({ remainingTime }) => remainingTime}
       </CountdownCircleTimer>
   </div>
   <Typography className={classes.card_tit} >Please enter OTP</Typography>


   <Typography style={{ textAlign: 'center', marginTop: '15px', color: '#999999', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number</Typography>
   <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
       <Grid style={{ width: '95%', display: 'flex', justifyContent: 'center', marginTop: '5px', }}>
           <Typography style={{ textAlign: 'center', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>{this.state.maskedaadharno}</Typography>
          
       </Grid>

   </Grid>
   <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', }}>

       <OtpInput

           numInputs={6}
           value={this.state.otp}
           isInputSecure={true}
           shouldAutoFocus={true}
           focusStyle={{
               outline: 'none',
               backgroundColor: 'red !important',
           }}

           inputStyle={{
               backgroundColor: 'transparent',
               width: "40px",
               height: "40px",


               border: "none",
               marginRight: "5px",
               color: "#ffffff ",
               fontSize: "16px",
               fontFamily: "cardiuma-M",
               borderBottom: '1px solid #949A9E',

           }}
           containerStyle={{
               justifyContent: "center",
               borderBottom: '10',

           }}
           renderInput={(props) => <input {...props} />}

           onChange={this.handleOTPChange}
       />
   </Grid>
   <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
<Grid style={{width:'80%'}}>
   <Button fullWidth className={classes.joinDiplomtsBtn}
       style={{ marginTop: '50px', }}
       onClick={() => this.Continue3()}
   >Continue

   </Button>
   </Grid>   </Grid>
   
</Grid>
<Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
<Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

</Modal>
<Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
</div>
    )
  }
}
export default (withStyles(styles)((s_otp)));