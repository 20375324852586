import React, { Component } from 'react'
import './brokerage.css'
import {
    Typography, Grid, Radio, RadioGroup, FormControlLabel, TextField
} from '@mui/material'
import { withStyles, styled } from "@mui/styles";
const styles = theme => ({

    bmaintit :{
        color: '#3E3E3E',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '32px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
    },
    
    maincard: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        padding: '100px 0px 0px 0px',
    },
    
    subcard: {
        width: '23%',
        padding: '24px',
        borderRadius: '8px',
        background: '#ffffff',
        "@media only screen and (max-device-width: 480px)": {
            
            width: '100%',
           
        }
    },
    
    subcard1: {
        width: '40%',
        padding: '24px',
        borderRadius: '8px',
        background: '#ffffff',
    },
    
    subcard2: {
        width: '45%',
        padding: '24px',
        borderRadius: '8px',
        background: '#ffffff',
    },
    
    subtit: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-M !important',
        fontSize: '16px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '18px',
        /* 112.5% */
    },
    
    subtit1: {
        color: '#999999',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        /* 200% */
    },
    
    bamountcard :{
        borderRadius: '4px',
        border: '1px solid #999999',
        background: '#FFFFFF',
        padding: '6px',
        display: 'flex',
        height: '32px',
        justifyContent: 'flex-start',
    },
    
    bamount: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        fontStyle: 'normal',
        textAlign: 'left',
        fontWeight: '400',
    
        /* 166.667% */
    },
    
    bopttxt :{
        color: '#3E3E3E',
        fontFamily: 'cardiuma-M !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        /* 142.857% */
    },
    
    bsubtit1: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',
        /* 266.667% */
    },
    
    bsubtit2: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',
        textAlign: 'right',
        /* 266.667% */
    },
    
    nettit: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        /* 142.857% */
    },
    
    netamt: {
        color: '#21B12F',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        /* 142.857% */
    },
    
    netamt1: {
        color: '#F74542',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        /* 142.857% */
    },
    card:{
        width: '100%', display: 'flex', justifyContent: 'space-around', marginBottom: '50px',
        "@media only screen and (max-device-width: 480px)": {
            
            width: '100%',display: 'block', justifyContent: 'center'
           
        }
    }


})
const CssTextField = styled(TextField)({
    borderRadius: '8px',

    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

class fando extends Component {
    constructor(props) {
        super(props)


        this.state = {
            b1: 1000,
            s1: 1100,
            q1: 400,
            brokerage_buy1: 0,
            turnover1: 0,
            exc_trans_charge1: 0,
            stt_total1: 0,
            sebi_charges1: 0,


            gst1: 0,
            bse1: true,
            nse1: false,
            brokerage_buy2: 0,

            b2: 1000,
            s2: 1100,
            q2: 400,


            b3: 1000,
            s3: 1100,
            q3: 400,
            brokerage_buy3: 0,
            b4: 100,
            s4: 110,
            q4: 400,
            nse4: true,
            bse4: false,
            brokerage_buy4: 0,
            opt1: 'nse1',
            opt2: 'nse2',
            opt3: 'nse3',
            opt4: 'nse4',

        }



    }

    async handle1(e) {
        this.state.opt1 = e.target.value
        this.equity()
    };
    async handle2(e) {
        this.state.opt2 = e.target.value
        this.delivery_equity()
    };
    async handle3(e) {
        this.state.opt3 = e.target.value
        this.cal_futures()

    };
    async handle4(e) {
        this.state.opt4 = e.target.value
        this.cal_options()
    };
    componentDidMount = async () => {
        this.equity()
        this.delivery_equity()
        this.cal_futures()
        this.cal_options()
    }
    eb1(e) {
        this.state.b1 = e.target.value
        this.equity()
    }
    es1(e) {
        this.state.s1 = e.target.value
        this.equity()
    }

    eq1(e) {
        this.state.q1 = e.target.value
        this.equity()
    }


    eb2(e) {
        this.state.b2 = e.target.value
        this.delivery_equity()
    }
    es2(e) {
        this.state.s2 = e.target.value
        this.delivery_equity()
    }

    eq2(e) {
        this.state.q2 = e.target.value
        this.delivery_equity()

    }

    eb3(e) {
        this.state.b3 = e.target.value
        this.cal_futures()
    }
    es3(e) {
        this.state.s3 = e.target.value
        this.cal_futures()
    }

    eq3(e) {
        this.state.q3 = e.target.value
        this.cal_futures()

    }

    eb4(e) {
        this.state.b4 = e.target.value
        this.cal_options()
    }
    es4(e) {
        this.state.s4 = e.target.value
        this.cal_options()
    }

    eq4(e) {
        this.state.q4 = e.target.value
        this.cal_options()

    }
    cal_options() {


        let b4 = this.state.b4
        let s4 = this.state.s4
        let q4 = this.state.q4

        let turnover = parseFloat(b4) + parseFloat(s4)
        let brokerage_buy = 0
        brokerage_buy = ((b4 * q4 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(b4 * q4 * 0.0003).toFixed(2));

        let brokerage_sell = 0
        brokerage_sell = ((s4 * q4 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(s4 * q4 * 0.0003).toFixed(2));

        let brokerage = 40

        turnover = (parseFloat(turnover) * parseFloat(q4)).toFixed(2)

        var stt_total = Math.round(parseFloat(parseFloat(s4 * q4 * 0.000625).toFixed(2)));
        var sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        let exc_trans_charge = 0
        let nse_ipft = 0
        if (this.state.opt4 === "nse4") {
            exc_trans_charge = parseFloat(parseFloat(0.0005 * turnover).toFixed(2))
            nse_ipft = parseFloat(parseFloat(0.000005 * turnover).toFixed(2))
        } else {
            exc_trans_charge = parseFloat(parseFloat(0.00005 * turnover).toFixed(2))
            nse_ipft = 0
        }
        let gst = 0
        gst = ((parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(sebi_charges)) * 0.18).toFixed(2)
        exc_trans_charge = (exc_trans_charge + nse_ipft).toFixed(2)

        let cc = 0
        var stamp_charges = Math.round(parseFloat(parseFloat(b4 * q4 * 0.00002).toFixed(2)));

        var total_tax = (parseFloat(brokerage) + parseFloat(stt_total) + parseFloat(exc_trans_charge) + parseFloat(gst) + parseFloat(sebi_charges) + parseFloat(stamp_charges)).toFixed(2);

        var breakeven = parseFloat(parseFloat(total_tax / q4).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven

        var net_profit = parseFloat(parseFloat(((s4 - b4) * q4) - total_tax).toFixed(2));


        this.setState({ exc_trans_charge4: exc_trans_charge, gst4: gst, brokerage_buy4: brokerage, turnover4: turnover, stt_total4: stt_total, sebi_charges4: sebi_charges })

        this.setState({ stamp_charges4: stamp_charges, total_tax4: total_tax, breakeven4: breakeven, net_profit4: net_profit })


    }
    cal_futures() {

        let b3 = this.state.b3
        let s3 = this.state.s3
        let q3 = this.state.q3

        let turnover = parseFloat(b3) + parseFloat(s3)
        let brokerage_buy = 0
        brokerage_buy = ((b3 * q3 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(b3 * q3 * 0.0003).toFixed(2));

        let brokerage_sell = 0
        brokerage_sell = ((s3 * q3 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(s3 * q3 * 0.0003).toFixed(2));

        let brokerage = 0
        brokerage = (parseFloat(brokerage_buy) + parseFloat(brokerage_sell)).toFixed(2)
        turnover = (parseFloat(turnover) * parseFloat(q3)).toFixed(2)

        var stt_total = Math.round(parseFloat(parseFloat(s3 * q3 * 0.000125).toFixed(2)));
        var sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        let exc_trans_charge = 0
        let nse_ipft = 0
        if (this.state.opt3 === "nse3") {
            exc_trans_charge = parseFloat(parseFloat(0.000019 * turnover).toFixed(2))
            nse_ipft = parseFloat(parseFloat(0.000001 * turnover).toFixed(2))
        } else {
            exc_trans_charge = 0
            nse_ipft = 0
        }
        let gst = 0
        gst = ((parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(sebi_charges)) * 0.18).toFixed(2)
        exc_trans_charge = (exc_trans_charge + nse_ipft).toFixed(2)

        let cc = 0
        var stamp_charges = Math.round(parseFloat(parseFloat(b3 * q3 * 0.00002).toFixed(2)));

        var total_tax = (parseFloat(brokerage) + parseFloat(stt_total) + parseFloat(exc_trans_charge) + parseFloat(gst) + parseFloat(sebi_charges) + parseFloat(stamp_charges)).toFixed(2);

        var breakeven = parseFloat(parseFloat(total_tax / q3).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven

        var net_profit = parseFloat(parseFloat(((s3 - b3) * q3) - total_tax).toFixed(2));


        this.setState({ exc_trans_charge3: exc_trans_charge, gst3: gst, brokerage_buy3: brokerage, turnover3: turnover, stt_total3: stt_total, sebi_charges3: sebi_charges })

        this.setState({ stamp_charges3: stamp_charges, total_tax3: total_tax, breakeven3: breakeven, net_profit3: net_profit })


    }
    equity() {

        let { nse1, bse1 } = this.state
        let b1 = this.state.b1
        let s1 = this.state.s1
        let q1 = this.state.q1


        let turnover1 = parseFloat(b1) + parseFloat(s1)
        let brokerage_buy = 0
        brokerage_buy = ((b1 * q1 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(b1 * q1 * 0.0003).toFixed(2));

        let brokerage_sell = 0
        brokerage_sell = ((s1 * q1 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(s1 * q1 * 0.0003).toFixed(2));

        let brokerage = 0
        brokerage = (parseFloat(brokerage_buy) + parseFloat(brokerage_sell)).toFixed(2)


        turnover1 = (parseFloat(turnover1) * parseFloat(q1)).toFixed(2)
        let exc_trans_charge = 0
        let nse_ipft = 0

        if (this.state.opt1 === "nse1") {
            exc_trans_charge = parseFloat(parseFloat(0.0000325 * turnover1).toFixed(2))
            nse_ipft = parseFloat(parseFloat(0.000001 * turnover1).toFixed(2))
        } else {
            exc_trans_charge = parseFloat(parseFloat(0.0000375 * turnover1).toFixed(2))
            nse_ipft = 0
        }

        let stt_total1 = 0
        stt_total1 = Math.round(parseFloat(parseFloat((s1 * q1) * 0.00025).toFixed(2)));
        let sebi_charges1 = 0
        sebi_charges1 = parseFloat(parseFloat(turnover1 * 0.000001).toFixed(2));
        let gst = 0
        gst = ((parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(sebi_charges1)) * 0.18).toFixed(2)

        exc_trans_charge = (exc_trans_charge + nse_ipft).toFixed(2)
        let stamp_charges = 0
        stamp_charges = Math.round(parseFloat(parseFloat((b1 * q1) * 0.00003).toFixed(2)));
        let total_tax = 0
        total_tax = parseFloat(brokerage) + parseFloat(stt_total1) + parseFloat(exc_trans_charge) + parseFloat(gst) + parseFloat(sebi_charges1) + parseFloat(stamp_charges)

        let breakeven = 0
        breakeven = parseFloat(parseFloat(total_tax / q1).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven

        let net_profit = 0
        net_profit = parseFloat(parseFloat(((s1 - b1) * q1) - total_tax).toFixed(2));
        this.setState({ brokerage_buy1: brokerage, turnover1: turnover1, stt_total1: stt_total1, sebi_charges1: sebi_charges1 })
        this.setState({ exc_trans_charge1: exc_trans_charge, gst1: gst, stamp_charges1: stamp_charges, total_tax1: total_tax, breakeven1: breakeven, net_profit1: net_profit })
    }
    delivery_equity() {

        let b2 = this.state.b2
        let s2 = this.state.s2
        let q2 = this.state.q2
        let turnover = parseFloat(b2) + parseFloat(s2)
        let brokerage_buy = 0
        brokerage_buy = ((b2 * q2 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(b2 * q2 * 0.0003).toFixed(2));

        let brokerage_sell = 0
        brokerage_sell = ((s2 * q2 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(s2 * q2 * 0.0003).toFixed(2));

        let brokerage = 0
        turnover = (parseFloat(turnover) * parseFloat(q2)).toFixed(2)
        var stt_total = Math.round(parseFloat(parseFloat(turnover * 0.001).toFixed(2)));
        var sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        let exc_trans_charge = 0
        let nse_ipft = 0

        if (this.state.opt2 === "nse2") {
            exc_trans_charge = parseFloat(parseFloat(0.0000325 * turnover).toFixed(2))
            nse_ipft = parseFloat(parseFloat(0.000001 * turnover).toFixed(2))
        } else {
            exc_trans_charge = parseFloat(parseFloat(0.0000375 * turnover).toFixed(2))
            nse_ipft = 0
        }
        let cc = 0
        let dp = 15.93
        if (s2 === 0) { dp = 0 }
        let gst = 0
        gst = ((parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(sebi_charges)) * 0.18).toFixed(2)
        exc_trans_charge = (exc_trans_charge + nse_ipft).toFixed(2)

        var stamp_charges = Math.round(parseFloat(parseFloat(b2 * q2 * 0.00015).toFixed(2)));
        var total_tax = (parseFloat(brokerage) + parseFloat(stt_total) + parseFloat(exc_trans_charge) + parseFloat(cc) + parseFloat(dp) + parseFloat(gst) + parseFloat(sebi_charges) + parseFloat(stamp_charges)).toFixed(2);
        var breakeven = parseFloat(parseFloat(total_tax / q2).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven

        var net_profit = parseFloat(parseFloat(((s2 - b2) * q2) - total_tax).toFixed(2));

        this.setState({ brokerage_buy2: brokerage, turnover2: turnover, stt_total2: stt_total, sebi_charges2: sebi_charges, exc_trans_charge2: exc_trans_charge })
        this.setState({ dp: dp, gst2: gst, sebi_charges2: sebi_charges, stamp_charges2: stamp_charges, total_tax2: total_tax, breakeven2: breakeven, net_profit2: net_profit })



    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.card}>
                <Grid className={classes.subcard}>
                    <Typography className={classes.subtit}>Intraday equity</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}

                                onClick={(e) => this.equity()}
                            >
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.b1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb1(e)}
                            />

                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.s1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es1(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.q1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq1(e)}
                            />
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <RadioGroup
                            row

                            value={this.state.opt1}
                            onChange={e =>
                                this.handle1(e)


                            }


                        >
                            <FormControlLabel value="nse1" control={<Radio
                                disableripple
                                size='small'

                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>NSE</Typography>} />
                            <FormControlLabel value="bse1" control={<Radio

                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>BSE</Typography>} />







                        </RadioGroup>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.turnover1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.brokerage_buy1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>STT total</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stt_total1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.exc_trans_charge1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>0</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.gst1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.sebi_charges1}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stamp_charges1}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.total_tax1}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.breakeven1}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.nettit}>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit1 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit1}</Typography>
                        </Grid>


                    </Grid>


                </Grid>
                <Grid className={classes.subcard}>
                    <Typography className={classes.subtit}>Delivery equity</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.b2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.s2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.q2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq2(e)}
                            />
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <RadioGroup
                            row

                            value={this.state.opt2}
                            onChange={e =>
                                this.handle2(e)


                            }


                        >
                            <FormControlLabel value="nse2" control={<Radio
                                disableripple
                                size='small'

                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>NSE</Typography>} />
                            <FormControlLabel value="bse2" control={<Radio

                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>BSE</Typography>} />







                        </RadioGroup>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.turnover2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.brokerage_buy2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>STT total</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stt_total2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.exc_trans_charge2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>DP charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.dp}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.gst2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.sebi_charges2}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stamp_charges2}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.total_tax2}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.breakeven2}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.nettit}>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit2 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit2}</Typography>
                        </Grid>


                    </Grid>


                </Grid>
                <Grid className={classes.subcard}>
                    <Typography className={classes.subtit}>F&O - Futures</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.b3}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb3(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.s3}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es3(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.q3}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq3(e)}
                            />
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            value={this.state.opt3}
                            onChange={e =>
                                this.handle3(e)


                            }





                        >
                            <FormControlLabel value="nse3" control={<Radio
                                disableripple
                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>NSE</Typography>} />
                            <FormControlLabel value="bse3" control={<Radio

                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>BSE</Typography>} />







                        </RadioGroup>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.turnover3}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.brokerage_buy3}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>STT total</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stt_total3}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.exc_trans_charge3}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>0</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.gst3}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.sebi_charges3}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stamp_charges3}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.total_tax3}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.breakeven3}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.nettit}>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit3 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit3}</Typography>
                        </Grid>


                    </Grid>


                </Grid>
                <Grid className={classes.subcard}>
                    <Typography className={classes.subtit}>F&O - Options</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.b4}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb4(e)}
                            />

                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.s4}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es4(e)}
                            />


                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.q4}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq4(e)}
                            />
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            value={this.state.opt4}
                            onChange={e =>
                                this.handle4(e)


                            }





                        >
                            <FormControlLabel value="nse4" control={<Radio
                                disableripple
                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>NSE</Typography>} />
                            <FormControlLabel value="bse4" control={<Radio

                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>BSE</Typography>} />







                        </RadioGroup>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.turnover4}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.brokerage_buy4}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.subtit1}>STT total</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stt_total4}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.exc_trans_charge4}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>0</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.gst4}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.sebi_charges4}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stamp_charges4}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.total_tax4}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.subtit1}>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.breakeven4}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.nettit}>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit4 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit4}</Typography>
                        </Grid>


                    </Grid>


                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((fando)));