import React, { Component } from 'react'
import { withStyles, styled } from "@mui/styles";
import './brokerage.css'
import Fando from './fando'
import Currency_main from './currency_main.js'
import Commodities from './commodities.js'
import Footer from '../landingpage/fotter'
import {
    Typography, Grid, Tab, Tabs
} from '@mui/material'

import DocumentMeta from 'react-document-meta'

// meta tag & content added - 02/09/24 - Dev Srini
const meta = {
    title: 'Brokerage | BullForce',
    description: 'Bullforce: Low-cost brokerage on NSE, BSE trades. Open a demat account and trade stocks, bonds, and IPOs with competitive fees. Trusted by NRIs, HNIs, institutions.',
    canonical: 'https://bullforce.co/brokerage',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, brokerage, bullforce, NRIs, HNIs, and institutions, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs'
        }
    }
}

const StyledTabs = styled(Tab)({

    '&.Mui-selected': {
        fontFamily: 'cardiuma-Bold !important',
    },
});

const styles = theme => ({

    buttonlabel: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '15px !important',
        "&:hover": {

            cursor: 'pointer'

        },
    },
    buttonlabel_bold: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important'

    },
    buttonlabel_bold1: {
        color: '#000000 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '15px !important', fontWeight: '600 !important',

        "&:hover": {
            color: "#00ACFE !important",
            cursor: 'pointer'

        },

    }
})


class brokerage extends Component {
    constructor(props) {
        super(props)


        this.state = {


            tabvalue: 0
        }



    }
    handleTabChange = (event, value) => {

        console.log('tabvalue==', value)
        this.setState({ tabvalue: value });
    };

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F7F9FA"
    }
    render() {
        const { classes } = this.props;
        const { tabvalue } = this.state
        return (
            <div>
                <DocumentMeta {...meta} />
                {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                <Grid className='maincard'>
                    <h1 className='bmaintit'>Brokerage calculator</h1>
                </Grid>

                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Grid style={{ width: '90%' }}>
                        <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} style={{ background: '#F7F9FA' }}
                            indicatorColor='red'
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#F3D45E"


                                }
                            }}

                        >
                            <StyledTabs style={{ width: '33%' }} className={classes.buttonlabel} label="Equities - F&O" />
                            <StyledTabs style={{ width: '33%' }} className={classes.buttonlabel} label="Currency" />
                            {/* <StyledTabs style={{ width: '33%' }} className={classes.buttonlabel} label="Commodities" />*/}


                        </Tabs>
                        {tabvalue === 0 &&
                            <div style={{ background: '#F7F9FA', height: 'auto', width: '100%', marginTop: '25px' }}>
                                <Fando />
                            </div>
                        }

                        {tabvalue === 1 &&
                            <div style={{ background: '#F7F9FA', height: 'auto', width: '100%', marginTop: '25px' }}>
                                <Currency_main />
                            </div>
                        }

                        {tabvalue === 2 &&
                            <div style={{ background: '#F7F9FA', height: 'auto', width: '100%', marginTop: '25px' }}>
                                <Commodities />
                            </div>
                        }


                    </Grid>
                </Grid>
                <Grid style={{ width: '100%',background:'#0B1420' }}>
                    <Footer />
                </Grid>
            </div>
        )
    }
}

export default (withStyles(styles)((brokerage)));