import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { Grid, Typography, } from '@mui/material'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Refund Policy | BullForce',
    description: 'Bullforce Refund Policy: Transparent refunds for trading, demat accounts, and subscriptions. Secure and clear process to protect your investments and payments.',
    canonical: 'https://bullforce.co/refund_policy',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, refund, policy, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const styles = theme => ({

    heading: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important',  // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',

        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '20px !important',
        }


    },
    p_body: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",
        "@media only screen and (max-device-width: 480px)": {

            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    },

})

class refund_policy extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: 'auto', padding: '0px 0px 20px 0px' }}>

                <DocumentMeta {...meta} />
                <Grid style={{ padding: '20px 0px 40px 0px' }}>
                    {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                    <h1 className={classes.heading}  >

                        Refund & cancellation policy
                    </h1>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        The Refund & Cancellation policy for all payments made towards account opening using the
                        payment gateway shall stand as under:
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >

                        <ul>
                            <li>The Fees paid towards account opening charges for enabling equities as well as
                                commodities is non-refundable.</li>
                            <li>Pick up of required documents related to the account opening procedure is subject to
                                availability of our representatives, given at any particular time and location.</li>
                            <li>In case your account has not been opened by Maliram Makharia Finstock Pvt. Ltd., (Bullforce)’s team, after the fifth day passing
                                by from the day of collection of all necessary supporting documents and receipt of all due
                                authorizations from you, you may request for a full refund of the charges as paid by you
                                towards account opening.</li>
                            <li>In case you have paid the charges relating to account opening multiple times, please
                                create a ticket here and we will initiate the necessary procedure to refund your money</li>

                        </ul>
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    As per <b>SEBI’s circular</b> , funds in the trading account must be transferred back to the client's bank account once a quarter. Transferring unused funds back to the primary bank account is called quarterly settlement of funds or running account settlement.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    The settlement of running account of funds of the client will be done by MMFPL after considering the End of the day (EOD) obligation of funds as on the date of settlement across all the Exchanges on first Friday of the Quarter (i.e., Apr-Jun, Jul-Sep, Oct-Dec, Jan–Mar) for all the clients.  i.e., the running account of funds will be settled on first Friday of July, October, January, April  and so on for all the clients. If first Friday is a trading holiday, then such settlement shall happen on the previous trading day.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    For clients, who have opted for monthly settlement, running account will be settled on first Friday of every month. If first Friday is a trading holiday, then such settlement shall happen on the previous trading day.
                    </Typography>
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                        <b>Note:</b> The completion of the refund procedure is subject to agencies such as banks, payment
                        gateways
                    </Typography>
                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((refund_policy)));