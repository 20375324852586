import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'

import * as CryptoJS from 'crypto-js';
import InputMask from 'react-input-mask';
import { Close } from '@mui/icons-material';
import {
    Typography, Grid, Button,TextField,Checkbox,Modal,Box,Snackbar, SnackbarContent, IconButton
} from '@mui/material'
import { signup_funcation } from './signup_funcation';
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {
 
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'
 
        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'
 
        }
    },
    sigCanvas:{
        background:'#ffffff'
    },
    shutter:{
        marginTop:'50px',
        width:'100%',
        height:'100vh',
        background:'red',
        borderTopLeftRadius :'8px',
        borderTopRightRaduis:'8px',
    },
 
 
  
 };
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);

const styles = theme => ({
    esign_card: {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            paddingTop:'52px',
            marginLeft: '0%',

        }
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    card_tit1: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important' , fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important', textAlign: 'left'
        },

    },
    esigncard: {
        borderRadius: '8px', paddingBottom: '25px',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
disabled:'flex',
            marginLeft: '0%',
            background: "#1B2636",
            paddingBottom: '0px'

        }
    },
    esign_tit:
    {
        color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '16px',
        "@media only screen and (max-device-width: 480px)": {
            color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '12px',

        }
    },
    esign_tit1:
    {
        color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '16px',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '12px',

        }
    },
    esign_otp:
    {
        color: '#ffffff', fontFamily: 'cardiuma-M !important', fontSize: '16px',
        "@media only screen and (max-device-width: 480px)": {
            color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px',

        }
    },
})
class e_sign extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            esign:true,
            aadharno:'',
            islodding:false,
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {
      
     
let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
            
this.setState( {
               
                    aadharno: rd2.aadharno,
                 
                
                                })
      

    }
    Continue7 = async () => {
        if (this.state.esign === false) {
            this.setState({ showerr: true, errorMsg: "Accept Terms and conditions", mysnack: snackeror.myerror })
        }
        if (this.state.esign === true) {


            const { aadharno  } = this.state
            let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
            let emailid=rd2.emailid
    let mobileno=rd2.mobileno
            this.setState({ islodding: true, otp: '' })

            let payload = {
                "aadharno": CryptoJS.AES.encrypt(aadharno, "96358214256984120").toString(),
                "emailid": emailid,
                "mobileno": mobileno

            }
            try {
                const otp = await httpRequest({
                    endPoint: `/api/verfy/esignotp`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({ islodding: false })
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                if (otp.status === 200) {
                    signup_funcation(rd1,"client_id",otp.client_id)

                    window.ChangePageNo (10)

                  //  this.setState({ pageno: 10, client_id: otp.client_id, backimg_style: 1 })




                } else {
                    this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
                }
            } catch (error) {

                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

            }




        }
    }

  render() {
    const { classes } = this.props;
    return (
      <div>            <Grid className={classes.esign_card} >
      <Typography className={classes.card_tit1}>Terms and conditions</Typography>

      <Grid className={classes.esigncard} >

          <Grid style={{  width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
              <WhiteBackgroundCheckbox
                  checked={this.state.esign}
                  onChange={() => this.setState({ esign: !this.state.esign })}
              ></WhiteBackgroundCheckbox>
              <Typography className={classes.esign_tit} >I agree with Bullforce</Typography>
              <Typography className={classes.esign_tit1}

                  onClick={() => this.props.history.push('/Privacy_Policy', this.state)}
              > Terms & Conditions</Typography>
          </Grid>
          <Grid style={{ marginTop: '8%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              <Typography className={classes.esign_otp}>e-Sign thro Aadhar - OTP</Typography>

          </Grid>


          <Grid style={{ marginTop: '8%', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Grid style={{ width: '90%', display: 'flex', border: '1px solid #BCD171', borderRadius: '8px', justifyContent: 'center', padding: '13px 10px' }}>
                  <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
                      <InputMask
                          mask="9999-9999-9999"
                          value={this.state.aadharno}
                          disabled={true}
                          maskChar=" "

                      >
                          {() => <TextField variant="standard" size="small"


                              InputProps={{
                                  classes: { input: classes.input },
                                  maxLength: 12,
                                  style: { letterSpacing: '5px', color: "#ffffff", fontFamily: 'cardiuma-M', fontSize: '18px', display: 'flex', justifyContent: 'center', }
                              }}
                          />}
                      </InputMask>
                  </Grid>
              </Grid>
          </Grid>
          <Grid style={{ marginTop: '4%', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ color: "#999999", fontFamily: 'cardiuma-regular', fontSize: '14px', }}>You will receive an OTP on your number</Typography>
          </Grid>
          <Grid style={{ marginTop: '20%', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Grid style={{ width: '80%', display: 'flex', }}>

                  <Button fullWidth className={classes.joinDiplomtsBtn}
                      style={{ marginTop: '20px', }}
                      onClick={() => this.Continue7()}
                  >Sign

                  </Button>
              </Grid>
          </Grid>
      </Grid>

  </Grid>
  <Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
  <Modal
                      sx={{ border: 'none' }}
                      open={this.state.islodding}
                  >
  
                      <Box
                          style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '20%',
                              height: '8%',
  
                              outline: 'none',
                              background: 'transparent',
                              p: 4,
                              borderRadius: '12px',
  
                              justifyContent: 'center', alignItems: 'center'
                          }}
                      >
  
                          <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                              <img src={bull} alt={bull} width='50%' height='50%' />
                          </Grid>
                          <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                              <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                          </Grid>
                      </Box>
  
                  </Modal>
                  <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
  </div>
    )
  }
}
export default (withStyles(styles)((e_sign)));