import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import {signup_funcation} from './signup_funcation'
import { httpRequest } from '../../../ui-utils/api'
import {
    Typography, Grid, Button
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
    page2card: {
        width: '100%',
      
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '52px 16px',
            marginLeft: '0%',

        }
    },
    occ_main_card: {
        width: '100%', padding: '1%', display: 'flex', justifyContent: 'space-evenly', marginTop: '10px',
        "@media only screen and (max-device-width: 480px)": {
            display: 'inline', padding: '0%',
        },
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    occ_card: {
        width: '45%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '15px',
        },

    },
    occ_card1: {
        width: '80%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '15px',
        },

    },
    occ_but: {

        background: "#1B2636 !important",
        padding: "15px 5px !important",
        borderRadius: "8px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        border: '1px solid #DFE0EB !important',
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important",

        "&:hover": {
            background: "#F3D45E !important",
            padding: "15px 5px !important",
            borderRadius: "8px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#0B1420 !important",
            border: '1px solid #DFE0EB !important',
            textTransform: "initial !important",
            fontFamily: "cardiuma-regular !important",

        },

    },

})

 class annual_Income extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
          
        
            emailid:'',
        
            citizen:0,//I need to get NRI. 
        nre:0,//added on 27/08/24 To cheque NRE and NRO 
            Annual_Income: true,
        }



    }
//Added to get dinner right from local hosts, from Local storage. 
        

componentDidMount = async () => {
    let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
    //added on 27/08/24 to show Annual income in dollar 
    if(rd1.nre===true)
    {
    
        this.state.citizen=1
      this.setState({  nre:1})
    }
    if(rd1.nro===true)
        {
            this.setState({  nre:1})
        }

        
  

    }
            
        

    Annual_Income = async (income) => {

        this.setState({ Annual_Income: income })


        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
        let mobileno=rd2.mobileno //added on 30/07/24 Added mobile number. Annual_Income endpoint
    
        let payload = {
            email: emailid,
            option: income,
            optiontype: 2,
            mobileno:mobileno //added on 30/07/24 Added mobile number. Annual_Income endpoint 
        }
signup_funcation(rd1,'Annual_Income',income)
        try {
            const stagevalue = await httpRequest({
                endPoint: `/api/registration/otheroption`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if (stagevalue.status === 200) {
                window.ChangePageNo (6)
            }
        } catch (error) {

        }
    }
  render() {
    const { classes } = this.props;
    return (
      <div>
      <Grid className={classes.page2card} >
          <Typography style={{ color: '#BCD171', fontFamily: 'cardiuma-M', fontSize: '24px',marginLeft:window.innerWidth<600 ? '5px':'20px' }}>Annual Income</Typography>
          <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px', marginTop: '1%',marginLeft:window.innerWidth<600 ? '5px':'20px' }}>Select below option</Typography>
          {this.state.nre===0 && 
          <div>
          <Grid className={classes.occ_main_card} >
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('Upto 1 Lakh')}
                  >
                      Upto 1 Lakh</Button>

              </Grid>
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('1 Lakh - 5 Lakh')}
                  >
                      1 Lakh - 5 Lakh
                  </Button>

              </Grid>
          </Grid>

          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('5 Lakh - 10 Lakh')}
                  >
                      5 Lakh - 10 Lakh
                  </Button>
              </Grid>
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('10 Lakh - 25 Lakh')}
                  >
                      10 Lakh - 25 Lakh
                  </Button>
              </Grid>
          </Grid>
          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('25 Lakh - 50 Lakh')}
                  >
                      25 Lakh - 50 Lakh
                  </Button>
              </Grid>
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('50 Lakh - 1 Crore')}
                  >
                      50 Lakh - 1 Crore
                  </Button>
              </Grid>
          </Grid>
          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('1 Crore - 5 Crores')}
                  >
                      1 Crore - 5 Crores
                  </Button>
              </Grid>
              <Grid className={classes.occ_card}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('More than 5 Crores')}
                  >
                      More than 5 Crores
                  </Button>
              </Grid>

          </Grid>
          </div>
  }

{this.state.nre===1 && 
          <div>
          <Grid className={classes.occ_main_card} >
              <Grid className={classes.occ_card1}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('0 - $ 70k')}
                  >
                     0 - $ 70k</Button>

              </Grid>
              </Grid>
              <Grid className={classes.occ_main_card} >
              <Grid className={classes.occ_card1}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('1 Lakh - 5 Lakh')}
                  >
                     $ 70k - $ 150k 
                  </Button>

              </Grid>
          </Grid>

          <Grid className={classes.occ_main_card}  >
              <Grid className={classes.occ_card1}>
                  <Button fullWidth className={classes.occ_but}
                      onClick={() => this.Annual_Income('5 Lakh - 10 Lakh')}
                  >
                   $ 150k and above
                  </Button>
              </Grid>
             
          </Grid>
      
          </div>
  }
      </Grid>
    
      <Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
      </div>
    )
  }
}
export default (withStyles(styles)((annual_Income)));