import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";

import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'

import {signup_funcation} from './signup_funcation'
import * as CryptoJS from 'crypto-js';
import onemoney from '../../../ui-assets/onemoney.svg'
import Secure from '../../../ui-assets/Secure.svg'
import { Close } from '@mui/icons-material';
import logo from '../../../ui-assets/icon.png'
import {
    Typography, Grid, Button,Modal,Box, Snackbar, SnackbarContent, IconButton
} from '@mui/material'

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {
 
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
  
 
  
 };
const styles = theme => ({
  

    page1card: {
       width: '100%',
       paddingLeft: '20%',
  
       display:'flex',
       justifyContent:'center',
       alignItems:'center',
       top:0,
       left:0,
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   resendtxt:{
    cursor: 'pointer', textAlign: 'center', marginLeft: '15px', color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '14px' 

},
texthear:{
     textAlign: 'center', color: '#F3D45E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '32px  !important' ,
     "@media only screen and (max-device-width: 480px)": {
        fontSize: '20px' 
     }
    
},
texthear1:{
    textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'4%', 
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '16px' 
     }
   
},
texthear2:{
    textAlign: 'center', color: '#F3D45E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '24px  !important' ,
     "@media only screen and (max-device-width: 480px)": {
        fontSize: '20px' 
     }
   
},
texthear3:{
    textAlign: 'center', color: '#9A9A9A !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '12px' 
     }
},
   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },

   skipbut:
   {
    
    padding: "12px 45px !important",
    borderRadius: "20px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#F74542 !important",
    border:'1px solid #F74542 !important',
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important"
},
   cong_card: {
    position: 'absolute',
   
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100vh',
display:'flex',
justifyContent:'center',
    outline: 'none',
    marginTop:'5%',
    p: 4,
    "@media only screen and (max-device-width: 480px)": {
        top: '15%',
        left: '0%',
        transform: 'none',
        width: '100%',
    }
},
cong_card1: {
    width:'50%',
    "@media only screen and (max-device-width: 480px)": {
        width:'100%',
    }
},
butt_card:{
    width:'50%',display:'flex',justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
        width:'80%',
    }
},
esign_tit1:
{
    color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '16px',cursor:'pointer',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '12px',

    }
},
})

 class digilocker extends Component {
    constructor(props) {
       
        super(props)
      
       
        this.state = {
          islodding:false,
          mysnack: snackeror.mysucc,
          showerr: false,
          errorMsg: '0',
       
        }



    }

    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {

        let rd1 = localStorage.getItem('reg_data') || '{}'
let rd2 = JSON.parse(rd1)

  

}
//added 26/04/2024
Continueskip = async () => {
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    let emailid=rd2.emailid
let mobileno=rd2.mobileno
let aadharno=rd2.aadharno

let payload = {
     
         
    "emailid": emailid,
    "mobileno": mobileno,
    "fando":false,
    "type":1
}

try {
    const fandoskip = await httpRequest({
        endPoint: `/api/registration/payment1`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });
    window.ChangePageNo(16.1)
} catch (error) {
    
}

}

//end 26/04/2024
    Continue = async () => {
        //   window.ChangePageNo (9)
           let rd1 = localStorage.getItem('reg_data') || '{}'
           let rd2 = JSON.parse(rd1)
           let emailid=rd2.emailid
   let mobileno=rd2.mobileno
   let aadharno=rd2.aadharno
   let ifsc=rd2.ifsc
   let obj={}
   let maindata=JSON.parse( rd1)

   for (var key in maindata) {

let f=0
if(key==="stage")
{
   obj[key]=4
   f=1
}
if(f===0)
{
      obj[key]=maindata[key]
}

   }

   localStorage.removeItem('reg_data')
   localStorage.setItem('reg_data', JSON.stringify(obj))
        
          
     
     let payload = {
     
         
         "emailid": emailid,
         "mobileno": mobileno,
         "aadhar":aadharno.substring(8,12),
         "ifsc":ifsc,
         "type":"0"
     }
     this.setState({islodding:true})
     try {
         const digilock = await httpRequest({
             endPoint: `/setu/account_aggregator/create_consent`,
             method: "post",
             instance: "instanceTwo",
             requestBody: payload,
         });
     
        
         if(digilock.status===200)
         {
            this.setState({islodding:false})
            window.open(digilock.url,'_self')
         }
     } catch (error) {
         
     }                                                                                   
     
         }
  render() {
    const { classes } = this.props;
    return (
   
      <div> 
        <Grid className={classes.cong_card}>
        <Grid className={classes.cong_card1}>
            <div style={{display:'flex',justifyContent:'center',marginBottom:'4%'}}>
<img src={logo} alt='logo'></img>
</div>
            <Typography className={classes.texthear}>Account Aggregator</Typography>
            <Typography className={classes.texthear1}>As per the regulatory requirements, we would need six months bank statement of the applicant. Can we have your consent to digitally get the same from the bank identified by you.</Typography>
            <Grid style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:'4%'}}>
            <img src={onemoney} alt='diglocker'></img>
            </Grid>
            <Typography className={classes.texthear2}>BullForce uses onemoney to fetch your documents</Typography>
            
            <Grid style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'2%'}}>
            <img src={Secure} alt='diglocker'></img>
            <Typography className={classes.texthear3}> Your data is safe and secured with us</Typography>
            </Grid>
     
           <div>
            <Grid style={{width:'100%',display:'flex',marginTop:'2%',justifyContent:'center'}}>
            <Grid className={classes.butt_card}>
        <Button fullWidth className={classes.joinDiplomtsBtn}
       style={{ marginTop: '5px', }}
       onClick={() => this.Continue()}
   >I Agree & Continue with F&O

   </Button>

   </Grid>
  
   
   </Grid>
    {/* <Grid style={{width:'100%',display:'flex',marginTop:'2%',justifyContent:'center'}}>
     <Grid className={classes.butt_card}>
     <Button fullWidth className={classes.skipbut}
       style={{ marginTop: '5px', }}
       onClick={() =>  this.Continueskip()}
   >Skip F&O

   </Button>

   </Grid>
</Grid>*/}
</div>
   
   
   </Grid>
   </Grid>
   <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
      </div>
    )
  }
}
export default (withStyles(styles)((digilocker)));