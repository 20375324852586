import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { Grid, Typography, } from '@mui/material'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Cdsl | BullForce',
    description: 'Bullforce CDSL Rights & Obligations: Know your rights, secure your demat account, and ensure compliance with NSE, BSE, and CDSL for safe trading',
    canonical: 'https://bullforce.co/cdsl',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, cdsl, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const styles = theme => ({

    heading: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important', // h1 tags margin start, end removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',
        "@media only screen and (max-device-width: 480px)": {
            color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '20px !important',
        }


    },
    p_body: {
        textTransform: 'none !important', color: '#000000 !important', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important', lineHeight: "22px !important",
        "@media only screen and (max-device-width: 480px)": {

            color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important', lineHeight: "20px !important",
        }

    },

})

class cdsl extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div style={{ height: 'auto', padding: '0px 0px 20px 0px' }}>

                <DocumentMeta {...meta} />
                <Grid style={{ padding: '20px 0px 40px 0px' }}>
                    
                    {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                    <h1 className={classes.heading} style={{ textAlign: 'center'}} >
                    Rights and Obligations of Beneficial Owner and Depository Participant as prescribed by SEBI and Depositories General Clause
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    1. The Beneficial Owner and the Depository participant (DP) shall be bound by the provisions of the Depositories Act, 1996, SEBI (Depositories and Participants) Regulations, 1996, Rules and Regulations of Securities and Exchange Board of India (SEBI), Circulars/Notifications/Guidelines issued there under, Bye Laws and Business Rules/Operating Instructions issued by the Depositories and relevant notifications of Government Authorities as may be in force from time to time.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    2. The DP shall open/activate demat account of a beneficial owner in the depository system only after receipt of complete Account opening form, KYC and supporting documents as specified by SEBI from time to time.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Beneficial Owner information
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    3. The DP shall maintain all the details of the beneficial owner(s) as mentioned in the account opening form, supporting documents submitted by them and/or any other information pertaining to the beneficial owner confidentially and shall not disclose the same to any person except as required by any statutory, legal or regulatory authority in this regard.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    4. The Beneficial Owner shall immediately notify the DP in writing, if there is any change in details provided in the account opening form as submitted to the DP at the time of opening the demat account or furnished to the DP from time to time. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                        Fees/Charges/Tariff
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    5. The Beneficial Owner shall pay such charges to the DP for the purpose of holding and transfer of securities in dematerialized form and for availing depository services as may be agreed to from time to time between the DP and the Beneficial Owner as set out in the Tariff Sheet provided by the DP. It may be informed to the Beneficial Owner that "no charges are payable for opening of demat accounts”.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    6. In case of Basic Services Demat Accounts, the DP shall adhere to the charge structure as laid down under the relevant SEBI and/or Depository circulars/directions/notifications issued from time to time.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    7. The DP shall not increase any charges/tariff agreed upon unless it has given a notice in writing of not less than thirty days to the Beneficial Owner regarding the same. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Dematerialization
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    8. The Beneficial Owner shall have the right to get the securities, which have been admitted on the Depositories, dematerialized in the form and manner laid down under the Bye Laws, Business Rules and Operating Instructions of the depositories. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Separate Accounts 
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    9. The DP shall open separate accounts in the name of each of the beneficial owners and securities of each beneficial owner shall be segregated and shall not be mixed up with the securities of other beneficial owners and/or DP’s own securities held in dematerialized form.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    10. The DP shall not facilitate the Beneficial Owner to create or permit any pledge and /or hypothecation or any other interest or encumbrance over all or any of such securities submitted for dematerialization and/or held in demat account except in the form and manner prescribed in the Depositories Act, 1996, SEBI (Depositories and Participants) Regulations, 1996 and Bye-Laws/Operating Instructions/Business Rules of the Depositories. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Transfer of Securities
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    11. The DP shall effect transfer to and from the demat accounts of the Beneficial Owner only on the basis of an order, instruction, direction or mandate duly authorized by the Beneficial Owner and the DP shall maintain the original documents and the audit trail of such authorizations.
                    </Typography>


                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    12. The Beneficial Owner reserves the right to give standing instructions with regard to the crediting of securities in his demat account and the DP shall act according to such instructions. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Statement of account
                    </h1>


                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    13. The DP shall provide statements of accounts to the beneficial owner in such form and manner and at such time as agreed with the Beneficial Owner and as specified by SEBI/depository in this regard.
                    </Typography>


                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    14. However, if there is no transaction in the demat account, or if the balance has become Nil during the year, the DP shall send one physical statement of holding annually to such BOs and shall resume sending the transaction statement as and when there is a transaction in the account.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       15. The DP may provide the services of issuing the statement of demat accounts in an electronic mode if the Beneficial Owner so desires. The DP will furnish to the Beneficial Owner the statement of demat accounts under its digital signature, as governed under the Information Technology Act, 2000. However if the DP does not have the facility of providing the statement of demat account in the electronic mode, then the Participant shall be obliged to forward the statement of demat accounts in physical form.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       16. In case of Basic Services Demat Accounts, the DP shall send the transaction statements as mandated by SEBI and/or Depository from time to time. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Manner of Closure of Demat account
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       17. The DP shall have the right to close the demat account of the Beneficial Owner, for any reasons whatsoever, provided the DP has given a notice in writing of not less than thirty days to the Beneficial Owner as well as to the Depository. Similarly, the Beneficial Owner shall have the right to close his/her demat account held with the DP provided no charges are payable by him/her to the DP. In such an event, the Beneficial Owner shall Page 3 of 4 specify whether the balances in their demat account should be transferred to another demat account of the Beneficial Owner held with another DP or to rematerialize the security balances held.


                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       18. Based on the instructions of the Beneficial Owner, the DP shall initiate the procedure for transferring such security balances or rematerialize such security balances within a period of thirty days as per procedure specified from time to time by the depository. Provided further, closure of demat account shall not affect the rights, liabilities and obligations of either the Beneficial Owner or the DP and shall continue to bind the parties to their satisfactory completion. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Default in payment of charges
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       19. In event of Beneficial Owner committing a default in the payment of any amount provided in Clause 5 & 6 within a period of thirty days from the date of demand, without prejudice to the right of the DP to close the demat account of the Beneficial Owner, the DP may charge interest at a rate as specified by the Depository from time to time for the period of such default.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       20. In case the Beneficial Owner has failed to make the payment of any of the amounts as provided in Clause 5&6 specified above, the DP after giving two days notice to the Beneficial Owner shall have the right to stop processing of instructions of the Beneficial Owner till such time he makes the payment along with interest, if any. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Liability of the Depository
                    </h1>


                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       21. As per Section 16 of Depositories Act, 1996, 1. Without prejudice to the provisions of any other law for the time being in force, any loss caused to the beneficial owner due to the negligence of the depository or the participant, the depository shall indemnify such beneficial owner. 2. Where the loss due to the negligence of the participant under Clause (1) above, is indemnified by the depository, the depository shall have the right to recover the same from such participant. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Freezing/ Defreezing of accounts
                    </h1>


                    
                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    22. The Beneficial Owner may exercise the right to freeze/defreeze his/her demat account maintained with the DP in accordance with the procedure and subject to the restrictions laid down under the Bye Laws and Business Rules/Operating Instructions.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    23. The DP or the Depository shall have the right to freeze/defreeze the accounts of the Beneficial Owners on receipt of instructions received from any regulator or court or any statutory authority. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Redressal of Investor grievance
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       24. The DP shall redress all grievances of the Beneficial Owner against the DP within a period of thirty days from the date of receipt of the complaint.
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Authorized representative
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       25. If the Beneficial Owner is a body corporate or a legal entity, it shall, along with the account opening form, furnish to the DP, a list of officials authorized by it, who shall represent and interact on its behalf with the Participant. Any change in such list including additions, deletions or alterations thereto shall be forthwith communicated to the Participant. 
                    </Typography>

                    <h1 className={classes.heading} style={{ padding: '20px 0px 0px 0px' }} >
                    Law and Jurisdiction
                    </h1>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       26. In addition to the specific rights set out in this document, the DP and the Beneficial owner shall be entitled to exercise any other rights which the DP or the Beneficial Owner may have under the Rules, Bye Laws and Regulations of the respective Depository in which the demat account is opened and circulars/notices issued there under or Rules and Regulations of SEBI.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    27. The provisions of this document shall always be subject to Government notification, any rules, regulations, guidelines and circulars/ notices issued by SEBI and Rules, Regulations and Bye-laws of the relevant Depository, where the Beneficial Owner maintains his/ her account, that may be in force from time to time.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    28. The Beneficial Owner and the DP shall abide by the arbitration and conciliation procedure prescribed under the Bye-laws of the depository and that such procedure shall be applicable to any disputes between the DP and the Beneficial Owner.
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                       29. Words and expressions which are used in this document but which are not defined herein shall unless the context otherwise requires, have the same meanings as assigned thereto in the Rules, Bye-laws and Regulations and circulars/notices issued there under by the depository and /or SEBI
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    30. Any changes in the rights and obligations which are specified by SEBI/Depositories shall also be brought to the notice of the clients at once. 
                    </Typography>

                    <Typography className={classes.p_body} style={{ padding: '10px 0px 0px 0px' }} >
                    31. If the rights and obligations of the parties hereto are altered by virtue of change in Rules and regulations of SEBI or Bye-laws, Rules and Regulations of the relevant Depository, where the Beneficial Owner maintains his/her account, such changes shall be deemed to have been incorporated herein in modification of the rights and obligations of the parties mentioned in this document.
                    </Typography>


                </Grid>
            </div>
        )
    }
}
export default (withStyles(styles)((cdsl)));