
import React, { Component } from 'react'
import { withStyles, styled } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import { Typography, Grid } from '@mui/material';
import moment from 'moment';
const styles = theme => ({
    main_tit: {


        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "32px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",

        fontFamily: "cardiuma-M !important"

    },
    tit: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",

        fontFamily: "cardiuma-M !important"
    },
    tit_label: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",

        fontFamily: "cardiuma-regular !important",

    },

    tit_label_date: {
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",

        fontFamily: "cardiuma-Bold !important",
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",
        }
    },
    tit_label_cat: {

        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "12px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",
        textAlign: 'center',
        fontFamily: "cardiuma-regular !important",
        padding: '0px 5px',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: "12px !important",
            padding: '0px 0px',
        }
    },
    tit_label1: {

        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "16px !important",
        lineHeight: "28px !important",
        color: "#333333 !important",


        fontFamily: "cardiuma-M !important",


    },
    issuecard1:{
        width: '90%' ,
        "@media only screen and (max-device-width: 480px)": {
            width: '100%' ,
        }

    },
    mainiss_card:{
        width: '100%', marginTop: '25px', height: '100vh',
        "@media only screen and (max-device-width: 480px)": {
            marginTop: '5px', height: 'quto',
        }

    },
    iss_card1:{
        width: '20%', marginLeft: '1%' ,
        "@media only screen and (max-device-width: 480px)": {
            width: '30%', marginLeft: '1%' ,
        }
   },
    iss_card2:{
         width: '78%', marginLeft: '2%',
         "@media only screen and (max-device-width: 480px)": {
            width: '68%', marginLeft: '1%' ,
        }
    }
});

class issue_disclosures extends Component {
    constructor(props) {
        super(props)


        this.state = {
            userdata: []
        }



    }

    componentDidMount = async () => {
        let userdata1 = []
        try {
            const cir_data = await httpRequest({
                endPoint: `api/disclosures/list`,
                method: "get",
                instance: "instanceOne",

            });



            const re = cir_data.data.map(data => {





                userdata1.push({
                    date: moment(data.date).format("DD/MM/YYYY"),
                    category: data.category,
                    title: data.title,
                    description: data.description

                })

            })

        } catch (error) {

        }
        this.setState({ userdata: userdata1 })






    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.mainiss_card}>


                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <Grid className={classes.issuecard1}>
                        {this.state.userdata.map((label, index) => {


                            return (

                                <Grid style={{ width: '100%', display: 'flex', background: '#ffffff', alignItems: 'center', padding: '24px 2px', borderTop: '1.5px solid #DFE0EB' }}>
                                      <Grid  className={classes.iss_card1}>
                                        <Typography className={classes.tit_label_date} style={{ textAlign: 'center' }}>{label.date}</Typography>
                                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                                            <Grid style={{ width: '160px', padding: '3px', background: '#F2F2F2', borderRadius: '4px', marginTop: '5px' }}>
                                                <Typography className={classes.tit_label_cat}>{label.category}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid  className={classes.iss_card2}>
                                        <Typography className={classes.tit_label1}



                                        >{label.title}</Typography>

                                        <Typography className={classes.tit_label}



                                        >{label.description}</Typography>
                                    </Grid>

                                </Grid>

                            )
                        })}
                    </Grid>
                </Grid>
            </div >
        )
    }
}
export default (withStyles(styles)((issue_disclosures)));