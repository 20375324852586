import React, { Component } from 'react'

import {
    Typography, Grid, Radio, RadioGroup, FormControlLabel, TextField
} from '@mui/material'
import { withStyles, styled } from "@mui/styles";
const styles = theme => ({

    bmaintit :{
        color: '#3E3E3E',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '32px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
    },
    
    maincard: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        padding: '100px 0px 0px 0px',
    },
    
    subcard: {
        width: '23%',
        padding: '24px',
        borderRadius: '8px',
        background: '#ffffff',
        "@media only screen and (max-device-width: 480px)": {
            
            width: '100%',
           
        }
    },
    
    subcard1: {
        width: '40%',
        padding: '24px',
        borderRadius: '8px',
        background: '#ffffff',
        "@media only screen and (max-device-width: 480px)": {
            
            width: '100%',
           
        }
    },
    
    subcard2: {
        width: '45%',
        padding: '24px',
        borderRadius: '8px',
        background: '#ffffff',
    },
    
    subtit: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-M !important',
        fontSize: '16px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '18px',
        /* 112.5% */
    },
    
    subtit1: {
        color: '#999999',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        /* 200% */
    },
    
    bamountcard :{
        borderRadius: '4px',
        border: '1px solid #999999',
        background: '#FFFFFF',
        padding: '6px',
        display: 'flex',
        height: '32px',
        justifyContent: 'flex-start',
    },
    
    bamount: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        fontStyle: 'normal',
        textAlign: 'left',
        fontWeight: '400',
    
        /* 166.667% */
    },
    
    bopttxt :{
        color: '#3E3E3E',
        fontFamily: 'cardiuma-M !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        /* 142.857% */
    },
    
    bsubtit1: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',
        /* 266.667% */
    },
    
    bsubtit2: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-regular !important',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',
        textAlign: 'right',
        /* 266.667% */
    },
    
    nettit: {
        color: '#3E3E3E',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        /* 142.857% */
    },
    
    netamt: {
        color: '#21B12F',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        /* 142.857% */
    },
    
    netamt1: {
        color: '#F74542',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '14px !important',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
        /* 142.857% */
    },
    card:{
        width: '100%', display: 'flex', justifyContent: 'space-around', marginBottom: '50px',
        "@media only screen and (max-device-width: 480px)": {
            
            width: '100%',display: 'block', justifyContent: 'center'
           
        }
    }


})


const CssTextField = styled(TextField)({
    borderRadius: '8px',

    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

class currency_main extends Component {
    constructor(props) {
        super(props)


        this.state = {
            b1: 49.2525,
            s1: 49.2725,
            q1: 1,
            brokerage_buy1: 0,
            turnover1: 0,
            exc_trans_charge1: 0,
            stt_total1: 0,
            sebi_charges1: 0,

            gst1: 0,
            opt1: "nse1",
            opt2: "nse2",

            brokerage_buy2: 0,
            st2: 60.75,
            b2: 0.0625,
            s2: 0.0675,
            q2: 1,

            nse2: true,
            bse2: false,

            b3: 1000,
            s3: 1100,
            q3: 400,
            nse3: true,
            bse3: false,
            brokerage_buy3: 0,
            b4: 100,
            s4: 110,
            q4: 400,
            nse4: true,
            bse4: false,
            brokerage_buy4: 0,

        }



    }
    componentDidMount = async () => {
        this.equity()
        this.delivery_equity()

    }
    async handle1(e) {
        this.state.opt1 = e.target.value
        this.equity()
    };
    async handle2(e) {
        this.state.opt2 = e.target.value
        this.delivery_equity()
    };
    eb1(e) {
        this.state.b1 = e.target.value
        this.equity()
    }
    es1(e) {
        this.state.s1 = e.target.value
        this.equity()
    }

    eq1(e) {
        this.state.q1 = e.target.value
        this.equity()
    }

    est2(e) {
        this.state.st2 = e.target.value
        this.delivery_equity()
    }
    eb2(e) {
        this.state.b2 = e.target.value
        this.delivery_equity()
    }
    es2(e) {
        this.state.s2 = e.target.value
        this.delivery_equity()
    }

    eq2(e) {
        this.state.q2 = e.target.value
        this.delivery_equity()

    }



    equity() {

        let { nse1, bse1 } = this.state
        let b1 = this.state.b1
        let s1 = this.state.s1
        let q1 = this.state.q1


        let turnover = parseFloat(b1) + parseFloat(s1)
        var brokerage_buy = ((b1 * q1 * 1000 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(b1 * q1 * 1000 * 0.0003).toFixed(2));
        var brokerage_sell = ((s1 * q1 * 1000 * 0.0003) > 20) ? 20 : parseFloat(parseFloat(s1 * q1 * 1000 * 0.0003).toFixed(2));
        var brokerage = parseFloat(parseFloat(brokerage_buy + brokerage_sell).toFixed(2));


        turnover = (parseFloat(turnover) * parseFloat(q1) * 1000).toFixed(2)
        let exc_trans_charge = 0
        let nse_ipft = 0

        if (this.state.opt1 === "nse1") {
            exc_trans_charge = parseFloat(parseFloat(0.000009 * turnover).toFixed(2))
            nse_ipft = parseFloat(parseFloat(0.0000005 * turnover).toFixed(2))
        } else {
            exc_trans_charge = parseFloat(parseFloat(0.0000025 * turnover).toFixed(2))
            nse_ipft = 0
        }

        let stt_total1 = 0
        stt_total1 = Math.round(parseFloat(parseFloat((s1 * q1) * 0.00025).toFixed(2)));
        let sebi_charges = 0
        sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        let gst = 0
        gst = ((parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(sebi_charges)) * 0.18).toFixed(2)

        exc_trans_charge = (exc_trans_charge + nse_ipft).toFixed(2)
        let stamp_charges = Math.round((parseFloat(b1) * parseFloat(q1) * 1000 * 0.000001).toFixed(2));


        let total_tax = 0

        total_tax = (parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(gst) + parseFloat(sebi_charges) + parseFloat(stamp_charges)).toFixed(2);
        let breakeven = 0
        breakeven = parseFloat(parseFloat(total_tax / (q1 * 1000)).toFixed(2));
        breakeven = isNaN(breakeven) ? 0 : breakeven
        var pips = Math.ceil(parseFloat(breakeven / 0.0025));
        let net_profit = 0
        net_profit = parseFloat(parseFloat(((s1 - b1) * q1 * 1000) - total_tax).toFixed(2));
        this.setState({ pips1: pips, brokerage_buy1: brokerage, turnover1: turnover, stt_total1: stt_total1, sebi_charges1: sebi_charges })
        this.setState({ exc_trans_charge1: exc_trans_charge, gst1: gst, stamp_charges1: stamp_charges, total_tax1: total_tax, breakeven1: breakeven, net_profit1: net_profit })
    }
    delivery_equity() {
        let { nse2, bse2 } = this.state
        let b2 = this.state.b2
        let s2 = this.state.s2
        let q2 = this.state.q2
        let st2 = this.state.st2
        var turnover = parseFloat(parseFloat((b2 + s2) * q2 * 1000).toFixed(2));

        var total_turnover = parseFloat(parseFloat((b2 + st2) * q2 * 1000) + parseFloat((s2 + st2) * q2 * 1000)).toFixed(2);
        var brokerage_buy = 0;
        var brokerage_sell = 0;

        if (b2 > 0) {
            brokerage_buy = (((b2 + st2) * q2 * 1000 * 0.0003) > 20) ? 20 : parseFloat(parseFloat((b2 + st2) * q2 * 1000 * 0.0003).toFixed(2));
        }
        if (s2 > 0) {
            brokerage_sell = (((s2 + st2) * q2 * 1000 * 0.0003) > 20) ? 20 : parseFloat(parseFloat((s2 + st2) * q2 * 1000 * 0.0003).toFixed(2));
        }
        var brokerage = parseFloat(parseFloat(brokerage_buy + brokerage_sell).toFixed(2));
        var sebi_charges = parseFloat(parseFloat(turnover * 0.000001).toFixed(2));
        let exc_trans_charge = 0
        let nse_ipft = 0

        if (this.state.opt2 === "nse2") {
            exc_trans_charge = parseFloat(parseFloat(0.00035 * turnover).toFixed(2))
            nse_ipft = parseFloat(parseFloat(0.00002 * turnover).toFixed(2))
        } else {
            exc_trans_charge = parseFloat(parseFloat(0.00001 * turnover).toFixed(2))
            nse_ipft = 0
        }
        var gst = parseFloat(parseFloat(0.18 * (brokerage + exc_trans_charge + sebi_charges)).toFixed(2));
        exc_trans_charge = parseFloat(parseFloat(exc_trans_charge + nse_ipft).toFixed(2));
        var stamp_charges = Math.round(parseFloat(parseFloat(b2 * q2 * 1000 * 0.000001).toFixed(2)));
        var total_tax = (parseFloat(brokerage) + parseFloat(exc_trans_charge) + parseFloat(gst) + parseFloat(sebi_charges) + parseFloat(stamp_charges)).toFixed(2)
        var breakeven = parseFloat(parseFloat(total_tax / (q2 * 1000)).toFixed(4));
        breakeven = isNaN(breakeven) ? 0 : breakeven

        var pips = Math.ceil(parseFloat(breakeven / 0.0025));
        var net_profit = parseFloat(parseFloat(((s2 - b2) * q2 * 1000) - total_tax).toFixed(2));


        this.setState({ brokerage_buy2: brokerage, turnover2: turnover, gst2: gst, exc_trans_charge2: exc_trans_charge, stamp_charges2: stamp_charges })
        this.setState({ total_tax2: total_tax, dp: 0, sebi_charges2: 0, breakeven2: breakeven, pips2: pips, net_profit2: net_profit })

    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.card}>
                <Grid className={classes.subcard1}>
                    <Typography className={classes.subtit}>Futures</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}

                                onClick={(e) => this.equity()}
                            >
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <Typography className={classes.subtit1}>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.b1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb1(e)}
                            />

                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.s1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es1(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '30%' }}>
                            <CssTextField
                                value={this.state.q1}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq1(e)}
                            />
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            value={this.state.opt1}
                            onChange={e =>
                                this.handle1(e)


                            }



                        >
                            <FormControlLabel value="nse1" control={<Radio
                                disableripple
                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>NSE</Typography>} />
                            <FormControlLabel value="bse1" control={<Radio

                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>BSE</Typography>} />







                        </RadioGroup>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.turnover1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.brokerage_buy1}</Typography>
                        </Grid>

                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.exc_trans_charge1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>0</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.gst1}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.sebi_charges1}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stamp_charges1}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.total_tax1}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.breakeven1}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Pips to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.pips1}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.nettit}>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit1 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit1}</Typography>
                        </Grid>


                    </Grid>






                </Grid>
                <Grid className={classes.subcard1} >
                    <Typography className={classes.subtit}>Options</Typography>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '25%' }}>
                            <Typography className={classes.subtit1}>
                                STRIKE PRICE
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '22%' }}>
                            <Typography className={classes.subtit1}>
                                BUY
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '22%' }}>
                            <Typography className={classes.subtit1}>
                                SELL
                            </Typography>
                        </Grid>
                        <Grid style={{ width: '20%' }}>
                            <Typography className={classes.subtit1}>
                                QUANTITY
                            </Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
                        <Grid style={{ width: '25%' }}>
                            <CssTextField
                                value={this.state.st2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.est2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '22%' }}>
                            <CssTextField
                                value={this.state.b2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eb2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '22%' }}>
                            <CssTextField
                                value={this.state.s2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.es2(e)}
                            />
                        </Grid>
                        <Grid style={{ width: '20%' }}>
                            <CssTextField
                                value={this.state.q2}
                                autoFocus
                                inputProps={{
                                    style: {
                                        height: "1px",
                                        fontSize: 14,
                                        fontFamily: 'cardiuma-Bold !important',
                                        backgroundColor: '#FFF',
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                        color: '#3E3E3E',

                                    },
                                }}
                                onChange={(e) => this.eq2(e)}
                            />
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            value={this.state.opt2}
                            onChange={e =>
                                this.handle2(e)


                            }



                        >
                            <FormControlLabel value="nse2" control={<Radio
                                disableripple
                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>NSE</Typography>} />
                            <FormControlLabel value="bse2" control={<Radio

                                size='small'
                                sx={{
                                    color: "#093265",
                                    "&.Mui-checked": {
                                        position: "relative",
                                        "&::before": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            color: "yellow"
                                        },
                                        "&::after": {
                                            content: '""',
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#093265",

                                            borderColor: "#ffffff",
                                            color: "yellow"
                                        },

                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                                        {
                                            stroke: '#ffffff',
                                            strokeWidth: 0.5,
                                        },
                                    }

                                }}
                            />} label={<Typography className={classes.bopttxt}>BSE</Typography>} />







                        </RadioGroup>
                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Turnover</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.turnover2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Brokerage</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.brokerage_buy2}</Typography>
                        </Grid>

                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Exchange txn charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.exc_trans_charge2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Clearing charge</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.dp}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>GST</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.gst2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>SEBI charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.sebi_charges2}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Stamp duty</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.stamp_charges2}</Typography>
                        </Grid>


                    </Grid>

                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Total tax and charges</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.total_tax2}</Typography>
                        </Grid>


                    </Grid>


                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.bsubtit1}>Points to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.breakeven2}</Typography>
                        </Grid>


                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '15px' }}>
                        <Grid style={{ width: '50%' }}>

                            <Typography className={classes.bsubtit1}>Pips to breakeven</Typography>
                        </Grid>
                        <Grid style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={classes.bsubtit2}>{this.state.pips2}</Typography>
                        </Grid>

                    </Grid>
                    <Grid style={{ width: '100%', display: 'flex', marginTop: '30px' }}>
                        <Grid style={{ width: '60%' }}>

                            <Typography className={classes.nettit}>Net P&L</Typography>
                        </Grid>
                        <Grid style={{ width: '40%', display: 'flex', justifyContent: 'flex-end' }}>
                            <Typography className={this.state.net_profit2 > 0 ? 'netamt' : 'netamt1'}>{this.state.net_profit2}</Typography>
                        </Grid>


                    </Grid>


                </Grid>


            </div>
        )
    }
}
export default (withStyles(styles)((currency_main)));