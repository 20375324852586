import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import OtpInput from "react-otp-input";
import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import * as CryptoJS from 'crypto-js';
import { Close } from '@mui/icons-material';
import { inputLabelClasses } from "@mui/material/InputLabel";
import {
    Typography, Grid, Button,Modal,Box,MenuItem,TextField,FormControl,FormHelperText,InputLabel,Select,
    Snackbar, SnackbarContent, IconButton
} from '@mui/material'
import validator from 'validator';
import verfiy from '../../../ui-assets/verfiy.svg' //added 1/07/24 Store Store. Successful image Two variable 
import unverfiy from '../../../ui-assets/unverfiy.svg' //added 1/07/24 Store unsuccessful image to variable 

const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
  
    nominee_card: {
    marginTop: '5%',
        "@media only screen and (max-device-width: 480px)": {

            width: '100%', marginLeft: '0px',



        }
    },
    nominee_card_but: {
        width: '100%', display: 'flex',justifyContent:'center',
        "@media only screen and (max-device-width: 480px)": {

            width: '100%', marginLeft: '0px', justifyContent: 'center',



        }

    },
   
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },



   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },
   page3card: {
    width: '100%',
    
    background: '#1B2636',


    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        padding: '52px 16px',
        marginLeft: '0%',

    }
},
nominee_card_main: {
    width: '100%', display: 'flex', marginTop: '2%', justifyContent: 'center',
    "@media only screen and (max-device-width: 480px)": {
        display: 'inline', width: '10%',
    },
},
nomni_but: {

    background: "#F74542 !important",
    padding: "10px 24px !important",
    borderRadius: "24px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important",
    width: '80%',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',

        marginLeft: '0%',

    },

   


},
//Disable button css. 
nomni_but_dis: {

    background: "#999999 !important",
    padding: "10px 24px !important",
    borderRadius: "24px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important",
    width: '80%',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',

        marginLeft: '0%',

    },

   


},
})

class nominee extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            errtype:0,
            nominee_aadhaar:'',
             nonreal:'',
              nomineename:'',
            pincode:'',//added 19/07/24 To initialise pin code? 
            contbut:true,//added 19/07/24 To initialise continue button status 
            pincodeverify_st:2,//added 19/07/24 To initialise image On On Successful and unsuccessful 
        }



    }

    Getnominee_aadhaar(e) {
       
        if (e.target.value.length <= 12)
            this.setState({ nominee_aadhaar: e.target.value });
   
}

closeContactModal() {
    this.setState({ showerr: false })
}
componentDidMount = async () => {
    window.scrollTo(0, 0);
   
}
//And then the pin code. To verify the pin code 
Getcity = async (e) => {
//Add it to Clear pin code. On.22/07/24

this.setState({errtype:0})//added on 30/08/2024 to clear error message
    if(parseInt(e.target.value.length)===0)
    {
        this.setState({pincode:""})
    }
    if(validator.isNumeric(e.target.value))  //To To cheque Pin code is number or not. 
    {

      
        if(parseInt(e.target.value.length)<=6)
        {
        this.setState({pincode:e.target.value})
        }

        if(e.target.value.length===6) //Pin code 6 digit. To verify Pin code. 
            {

let payload={
    "pincode":e.target.value
}
     try {
    const cityname = await httpRequest({
        endPoint: `/api/nominee/pincode`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });

    this.setState({ islodding: false })
    if (cityname === "API_ERROR") {
        this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
    }
    if (cityname.status === 200) {//If pin code is correct. Then To show successful image End Enable Continue button. 
this.setState({contbut:false,pincodeverify_st:1})
    
    }  if (cityname.status === 201) {//A pin code is wrong. Then display unsuccessful image. and error message 
        this.setState({pincodeverify_st:0,   errtype: 9, errorMsg: "Please enter valid pin code" })
            
            } 
} catch (error) {

    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

}

            }

}
}
Continue10 = async () => {
    let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
let citizen=rd2.citizen
    const { nominee_aadhaar, nonreal, nomineename, aadharno,pincode } = this.state //To add Pin code to the payload. 
    let isok = 0

    console.log(nonreal)
    if (!nomineename.length && isok === 0) {
        isok = 1
        this.setState({ errtype: 5, errorMsg: "Enter nominee name" })

    }
    if (!nonreal.length && isok === 0) {
        isok = 1
        this.setState({ errtype: 6, errorMsg: "Enter nominee relationship" })

    }

    if (!pincode.length && isok === 0) {
        isok = 1
        this.setState({ errtype: 6, errorMsg: "Enter Pincode" })

    }


    if (!nominee_aadhaar.length && isok === 0) {
        isok = 1
        this.setState({ errtype: 7, errorMsg: "Enter nominee aadhar Number" })

    }
    if (nominee_aadhaar.length < 12 && isok === 0) {
        isok = 1
        this.setState({ errtype: 7, errorMsg: "Enter vailed nominee aadhar Number" })

    }


    if (nominee_aadhaar === aadharno && isok === 0) {
        isok = 1
        this.setState({ errtype: 7, errorMsg: "Enter vailed nominee aadhar Number" })

    }
    if (isok === 0) {
        this.setState({ islodding: true })
        let payload = {
            "nomineename": nomineename,
            "nonreal": nonreal,
            "nominee_aadhaar": CryptoJS.AES.encrypt(nominee_aadhaar, "96358214256984120").toString(),

            "mobileno": mobileno,
            "emailid": emailid,
            "pincode":pincode //Add a pin code to the payload. 
        }

       console.log(payload)

        try {
            const otp = await httpRequest({
                endPoint: `/api/registration/nominee`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });

            this.setState({ islodding: false })
            if (otp === "API_ERROR") {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
            if (otp.status === 200) {

                    window.ChangePageNo (14)
            
              
            
            

            } else {
                this.setState({ showerr: true, errorMsg: otp.message, mysnack: snackeror.myerror })
            }
        } catch (error) {

            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }

    }

}

  render() {
    const { classes } = this.props;
    return (
      <div>    <Grid className={classes.page3card}  >
      <Typography style={{ color: '#BCD171', fontFamily: 'cardiuma-M', fontSize: '24px' }}>Add nominee</Typography>
      <Typography style={{ marginTop: '2%', color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Select below option</Typography>

      <Grid className={classes.nominee_card}>
          <Grid  >

              <FormControl style={{ width: '100%' }} variant="standard">
                  <TextField size="small" variant="standard"

                      fullWidth
                      sx={{
                          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                          '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                      }}
                      InputLabelProps={{
                        sx: {
                          // set the color of the label when not shrinked
                          color: "#FFFFFF",
                          fontFamily: 'cardiuma-regular', fontSize: '14px',
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "#FFFFFF"
                          }
                        }
                    }}
                      label='Enter nominee name'
                      value={this.state.nomineename}

                      InputProps={{
                          classes: { input: classes.input },

                          style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                      }}
                      onChange={(e) => this.setState({ nomineename: e.target.value })}
                  ></TextField>
                  {this.state.errtype === 5 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }

              </FormControl>
          </Grid>
      </Grid>
      <Grid className={classes.nominee_card} >
          <FormControl variant="standard" sx={{
              minWidth: '100%', '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
              '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
          }}>
              <InputLabel id="demo-simple-select-standard-label"

                  style={{ color: this.state.errtype === 6 ? "#F74542" : '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '12px' }}



              >Nominee relation</InputLabel>
              <Select
                  id="demo-simple-select-standard-label"

                  values={this.state.nonreal}
                  variant="standard"
                  label="ajdklajdlkjkla"
                  fullWidth

                  style={{ color: '#ffffff' }}

                  onChange={e => {
                      this.setState({ errtype: 0, errorMsg: "", nonreal: e.target.value });


                  }}
              >


                  <MenuItem value={'Father'}>Father</MenuItem>
                  <MenuItem value={'Mother'}>Mother</MenuItem>
                  <MenuItem value={'Spouse'}>Spouse</MenuItem>
                  <MenuItem value={'Daughter'}>Daughter</MenuItem>
                  <MenuItem value={'Son'}>Son</MenuItem>
                  <MenuItem value={'Brother'}>Brother</MenuItem>
                  <MenuItem value={'Sister'}>Sister</MenuItem>
                  <MenuItem value={'Grand-Son'}>Grand-Son</MenuItem>
                  <MenuItem value={'Grand-Daughter'}>Grand-Daughter</MenuItem>
                  <MenuItem value={'Grand-Father'}>Grand-Father</MenuItem>
                  <MenuItem value={'Grand-Mother'}>Grand-Mother</MenuItem>
                  <MenuItem value={'Not Provided'}>Not Provided</MenuItem>
              </Select>
              {this.state.errtype === 6 &&
                  <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
              }

          </FormControl>
      </Grid>
      <Grid className={classes.nominee_card}>
          <Grid  >
              <FormControl style={{ width: '100%' }} variant="standard">

                  <TextField size="small" variant="standard"
                      fullWidth
                      type='number'
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                          '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                      }}
                      InputLabelProps={{
                        sx: {
                          // set the color of the label when not shrinked
                          color: "#FFFFFF",
                          fontFamily: 'cardiuma-regular', fontSize: '14px',
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "#FFFFFF"
                          }
                        }
                    }}
                      label='Nominee aadhaar No'

                      value={this.state.nominee_aadhaar}
                      InputProps={{

                          classes: { input: classes.input },
                          style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                      }}
                      onChange={(e) => this.Getnominee_aadhaar(e)}
                  ></TextField>
                  {this.state.errtype === 7 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
              </FormControl>
          </Grid>
      </Grid>
      {/* Pin code input field is added  */}
      <Grid className={classes.nominee_card}>
          <Grid  style={{display:'flex'}}>

              <FormControl style={{ width: '100%' }} variant="standard">
                  <TextField size="small" variant="standard"
type= {window.innerWidth<600 ? "number":"text"}
                      fullWidth
                      sx={{
                          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                          '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                      }}
                      InputLabelProps={{
                        sx: {
                          // set the color of the label when not shrinked
                          color: "#FFFFFF",
                          fontFamily: 'cardiuma-regular', fontSize: '14px',
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "#FFFFFF"
                          }
                        }
                    }}
                      label='Enter Pincode'
                      value={this.state.pincode}

                      InputProps={{
                          classes: { input: classes.input },

                          style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                      }}
                      onChange={(e) => this.Getcity(e)}
                  ></TextField>
                  {this.state.errtype === 9 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }

              </FormControl>
              {this.state.pincodeverify_st !== 2 &&
                  <Grid style={{ width: '10%', marginLeft: '5px', marginTop: '10px' }}>
                      <img alt='bank' style={{ width: '90%', height: '90%' }} src={this.state.pincodeverify_st === 1 ? verfiy : this.state.pincodeverify_st === 0 ? unverfiy : ''}></img>
                  </Grid>
              }
          </Grid>
      </Grid>{/*Pin code text field. */}
      <Grid className={classes.nominee_card_main} >
{/* To enable and disable Continue button. */}
          <Grid className={classes.nominee_card_but} >

              <Button 
              
              disabled={this.state.contbut}
              className={this.state.contbut? classes.nomni_but_dis: classes.nomni_but} 
                  style={{ marginTop: '20px', }}
                  onClick={() => this.Continue10()}
              >Continue

              </Button>

          </Grid>
      </Grid>
  </Grid>
  <Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
  <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
  </div>
    )
  }
}
export default (withStyles(styles)((nominee)));