import React, { Component } from 'react'

import { withStyles, } from "@mui/styles";
import stepconmp3 from '../../../ui-assets/step_con.mp3'
import cong_bull from '../../../ui-assets/new_bull.svg'
import ani_gif from '../../../ui-assets/Bullbg.gif'

import {
    Typography, Grid, Button,Modal,Box
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};
const styles = theme => ({
  

    page1card: {
       width: '100%',
       paddingLeft: '20%',
       background: '#1B2636',
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   cong_card: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: 'auto',

    outline: 'none',
    p: 4,
    "@media only screen and (max-device-width: 480px)": {
        top: '15%',
        left: '0%',
        transform: 'none'
    }
},
cong_tit1: {
    textAlign: 'center', color: '#BCD171', fontFamily: 'cardiuma-Bold !important', fontSize: '42px !important',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '32px !important'
    },


},
cong_tit2: {
    textAlign: 'center', color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '28px !important',width:'50%',fontWeight:'400',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '18px !important',
        marginTop: '10%',
        padding:'16px',
        width:'70%'
    },
},
cong_card1: {
    marginTop: '2%', width: '100%', display: 'flex', justifyContent: 'center',

    "@media only screen and (max-device-width: 480px)": {
        display: 'none',
    },

},
cong_card2: {

    display: 'none',
    "@media only screen and (max-device-width: 480px)": {
        marginTop: '2%', width: '100%', display: 'inline'
    },

},

cong_card3: {
    width: '100%', marginTop: '1%',display:'flex',justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', marginTop: '3%'
    },
},
   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },

})
const beforeUnloadHandler = (event) => {
    // Recommended
        window.open('/')
    event.preventDefault();
    console.log("Clear navigation history. ")
    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
    
  };
 class congratulations extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
          
            audio_stepcon : new Audio(stepconmp3),
            showang:1
        }



    }
 
    componentDidMount = async () => {
        //aded on hande back button on 11/08/24
        //added on 30/08/24 to Clear navigation history. 

        window.addEventListener("beforeunload", beforeUnloadHandler);
               //end
        setTimeout(
            function() {
                this.state.audio_stepcon.play()
            }
            .bind(this),
            1000
        );
        setTimeout(
            function() {
                this.setState({showang:0})
            }
            .bind(this),
            1000
        );
        }
        
  render() {

    const { classes } = this.props;
    return (
      <div className={classes.cong_card}> 
           <Grid style={{ width: '100%', display:'flex',justifyContent:'center',position:'relative',top:0 }}>
<img style={{position:'relative'}} src={cong_bull} alt="congbull"></img>
{this.state.showang===1 && 
<img style={{position:'absolute'}} src={ani_gif} alt="congbull"></img>
  }

</Grid>
         <Grid style={{width:'100%'}}>
   
    <Grid style={{ width: '100%', marginTop: '3%' }}>
        <Typography className={classes.cong_tit1} >Congratulations!</Typography>
    </Grid>
    <Grid className={classes.cong_card3}>
        <Typography className={classes.cong_tit2} >Your account opening is under process. You will be able to invest shortly.</Typography>
    </Grid>
   
   
</Grid>
</div>
    )
  }
}
export default (withStyles(styles)((congratulations)));