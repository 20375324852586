import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import { httpRequest } from '../../../ui-utils/api'
import { inputLabelClasses } from "@mui/material/InputLabel";
import {
    Typography, Grid, Button,FormControl,TextField,FormHelperText
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
    page2card: {
        width: '100%',
      
      
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '52px 16px',
            marginLeft: '0%',

        }
    },
    occ_main_card: {
        width: '100%', padding: '5px 0px', display: 'flex', justifyContent: 'space-evenly', marginTop: '8px',
        "@media only screen and (max-device-width: 480px)": {
            display: 'inline', padding: '0%',background:'red'
        },
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    occ_card: {
        width: '43%',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%', marginTop: '15px',
        },
       
    },

    occ_but: {

        background: "#1B2636 !important",
        padding: "10px 5px !important",
        borderRadius: "8px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        border: '1px solid #DFE0EB !important',
        textTransform: "initial !important",
        fontFamily: "cardiuma-regular !important",

        "&:hover": {
            background: "#F3D45E !important",
            padding: "10px 5px !important",
            borderRadius: "8px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#0B1420 !important",
            border: '1px solid #DFE0EB !important',
            textTransform: "initial !important",
            fontFamily: "cardiuma-regular !important",

        },

    },
    occ_but_selected: {

      
            background: "#F3D45E !important",
            padding: "10px 5px !important",
            borderRadius: "8px !important",
            fontStyle: "normal !important",
            fontWeight: "500 !important",
            fontSize: "14px !important",
            lineHeight: "18px !important",
            color: "#0B1420 !important",
            border: '1px solid #DFE0EB !important',
            textTransform: "initial !important",
            fontFamily: "cardiuma-regular !important",

       

    },
    main_tit:{
        color: '#BCD171 !important', fontFamily: 'cardiuma-M !important', fontSize: '24px !important',marginLeft:'20px',
        "@media only screen and (max-device-width: 480px)": {
            marginLeft:'0px',marginTop:'3%'
        },

    },
    sub_tit:{
        color: '#ffffff !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px', marginTop: '1%',marginLeft:'20px',
        "@media only screen and (max-device-width: 480px)": {
            marginLeft:'0px'
        },
    },
    main_card:{
        marginTop:'0px',
        "@media only screen and (max-device-width: 480px)": {
            marginTop:'3%'
        },
    },
    main_card1:{
        marginTop:'20px',
        "@media only screen and (max-device-width: 480px)": {
            marginTop:'10%'
        },
    },
    main_card2:{
        marginTop:'5%',marginLeft:'20px',width:'90%',
        "@media only screen and (max-device-width: 480px)": {
            marginTop:'5%',width:'100%',marginLeft:'0px'
        },
    },
    main_card3:{
    width:'100%',display:'flex',justifyContent:'center',marginTop:'10%',
    "@media only screen and (max-device-width: 480px)": {
        marginTop:'0%',width:'100%',marginLeft:'0px'
    },
    }
})

 class marital_status extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errtype:0,
        
            emailid:'',
            fathername:'',
        sex:'',
        errorMsg:'',
        
        Occupation: '', //updaed on 12/0824 Marital status validation. 
        }



    }
    componentDidMount = async () => {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let father_name=rd2.father_name
        let gender=rd2.gender
        if(gender!==null)
        {
            this.setState({sex:gender})
        }
        if(father_name!==null)
        {
            father_name=father_name.replaceAll("S/O","")
            father_name=father_name.replaceAll(":","")
            father_name=father_name.replaceAll("S/o","")
            father_name=father_name.replaceAll("D/o","")
            father_name=father_name.replaceAll("D/O","")

        this.setState({fathername:father_name})
        }
    }
    Continue= async()=>
    {

        const {sex,Occupation,fathername}=this.state
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)

    
    
        
        let isok = 0
        if (!fathername.length && isok === 0) {
    
            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the Father Name" })
    
    
        }
        //updaed on 12/0824 Marital status validation. 
        if (!Occupation.length && isok === 0) {
    
            isok = 1
            this.setState({ errtype: 2, errorMsg: "Select Marital Status" })
    
    
        }

        
        if(isok === 0)
        {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
        let mobileno=rd2.mobileno //added on 30/07/24 Added mobile number. marital_status endpoint 
        let obj={}
        let maindata=JSON.parse( rd1)
        for (var key in maindata) {
    
    let f=0
 
    if(key==="father_name")
    {
        obj[key]=fathername
        f=1
    }
   
    
    if(f===0)
     {
            obj[key]=maindata[key]
    }
    
        }
        localStorage.removeItem('reg_data')
        localStorage.setItem('reg_data', JSON.stringify(obj))
        let payload = {
            email: emailid,
            option: Occupation,
            sex:sex,
            fathername:fathername,
            optiontype: 4,
            mobileno:mobileno //added on 30/07/24 Added mobile number. marital_status endpoint 
        }

        try {
            const stagevalue = await httpRequest({
                endPoint: `/api/registration/otheroption`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if (stagevalue.status === 200) {
            
               
                window.ChangePageNo (6.2)
            }
        } catch (error) {

        }
    }
    }
  render() {
    const { classes } = this.props;
    return (
      <div> 
        <Grid className={classes.page2card}  >
            <Grid className={classes.main_card}>
      <Typography className={classes.main_tit}>Personal Details</Typography>
      </Grid>
      
      <Typography className={classes.sub_tit}>Gender</Typography>
      
      <Grid className={classes.occ_main_card}>
          <Grid className={classes.occ_card}>
              <Button fullWidth className={this.state.sex==='M'?classes.occ_but_selected:classes.occ_but}
                  onClick={() => this.setState({sex:'M'})}
              >Male</Button>

          </Grid>
          <Grid className={classes.occ_card}>

              <Button fullWidth className={this.state.sex==='F'?classes.occ_but_selected: classes.occ_but}
                  onClick={() => this.setState({sex:'F'})}
              >Female</Button>

          </Grid>
        
      </Grid>
      <Grid className={classes.main_card1}>
      <Typography className={classes.sub_tit}>Marital Status</Typography>
      {this.state.errtype === 2 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
      </Grid>
      <Grid className={classes.occ_main_card}>
          <Grid className={classes.occ_card}>
              <Button fullWidth className={this.state.Occupation==='Single'?classes.occ_but_selected:classes.occ_but}
                  onClick={() => this.setState({Occupation:'Single',errtype:0})}
              >Single</Button>

          </Grid>
          <Grid className={classes.occ_card}>

              <Button fullWidth className={this.state.Occupation==='Married'?classes.occ_but_selected:classes.occ_but}
                  onClick={() => this.setState({Occupation:'Married',errtype:0})}
              >Married</Button>

          </Grid>
         
      </Grid>
      <Grid className={classes.occ_main_card}>

      <Grid className={classes.occ_card}>
              <Button fullWidth className={this.state.Occupation==='Widow/Widower'?classes.occ_but_selected:classes.occ_but}
                  onClick={() => this.setState({Occupation:'Widow/Widower',errtype:0})}
              >Widow/Widower</Button>

          </Grid>
          <Grid className={classes.occ_card}>

              <Button fullWidth className={this.state.Occupation==='Divorce'?classes.occ_but_selected:classes.occ_but}
                  onClick={() => this.setState({Occupation:'Divorce',errtype:0})}
              >Divorce</Button>

          </Grid>

      </Grid>
      <Grid className={classes.main_card2}>
      <FormControl style={{ width: '100%' }} variant="standard">
                  <TextField size="small" variant="standard"

                      
                      fullWidth
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                          '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                      }}
                      InputLabelProps={{
                        sx: {
                          // set the color of the label when not shrinked
                          color: "#FFFFFF",
                          fontFamily: 'cardiuma-regular', fontSize: '14px',
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "#FFFFFF"
                          }
                        }
                    }}
                      label='Father’s Name'
                      value={this.state.fathername}

                      InputProps={{
                          classes: { input: classes.input },
                          maxLength: 12,
                          style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                      }}
                      onChange={(e) => this.setState({fathername:e.target.value})}
                  ></TextField>
                  {this.state.errtype === 1 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
              </FormControl>
              </Grid>
  </Grid>
  <Grid className={classes.main_card3}>
  <Grid style={{width:'90%'}}>
      <Button
        
          fullWidth className={classes.joinDiplomtsBtn}
         
          onClick={() => this.Continue()}
      >Continue

      </Button>
      </Grid>
      </Grid>
  <Typography style={{  textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography>
  </div>
    )
  }
}
export default (withStyles(styles)((marital_status)));