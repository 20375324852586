import React, { Component } from 'react'


import diplomate_card from "../../ui-assets/diplomate_card.svg";
import teamwork from "../../ui-assets/teamwork.svg";
import teamwork_mobile from "../../ui-assets/teamwork_mobile.svg";

import diplomate_main from "../../ui-assets/diplomate_main.webp";
import diplomate_main_mobile from "../../ui-assets/diplomate_main_mobile.webp";
import v1 from "../../ui-assets/v1.svg";
import v2 from "../../ui-assets/v2.svg";
import v3 from "../../ui-assets/v3.svg";
import { withStyles, styled } from "@mui/styles";
import { Close } from '@mui/icons-material';

import { httpRequest } from '../../ui-utils/api'
import './diplomats.css'
import Fotter from '../landingpage/fotter';
import validator from 'validator'
import { Grid, Typography, Button, TextField, Snackbar, SnackbarContent, IconButton, FormControl, FormHelperText } from '@mui/material'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Diplomats | BullForce',
    description: 'Join BullForce Diplomat: Become a brand ambassador, monetize your network, and enhance your influence in trading. Get exclusive benefits and grow with BullForce.',
    canonical: 'https://bullforce.co/diplomats',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, diplomats, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const CssTextField = styled(TextField)({
    borderRadius: '8px',
    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

const styles = theme => ({

    back_img: {

        backgroundSize: "cover !important",
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center center !important",
        height: "75vh",

        backgroundImage: `url(${diplomate_main})`,
        //backgroundImage: `url(${diplomate_main}),linear-gradient(45deg, rgba(17, 169, 255, 0.67), rgba(1, 46, 101, 0.7))`,


        //backgroundBlendMode: 'multiply',
        width: "auto",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        "@media screen and (max-width:960px)": {


            backgroundImage: `url(${diplomate_main_mobile})`,

        }


    },

    teamworkcard: {
        display: 'flex', width: '100%', justifyContent: 'center', padding: '0px',

        "@media screen and (max-width:960px)": {


            display: 'none',

        }
    },

    teamworkcard1: {

        display: 'none',
        "@media screen and (max-width:960px)": {

            display: 'flex', width: '100%', justifyContent: 'center', padding: '0px',


        }
    },

    welcomecard: {
        width: '100%',
        "@media screen and (max-width:960px)": {

            width: '100%',
          

        }
    },

    welcomecard1: {
        marginTop: '25px', width: '100%', display: 'flex', justifyContent: 'center',
        "@media screen and (max-width:960px)": {


         

        }
    },
    welcome_txt:{
        textAlign: 'center', color: '#ffffff', fontSize: '58px !important', fontFamily: 'cardiuma-Bold !important', padding: '0px 150px 0px 150px',

        // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '28px !important',
            padding: '16px', color: '#111111',
        }


    },
    c_cardmain: {
        display: 'flex', width: '100%', background: '#ffffff', padding: '40px 0px 40px 40px',

        "@media screen and (max-width:960px)": {

            width: '100%', background: '#ffffff', padding: '0px',
            display: 'inline'


        }
    },


    c_cardmain_sub1: {
        width: '55%', background: '#ffffff',
        "@media screen and (max-width:960px)": {
            width: '100%', background: '#ffffff',
            padding: '0px 20px 16px 20px'
        }
    },

    c_cardmain_sub2: {
        width: '40%', marginLeft: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center',
        "@media screen and (max-width:960px)": {
            display: 'none'
        }
    },
    c_cardmain_sub3: {
        display: 'none',

        "@media screen and (max-width:960px)": {
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff',
            padding: '75px 16px 10px 16px'
        }
    },
    c_cardmain_tit: {
        color: '#363B68', fontSize: '28px', fontFamily: 'cardiuma-regular !important ',
        "@media screen and (max-width:960px)": {
            fontSize: '18px',
        }
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset",
            WebkitTextFillColor: '#1B2636'

        }
    },
    DiplomtsBtn: {
        background: "rgba(244, 213, 83, 1) !important",
        padding: "16px 24px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "rgba(51, 51, 51, 1) !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },

    submitBtn: {
        background: "#F74542 !important",
        padding: "16px 24px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#ffffff !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        "@media screen and (max-width:960px)": {
            background: "#F74542 !important",
            padding: "12px 24px !important",
            borderRadius: "38px !important",
            fontSize: "14px !important",
        }
    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    ourdip_tit: {
        textAlign: 'center', color: '#ffffff', fontSize: '68px !important', fontFamily: 'cardiuma-Bold !important',
        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
            textAlign: 'left !important'
        }
    },
    ourdip_card1: {
        width: '30%',
        "@media screen and (max-width:960px)": {
            width: '100%',
            padding: '0px 16px'
        }
    },
    ourdip_card2: {
        width: '30%',
        "@media screen and (max-width:960px)": {
            display: 'none'
        }

    },
    signupcard: {
        width: '100%', background: '#D8E2EC', height: 'auto', padding: '50px 0px 50px 0px',
        "@media screen and (max-width:960px)": {
            background: '#ffffff !important',
            padding: '0px 0px 0px 0px'
        }
    },
    signup_tit: {
        color: '#0B1420', fontSize: '32px !important', fontFamily: 'cardiuma-Bold !important',
        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '20px !important'
        }
    },
    signupinfo_card: {
        marginTop: '40px', width: '100%', display: 'flex', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
            marginTop: '1px',
        }

    },
    signupinfo_card1: {
        width: '40%', background: '#fff', borderRadius: '8px', padding: '30px 55px',
        "@media screen and (max-width:960px)": {
            width: '95%', padding: '0px 16px'
        }

    },
    dip_txt:{
        color: '#363B68', fontSize: '28px !important', fontFamily: 'cardiuma-regular !important',
        "@media screen and (max-width:960px)": {
            fontSize: '18px !important',
        }
    },
    dip_txt1:{
        color: '#363B68', fontSize: '28px !important', fontFamily: 'cardiuma-regular !important',paddingTop:'15px',
        "@media screen and (max-width:960px)": {
            fontSize: '18px !important',
        }
    },
    dip_imgcard1:{
        width: '100%', display: 'flex', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
            width: '100%', display: 'flex', justifyContent: 'flex-start',
            padding:'16px'
        }

    },
    dip_main:{
        width: '100%', background: '#111', height: 'auto', padding: '50px 0px 0px 0px',
        "@media screen and (max-width:960px)": {
            width: '100%', 
            padding:'16px'
        }
    },
    dip_main1:{
        width: '100%', display: 'flex', padding: '20px 0px 100px 0px',
        "@media screen and (max-width:960px)": {
            width: '100%', 
            padding: '0px 0px 0px 0px',
        }

    },
    dip_main2:{
    display: 'flex', width: '100%', justifyContent: 'center',
    "@media screen and (max-width:960px)": {
      display:'NOne'
    }
    },
    dip_main3:{
        display: 'None', width: '100%', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
          display:'flex',width: '100%', padding:'16px',justifyContent:'flex-start'
        }
        },
        dip_vfont:{

             color: '#00ACFE', fontSize: '22px !important', fontFamily: 'cardiuma-Bold !important'
   

        },
        dip_vfont_disp:{

            color: '#FFFFFF', fontSize: '16px !important', fontFamily: 'cardiuma-regular !important'
  

       }

});

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

class main extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            fname: '',
            lname: '',
            profession: '',
            errtype: 0,

            city: '',
            email: '',
            country: '',
            phoneno: '',
            socialmed: ''
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        window.Changemytab(1)
    }

    createAccount = async () => {
        const { fname, lname, profession, city, email, country, phoneno, socialmed } = this.state
        let isok = 0

        if (!fname.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the First Name" })

        }


        if (!phoneno.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 3, errorMsg: "Enter the Phone Number" })

        }

        if(validator.isMobilePhone(phoneno, 'en-IN') === false && isok === 0){

            isok = 1
            this.setState({ errtype: 3, errorMsg:"Enter valid Phone Number"})

        }

        if (!email.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the E-Mail" })

        }

        if (validator.isEmail(email) === false && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "E-Mail Not Vailed" })

        }

        if(!profession.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 4, errorMsg: "Enter the Profession"})
        }

        if(!socialmed.length && isok === 0 ){

            isok = 1
            this.setState({errtype: 5, errorMsg: "Enter the social media handle"})
        }



        if (isok === 0) {

            let payload = {
                fname: fname,
                lname: lname,
                profession: profession,
                city: city,
                country: country,
                phoneno: phoneno,
                email: email,
                socialmed: socialmed
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/user_form/diplomateaccount`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg: 'Your request has been submitted for review.', mysnack: snackeror.mysucc,

                        fname: '', lname: '', email: '',profession:'',socialmed:'',phoneno:''
                    })

                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror, 
                    fname: '', lname: '', email: '',profession:'',socialmed:'',phoneno:''

                })
            }

        }

    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <DocumentMeta {...meta} />
                <Grid className={classes.back_img}  >

                    <Grid style={{

                        width: '100%',
                        marginBottom: '80px'
                    }}>
                        <Grid className={classes.welcomecard}>
                            {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                            <h1 className={classes.welcome_txt}>Welcome to the world of BullForce Diplomats</h1>
                        </Grid>

                        <Grid className={classes.welcomecard1}>
                            <Button className={classes.DiplomtsBtn}

                                onClick={() => document.getElementById('signup').scrollIntoView()}
                            >Sign Up Now</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className={classes.c_cardmain}>

                    <Grid className={classes.c_cardmain_sub3}>

                        <img
                            src={diplomate_card}
                            alt="girlSitting"
                            loading="lazy"
                            height={'98%'}
                            width={'98%'}

                        />

                    </Grid>

                    <Grid className={classes.c_cardmain_sub1}>
                        <Typography className={classes.dip_txt}>BullForce diplomats is a program designed to assist our business partners to monetize the power of their social network. </Typography>
                        <Typography className={classes.dip_txt1} >The BullForce diplomats are  entitled for an array of benefits including a “BullForce Diplomat” privilege debit card from a reputed bank.</Typography>
                        <Typography className={classes.dip_txt1}>You can become a BullForce Diplomat by following few simple steps. Please fill the form and submit. One of our team member would get in touch with you and take this forward.</Typography>

                    </Grid>
                    <Grid className={classes.c_cardmain_sub2}>

                        <img
                            src={diplomate_card}
                            alt="girlSitting"
                            loading="lazy"

                        />

                    </Grid>

                </Grid>


                <Grid className={classes.dip_main}>
                    <Grid className={classes.dip_imgcard1}>
                        <h1 className={classes.ourdip_tit}>Our Diplomats say this!</h1>
                    </Grid>
                    <Grid className={classes.dip_main1}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.ourdip_card1}>
                                <img src={v1} style={{ width: '100%', }} />
                                <Typography className={classes.dip_vfont} >Vineet Patel, Bangalore</Typography>
                                <Typography className={classes.dip_vfont_disp} >This is one of the coolest program I have ever seen. This is one of the best ways to
contribute to our society by bringing more and more awareness on investment
management. Bullforce is striving hard to make this happen !</Typography>
                            </Grid>
                            <Grid className={classes.ourdip_card2}>
                                <img src={v2} style={{ width: '100%', }} />
                                <Typography className={classes.dip_vfont} >Abdul Saud, Hyderabad</Typography>
                                <Typography className={classes.dip_vfont_disp} >Am a big fan of this “diplomat program”, I really like the way in which the program has
been designed. This is working on a totally automated technology platform with very
minimum human interference and assuring highest level of transparency</Typography>
                            </Grid>
                            <Grid className={classes.ourdip_card2}>
                                <img src={v3} style={{ width: '100%' }} />
                                <Typography className={classes.dip_vfont} >Suchita Hegde, Mumbai</Typography>
                                <Typography className={classes.dip_vfont_disp} >Am truly excited to be the “diplomat” of Bullforce. This is the beginning of the new trend
which can be termed as “Social Investment”. This program is designed to benefit
everyone in the circle. The most benefited group are the investors as they have a face to
represent the brand.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid id="signup" className={classes.signupcard}>
                    <Grid className={classes.dip_main2}>
                        <h1 className={classes.signup_tit}>We need to capture following information</h1>
                    </Grid>
                    <Grid className={classes.dip_main3}>
                        <Typography className={classes.signup_tit}>Bullforce Diplomats</Typography>
                    </Grid>
                    
                    <Grid className={classes.signupinfo_card}>
                        <Grid className={classes.signupinfo_card1} >

                            <Grid item md={12} style={{ marginTop: '10px' }}>

                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Name*'
                                        fullWidth

                                        value={this.state.fname}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 1 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: { color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ fname: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 1 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>

                            </Grid>

                            <Grid item md={12} style={{ marginTop: '10px' }}>
                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Email*'
                                        fullWidth

                                        value={this.state.email}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 2 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ email: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 2 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>

                            </Grid>
                            <Grid item md={6} style={{ marginTop: '10px' }} >
                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Phoneno'
                                        fullWidth

                                        value={this.state.phoneno}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 3 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ phoneno: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 3 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>

                            </Grid>
                            <Grid item md={12} style={{ marginTop: '10px' }}>

                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Profession'
                                        fullWidth

                                        value={this.state.profession}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 4 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ profession: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 4 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>

                            </Grid>

                            <Grid item md={12} style={{ marginTop: '10px' }}>
                                <FormControl style={{ width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Social Media Handle'
                                        fullWidth

                                        value={this.state.socialmed}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                        }}
                                        InputLabelProps={{
                                            style: { color: this.state.errtype === 5 ? "#F74542" : '#92A4C1', fontFamily: 'cardiuma-regular', fontSize: '12px' },


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },
                                            maxLength: 16,
                                            style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },

                                        }}
                                        onChange={(e) => this.setState({ socialmed: e.target.value, errtype: 0 })}
                                    ></TextField>
                                    {this.state.errtype === 5 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>


                            </Grid>
                            <Grid item md={12} style={{ margin: '25px', }}>
                                <Button fullWidth className={classes.submitBtn}

                                    onClick={() => this.createAccount()}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.teamworkcard}>
                        <img src={teamwork} alt='teamwork'
                            style={{ height: '100%', width: '100%' }}>

                        </img>
                    </Grid>

                </Grid>

                <Grid className={classes.teamworkcard1}>
                    <img src={teamwork_mobile} alt='teamwork'
                        style={{ height: '100%', width: '100%' }}>

                    </img>
                </Grid>

                <Grid style={{width:'100%'}}>
                    <Fotter/>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>


            </div>

        )
    }
}
export default (withStyles(styles)((main)));