import { Typography, Grid } from '@mui/material';
import React, { Component } from 'react'
import { withStyles } from "@mui/styles";
import { httpRequest } from '../../ui-utils/api'
import moment from 'moment';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import tr from "../../ui-assets/tr.svg"
import settlement from "../../ui-assets/settlement.svg"
const styles = theme => ({

    tit: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "16px !important",
        lineHeight: "18px !important",
        color: "#000000 !important",

        fontFamily: "cardiuma-M !important",
        "@media only screen and (max-device-width: 480px)": {
            color: "#FFFFFF !important",
            fontSize: "14px !important",
        }

    },
    leg: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",

        fontFamily: "cardiuma-regular !important",
        "@media only screen and (max-device-width: 480px)": {
            color: "#FFFFFF !important",
            fontSize: "14px !important",
        }
    },
    tit_label: {

        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#333333 !important",

        fontFamily: "cardiuma-regular !important"
    },
card1:{
    width: '95%', display: 'flex',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block',
    }
},
card2:{
    width: '70%' ,
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block',
    }

},
card3:{
    width: '30%' ,display: 'flex', justifyContent: 'flex-end',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block',
    }

},
card4:{
    width: '95%', marginTop: '5px', display: 'flex' ,
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block',
    }
},
card5:{
    width: '70%' ,
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block',
    }

},
card6:{
    width: '30%', marginLeft: '5%', marginTop: '3%',
    "@media only screen and (max-device-width: 480px)": {
        width: '100%', display: 'block', marginLeft: '0%', marginTop: '6%',
    }
}
});



class holiday extends Component {


    constructor(props) {
        super(props)


        this.state = {
            userdata: [],
            hdesp: '',
            hdate: '',
            caldate: dayjs('2023-09-01'),
            holidays:[],
        }



    }

//Writing a function for displaying all the holidays
    ServerDay({ day,outsideCurrentMonth, ...other }){
        const holidays = this.state.holidays.filter(holiday => holiday.date === day.format('YYYY-MM-DD'));
        const isHoliday = holidays.length > 0;
        const typeOfHoliday = isHoliday ? holidays[0].type : null;
        return (
          <PickersDay
            {...other}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            style={{
                backgroundColor : isHoliday ? (typeOfHoliday==="TRADING" ? "#F74542":"#517AAF" ) : 'transparent',
                color: isHoliday ? 'white' : 'inherit',
            }}
          >
            {day.format('D')}
          </PickersDay>
        );   
         }

    componentDidMount = async () => {

        let userdata1 = []

        var currenDate = moment(new Date()).format("MM/DD/YYYY");
        var endDate = moment(currenDate, "MM/DD/YYYY");

        try {
            const cal_list = await httpRequest({
                endPoint: `api/calander/get_holidaylist`,
                method: "get",
                instance: "instanceOne",

            });



            let a = 0
            console.log("Holiday Dates",cal_list)
            //Creating a new array and storing all holidays
            const allHolidays = cal_list.data.map((ele)=>{
                return {date:moment(ele.h_date).format("YYYY-MM-DD"), type:ele.otype}
            })
            console.log("allHolidays",allHolidays)
            this.setState({holidays:allHolidays})
            
            const re = cal_list.data.map(data => {



                let hdate = moment(data.h_date).format("MM/DD/YYYY");

                var result = endDate.diff(hdate, 'days');
                //  console.log(new Date(hdate) + '*****' + result)
                //                                                          {/*  borderRight: label.exchange.split(',').length - 1 !== index1 ? '1px solid #999999' : '', */}
                let b = 0
                if (a === 0) {
                    if (result <= 0) {
                        a = 1
                        b = 1

                        let hmsg = parseInt(result) * -1 + " Days (" + moment(data.h_date).format("ddd, DD MMM") 
                        this.state.caldate = dayjs(moment(data.h_date).format("YYYY-MM-DD"))
                        console.log(this.state.caldate)
                        this.setState({ hdesp: data.occasion, hmsg: hmsg })
                    }
                }

                userdata1.push({
                    ha_date: moment(data.h_date).format("ddd, DD MMM YYYY"),
                    occasion: data.occasion,
                    exchange: data.exchange,
                    otype: data.otype,
                    a: b

                })

            })

        } catch (error) {

        }
        this.setState({ userdata: userdata1 })
    }
    render() {
        const { classes } = this.props;
        return (
            <div style={{ width: '100%', paddingTop: '25px' }}>
                <div style={{ width: '100%' }}>
                    <div className={classes.card1}>
                        <div className={classes.card2}>
                            <Grid style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
                            
                                <Typography className={classes.tit} style={{ marginLeft: '5px' }}>The next trading holiday is in {this.state.hmsg} {this.state.hdesp})</Typography>
                            </Grid>
                        </div>
                        <div className={classes.card3}>
                            <Grid style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
                                <div style={{ background: '#F74542', borderRadius: '2px', height: '10px', width: '10px' }}>

                                </div>
                                <Typography className={classes.leg} style={{ marginLeft: '5px' }}>TRADING</Typography>
                                <div style={{ background: '#517AAF', marginLeft: '15px', borderRadius: '2px', height: '10px', width: '10px' }}>

                                </div>
                                <Typography className={classes.leg} style={{ marginLeft: '5px' }}>SETTLEMENT</Typography>
                            </Grid>
                        </div>

                    </div>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>


                    <div className={classes.card4}>


                        <div className={classes.card5}>

                            {this.state.userdata.map((label, index) => {


                                return (

                                    <Grid style={{ width: '100%', display: 'flex', borderTop: '1px solid #DFE0EB', background: '#ffffff', alignItems: 'center', padding: '18px 2px' }}>
                                        <Grid style={{ width: '5%', }}>


                                            <img src={label.otype === "TRADING" ? tr : settlement}></img>


                                        </Grid>

                                        <Grid style={{ width: '20%', marginLeft: '1%' }}>
                                            <Typography className={classes.tit_label}>{label.ha_date}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                            {label.a === 10 &&
                                                <div style={{ height: '10px', width: '10px', background: '#21B12F', borderRadius: '50%' }}></div>
                                            }
                                            <Typography className={classes.tit_label} style={{ paddingLeft: '20px' }}>{label.occasion}</Typography>
                                        </Grid>
                                        <Grid style={{ width: '20%', }}>


                                            <div style={{ display: 'flex' }} >
                                                {

                                                    label.exchange.split(',').map((data, index1) => {

                                                        return (


                                                            <div style={{ padding: '0px 5px' }}>
                                                                <Typography className={classes.tit_label} >{data !== "MCX" ? data : ''}</Typography>
                                                            </div>

                                                        )
                                                    })

                                                }

                                            </div>

                                        </Grid>


                                    </Grid>

                                )
                            })}
                        </div>
                        <div className={classes.card6}>

                            <div style={{ width: '90%', padding: '20px 20px 0px 20px', background: '#ffffff', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DemoItem style={{ background: '#ffffff' }} >
                                        <DateCalendar 
                                        //value={this.state.caldate}
                                        slots={{
                                                day: props => this.ServerDay({ ...props}),
                                            }}
                                            // sx={{
                                            //     marginBottom: '-10px',
                                            //     "& .MuiPickersDay-root": {
                                            //         "&.Mui-selected": {
                                            //             backgroundColor: '#F74542 !important',
                                            //         },
                                            //     },
                                            // }}

                                        >

                                        </DateCalendar>
                                        
                                    </DemoItem>

                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default (withStyles(styles)((holiday)));