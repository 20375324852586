import React, { Component, } from 'react'
import { Grid, Typography, FormControl, TextField,
    MenuItem,Select,InputLabel,Snackbar, SnackbarContent,IconButton,
    FormHelperText, Button} from '@mui/material'
import { withStyles, } from "@mui/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import OtpInput from "react-otp-input";
import { httpRequest } from '../../ui-utils/api'
import { Close } from '@mui/icons-material';
import * as CryptoJS from 'crypto-js';
import digi from '../../ui-assets/diglocker.svg'
import Secure from '../../ui-assets/Secure.svg'
import cong_bull from '../../ui-assets/cong_bull.svg'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Account Disclosure | BullForce',
    description: 'Bullforce Account Closure: Securely close your demat account with full compliance. Follow NSE, BSE guidelines for a hassle-free and transparent closure process.',
    canonical: 'https://bullforce.co/account_closure',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, account, closure, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

const styles = theme => ({
card:{
    width:'100%',
    display:'flex',
    height:'100vh',
justifyContent:'center',
alignItems:'center',
"@media only screen and (max-device-width: 480px)": {
    marginTop:'20%',
    
    alignItems:'start'
},
    
},
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',textAlign:'center',
        // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',marginTop:'5%'
        },

    },
    email_sub_tit:{
        marginTop: '15px', color: '#DFE0EB', fontFamily: 'cardiuma-M !important', fontSize: '20px !important',textAlign:'center', marginBlockStart: 0, marginBlockEnd: 0, fontWeight: 'normal', 
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex'
        }
    },
    email_sub_tit1:{
        marginTop: '15px', color: '#DFE0EB', fontFamily: 'cardiuma-regular !important', fontSize: '16px !important',textAlign:'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
        }
    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        marginTop:'5%',
        width:'50%'
    },
    joinDiplomtsBtn1: {

        background: "#ffffff !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#F74542 !important",
        borderBottomColor:'1px solid #F74542',
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        marginTop:'5%',
        width:'50%'
    },

    joinDiplomtsBtn2: {
        background: 'transparent !important',
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#0083CA        !important",
        
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        marginTop:'5%',
        width:'50%'
    },
    texthear:{
        textAlign: 'center', color: '#F3D45E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '24px' ,
        "@media only screen and (max-device-width: 480px)": {
           fontSize: '20px' 
        }
       
   },
   texthear1:{
       textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'4%', 
       "@media only screen and (max-device-width: 480px)": {
           fontSize: '16px' 
        }
      
   },
   texthear2:{
       textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'3%', 
       "@media only screen and (max-device-width: 480px)": {
           fontSize: '16px' 
        }
      
   },
   texthear3:{
       textAlign: 'center', color: '#9A9A9A !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px',
       "@media only screen and (max-device-width: 480px)": {
           fontSize: '12px' 
        }
   },
   cong_card: {
    
    width: '30%', display:'grid', marginTop: '20px',
    "@media only screen and (max-device-width: 480px)": {
       
        width: '90%',
    }
},
cong_card1: {
    width:'60%',
    
    "@media only screen and (max-device-width: 480px)": {
        width:'90%',
    }
},
})

const reason_list=[
    {

    }
]

class accountclosure extends Component {
    constructor(props) {
        super(props)


        
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            
            errorMsg:'',
            errtype:0,
            pageno: 0,
            client_name:'',
            emailid:'',
            client_code:'',
            reason_closure:'',
            afeed:'',
            aadharno:'',
            mobileno:''
        }



    }
    closeContactModal = () => {
        this.setState({ showerr: false })
    }

    componentDidMount = async () => {

    

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if(urlParams.get('data') !==null)
{
    this.setState({pageno:4})
}
}
    VerifyReferralOTP= async () => 
    {

        const {client_code,otp}=this.state
        let payload = {
            clentid: client_code,
            otp:otp
           }   

           try {
            const openaccount = await httpRequest({
                endPoint: `/api/referral/otpvalidate`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if(openaccount.status===200)
            {
                    
                  
                this.setState({pageno:2})
            }
            if(openaccount.status===201)
            {
                this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
            }
            
           } catch (error) {
            
           }
        
           

    
}
    Verifyclient_code= async () => {
        const {client_code}=this.state
        let payload = {
         clentid: client_code
        }   
     try {
         const openaccount = await httpRequest({
             endPoint: `api/referral/verify`,
             method: "post",
             instance: "instanceTwo",
             requestBody: payload,
         });
         if(openaccount.status===200)
         {
     
            let aa=CryptoJS.AES.decrypt(openaccount.aadharno, "96358214256984120").toString(CryptoJS.enc.Utf8)
             
             this.setState({ pageno:1 ,client_name:openaccount.name,emailid:openaccount.emailid,mobileno:openaccount.mobileno,aadharno:aa})
            
         }
         if(openaccount.status===201)
         {
             this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
         }
     } catch (error) {
         
     }
     
     
     }
     
     handleOTPChange = (otp) => {
        this.setState({ otp })
    
    
    }

    reason_closure= async () => {
        const {client_code,emailid,mobileno,reason_closure,afeed,client_name}=this.state
        let payload = {
     
         "client_code":client_code,
            "emailid": emailid,
            "mobileno": mobileno,
           "reason_closure":reason_closure,
            "afeed":afeed,
            "name":client_name//added in 13/04/24
        }

        this.setState({islodding:true})
     try {
         const digilock = await httpRequest({
             endPoint: `/api/accountclosure`,
             method: "post",
             instance: "instanceTwo",
             requestBody: payload,
         });
     
         
         if(digilock.status===200)
         {
            this.setState({islodding:false,pageno:3})


           

             
         }
     } catch (error) {
         
     }   
    }

    Continue = async () => {
        //   window.ChangePageNo (9)
        
        const {emailid,mobileno,aadharno}=this.state
          
     
     let payload = {
     
         
         "emailid": emailid,
         "mobileno": mobileno,
         "aadhar":aadharno.substring(8,12),
         "type":1
     }
     this.setState({islodding:true})
     try {
         const digilock = await httpRequest({
             endPoint: `/setu/DigiLocker/getTocken`,
             method: "post",
             instance: "instanceTwo",
             requestBody: payload,
         });
     
         
         if(digilock.status===200)
         {
            this.setState({islodding:false})


           

             window.open(digilock.url,'_self')
         }
     } catch (error) {
         
     }                                                                                   
     
         }
    render() {
        const { classes } = this.props;
        const {pageno } = this.state

    
        return (

            <div >
              <DocumentMeta {...meta} />
{pageno===0 &&
<div>

    <Grid container className={classes.card}  >
        <div style={{width:'100%'}}>
            {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
    <h1 className={classes.card_tit}>Account Closure Request</h1>
        <h3 className={classes.email_sub_tit} style={{marginTop:'1%',display:'flex', justifyContent:'center'}}>Enter your Client Code</h3>
<Grid style={{width:'100%',display:'flex', justifyContent:'center'}}>
        <Grid  className={classes.cong_card} >
                                <FormControl style={{width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"

autoFocus

                                        label='Client Code'
                                        fullWidth

                                        value={this.state.client_code}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                            '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }


                                        }}

                                        InputLabelProps={{
                                            sx: {
                                                // set the color of the label when not shrinked
                                                color: "#FFFFFF",
                                                fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    // set the color of the label when shrinked (usually when the TextField is focused)
                                                    color: "#FFFFFF"
                                                }
                                            }


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },

                                            style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', }

                                        }}
                                        onChange={(e) => this.setState({ errtype: 0, errorMsg: "", client_code: e.target.value })}
                                    ></TextField>
                                    {this.state.errtype === 5 &&
                                        <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                    }
                                </FormControl>

                                <div style={{marginTop:'10%'}}>
                            <Button fullWidth className={classes.joinDiplomtsBtn}
                             onClick={() => this.Verifyclient_code()}
                            
                            >Submit</Button>
                            </div>
                            <div style={{marginTop:'5%'}}>
                            <Button fullWidth className={classes.joinDiplomtsBtn1}>Cancel</Button>
                            </div>
                            
                            </Grid>
                           
                            </Grid>
                            </div>
    </Grid>
</div>
}

   {pageno===1 &&
   
   
    <div>
  <Grid className={classes.card}  >
    <div>
    <h1 className={classes.card_tit}>Account Closure Request</h1>
        <Typography className={classes.email_sub_tit}  style={{marginTop:'4%',display:'flex', justifyContent:'center'}}>Please enter OTP</Typography>
        <Typography className={classes.email_sub_tit1} style={{marginTop:'2%'}} >Please provide us the OTP, Sent to your phone number registered with Bullforce</Typography>
<Grid style={{width:'100%',display:'flex', justifyContent:'center',marginTop:'2%'}}>
        <Grid style={{ width: '100%', display:'grid', marginTop: '20px' }}>
        <OtpInput

numInputs={6}
value={this.state.otp}

isInputSecure={true}
shouldAutoFocus={true}
focusStyle={{
    outline: 'none',
    backgroundColor: 'red !important',
}}

inputStyle={{
    backgroundColor: 'transparent',
    width: "35px",
    height: "35px",


    border: "none",
    marginRight: "5px",
    color: "#ffffff ",
    fontSize: "16px",
    fontFamily: "cardiuma-M",
    borderBottom: '1px solid #949A9E',

}}
containerStyle={{
    justifyContent: "center",
    borderBottom: '10',

}}
renderInput={(props) => <input {...props} />}

onChange={this.handleOTPChange}


/>

                                <div style={{marginTop:'10%',width:'100%',display:'flex',justifyContent:'center'}}>
                            <Button  className={classes.joinDiplomtsBtn}
                            
                            onClick={() => this.VerifyReferralOTP()}
                            >Continue</Button>
                            </div>
                            <div style={{marginTop:'5%'}}>
                            <Button fullWidth className={classes.joinDiplomtsBtn2}
                     onClick={() => this.Verifyclient_code()}
                            
                            >Resend OTP</Button>
                            </div>
                            
                            </Grid>
                           
                            </Grid>
                            </div>
    </Grid>

    </div>
   }
      {pageno===2 &&
   
   
   <div>
 <Grid className={classes.card}  >
    <div style={{width:'100%'}}>
   <h1 className={classes.card_tit}>Account Closure Request</h1>
       <Typography className={classes.email_sub_tit}  style={{marginTop:'2%',display:'flex', justifyContent:'center'}}>Select below option</Typography>
       
<Grid style={{width:'100%',display:'flex', justifyContent:'center',marginTop:'1%'}}>
       <Grid className={classes.cong_card}>
  
                                <FormControl style={{width: '100%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Client Name'
                                        fullWidth

                                        value={this.state.client_name}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                            '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }


                                        }}

                                        InputLabelProps={{
                                            sx: {
                                                // set the color of the label when not shrinked
                                                color: "#FFFFFF",
                                                fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    // set the color of the label when shrinked (usually when the TextField is focused)
                                                    color: "#FFFFFF"
                                                }
                                            }


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },

                                            style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', }

                                        }}
                                      
                                    ></TextField>
                                  
                                </FormControl>
                                <FormControl style={{width: '100%',marginTop:'3%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Email ID'
                                        fullWidth

                                        value={this.state.emailid}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                            '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }


                                        }}

                                        InputLabelProps={{
                                            sx: {
                                                // set the color of the label when not shrinked
                                                color: "#FFFFFF",
                                                fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    // set the color of the label when shrinked (usually when the TextField is focused)
                                                    color: "#FFFFFF"
                                                }
                                            }


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },

                                            style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', }

                                        }}
                                      
                                    ></TextField>
                                  
                                </FormControl>
                                <FormControl variant="standard" sx={{marginTop:'3%',
              minWidth: '100%', '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
          }}>
              <InputLabel id="demo-simple-select-standard-label"
                  style={{ color: this.state.errtype === 6 ? "#F74542" : '#BCD171', fontFamily: 'cardiuma-regular', fontSize: '12px' }}
              >Reason for closure</InputLabel>
              <Select
                  id="demo-simple-select-standard-label"
                
                  values={this.state.reason_closure}
                  variant="standard"
                
                  fullWidth
                
                  style={{ color: '#ffffff' }}

                  onChange={e => {
                      this.setState({ errtype: 0, errorMsg: "", reason_closure: e.target.value });
                  }}
              >

                  <MenuItem value={'stopped equity investment'}>Stopped Equity Investment</MenuItem>
                  <MenuItem value={'moving to another broker'}>Moving to Another Broker</MenuItem>
                  <MenuItem value={'Others'}>Others</MenuItem>
                 
                 
              </Select>
              {this.state.errtype === 6 &&
                  <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
              }

          </FormControl>
          <FormControl style={{width: '100%',marginTop:'3%' }} variant="standard">
                                    <TextField size="small" variant="standard"



                                        label='Additional Feedback'
                                        fullWidth

                                        value={this.state.afeed}
                                        sx={{
                                            '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                            '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                            '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                            '& .MuiInputLabel-root.Mui-focused': { color: '#BCD171' }


                                        }}

                                        InputLabelProps={{
                                            sx: {
                                                // set the color of the label when not shrinked
                                                color: "#FFFFFF",
                                                fontFamily: 'cardiuma-regular', fontSize: '14px',
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    // set the color of the label when shrinked (usually when the TextField is focused)
                                                    color: "#FFFFFF"
                                                }
                                            }


                                        }}
                                        InputProps={{
                                            classes: { input: classes.input },

                                            style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', }

                                        }}
                                        onChange={(e) => this.setState({ errtype: 0, errorMsg: "", afeed: e.target.value })}
                                    ></TextField>
                                  
                                </FormControl>
                                <div style={{marginTop:'10%'}}>
                            <Button fullWidth className={classes.joinDiplomtsBtn}
                              onClick={() => this.reason_closure()}
                            
                            >Continue</Button>
                            </div>
                            
                            </Grid>

                           </Grid>
                           </div>
                           </Grid>
   

   </div>
  }

  {
    pageno===3 &&
    <div>
      <Grid className={classes.card}>
        <Grid className={classes.cong_card1}>

            <Typography className={classes.texthear}>Digilocker - Documents for KYC</Typography>
            <Typography className={classes.texthear1}>Digilocker automatically verifies your documents needed for KYC and account opening on BullForce</Typography>
            <Grid style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:'4%'}}>
            <img src={digi} alt='diglocker'></img>
            </Grid>
            <Typography className={classes.texthear}>BullForce uses digilocker to fetch your documents</Typography>
            <Typography className={classes.texthear2}>I provide my consent to share my Aadhaar Number, Date of Birth and Name from my Aadhaar eKYC information with the Income Tax Departmert, Al States for the purpose of fetching my PAN Verification Record into Dig Locker.</Typography>
            <Grid style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img src={Secure} alt='diglocker'></img>
            <Typography className={classes.texthear3}> Your data is safe and secured with us</Typography>
            </Grid>
         
            <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Grid className={classes.butt_card}>
        <Button fullWidth className={classes.joinDiplomtsBtn}
       style={{ marginTop: '15px', }}
       onClick={() => this.Continue()}
   >Agree & Proceed For KYC

   </Button>
   </Grid>
   </Grid>
   </Grid>
   </Grid>
   
        </div>
  }
   {
    pageno===4 &&
    <div>
      <Grid className={classes.card}>
        <Grid className={classes.cong_card1}>

            <Typography className={classes.card_tit}>Account Closure</Typography>
            <div style={{display:'flex',justifyContent:'center'}}>
            <img src={cong_bull} alt="congbull"></img>
            </div>
            <Typography className={classes.email_sub_tit}>We acknowledge the receipt of your request for closing your account. We would process the request and confirm about the account closure through an email 
Thank you very much for your patronage.</Typography>
   </Grid>
   </Grid>
   
        </div>
  }
        <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar> 
            </div>

        )
    }
}
export default (withStyles(styles)((accountclosure)));
