import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'
import { Close, CurrencyRupee } from '@mui/icons-material';
import { signup_funcation } from './signup_funcation';
import verfiy from '../../../ui-assets/verfiy.svg'
import unverfiy from '../../../ui-assets/unverfiy.svg'
import hdfc from '../../../ui-assets/hdfc1.svg'
import atom from '../../../ui-assets/atom.svg'
import * as CryptoJS from 'crypto-js';
import {
    Typography, Grid, Button, Modal, Box, FormHelperText, TextField, FormControl,
    Checkbox, Radio, RadioGroup, FormControlLabel, Snackbar, SnackbarContent, IconButton
} from '@mui/material'
import bankupload from '../../../ui-assets/bank_upload.svg';//added 16/07/24
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'

        }
    },
    sigCanvas: {
        background: '#ffffff'
    },
    shutter: {
        marginTop: '50px',
        width: '100%',
        height: '100vh',
        background: 'red',
        borderTopLeftRadius: '8px',
        borderTopRightRaduis: '8px',
    }
}


const styles = theme => ({
    paymentcard: {
        width: '100%',
        padding: '20px 1px',
      
display:'grid',
justifyContent:'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '70px 1px',
            marginLeft: '0%',
            marginTop:'0%',
        }
    },
    payusecard: {

        width: '100%', display: 'flex', marginTop: '25px', justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            justifyContent: 'center',
            padding: '1px',
            marginLeft: '0%',

        }

    },
    payusecard1: {

        width: '100%', display: 'flex',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%',
            justifyContent: 'center',
            padding: '1px',
            marginLeft: '0%',

        }

    },
    pay_amount_card: {

        width: '75%',

        "@media only screen and (max-device-width: 480px)": {
            width: '80%',
        }
    },

    pay_amount_card1: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '15px',

        }
    },
    pay_amount_opt: {
        width: '100%',

        "@media only screen and (max-device-width: 480px)": {

            width: '100%',
            display: 'flex',
            marginTop: '10px',
            marginLeft: '-3%'
        }
    },
    pay_amount_opt1: {
        width: '100%', display: 'flex', alignItems: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            display: 'flex', alignItems: 'center',



        }
    },

    pay_amount_upi: {
        width: '60%', marginLeft: '5px',
        "@media only screen and (max-device-width: 480px)": {

            width: '100%', marginLeft: '0px',



        }

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    payment_succ_txt: {
        padding: '2% 0% 0% 0%', textAlign: 'center', color: '#3E3E3E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '24px !important',

       
        "@media only screen and (max-device-width: 480px)": {

            color: '#BCD171 !important',
             fontFamily: 'cardiuma-M !important'
  
  
          }
    },
    payment_succ_txt1: {
        padding: '5px', textAlign: 'center', color: '#3E3E3E !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
        "@media only screen and (max-device-width: 480px)": {

            color: '#FAFAFA !important',
             fontFamily: 'cardiuma-M !important'
  
  
          }
    },
    payment_failed_txt: {
        padding: '2% 0% 0% 0%', textAlign: 'center', color: '#9A9A9A !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
        "@media only screen and (max-device-width: 480px)": {

          color: '#9A9A9A !important',
           fontFamily: 'cardiuma-M !important',
   padding:'1% 5%'

        }
    },

    payment_failed_txt1: {
        padding: '5px', color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
        "@media only screen and (max-device-width: 480px)": {

            color: '#FAFAFA !important',
             fontFamily: 'cardiuma-M !important'
  
  
          }
    },
    payment_failed_txt2: {
        padding: '5px', textAlign: 'center', color: '#3E3E3E !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
        "@media only screen and (max-device-width: 480px)": {

            color: '#999999 !important',
             fontFamily: 'cardiuma-M !important',
            
             
            
  
          }
    },
    payment_ok: {
        background: "#F74542 !important",
        padding: "10px 24px !important",
        borderRadius: "24px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        width: '80%',
        "@media only screen and (max-device-width: 480px)": {
            width: '80%',

            marginLeft: '0%',

        },

    },
    bankcard: {
        width: '100%',
        display: 'flex',
        
        marginTop: '25px',
        "@media only screen and (max-device-width: 480px)": {
            width: '110%',

            display: 'flex',

        },

    },
    payment_dig: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: 'auto',

        outline: 'none',
        background: '#ffffff',
        p: 4,
        borderRadius: '8px',
        padding: '2%',
        justifyContent: 'center', alignItems: 'center',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            height: '90vh',
            background: '#1B2636  !important',
            transform: 'translate(-50%, -43%)',
            
            borderRadius: '0px',
        }

    },
//added on 10/08/24
    paymentinfocard:{
        background: 'linear-gradient(90deg, #2B405B -42.71%, #121E2D 100%)',
        border: '0.5px solid #506480',
        borderRadius:'8px',
        padding:'10px 20px',
        "@media only screen and (max-device-width: 480px)": {
            marginTop:'-10%'
        }
    },
    hedatxt1:{
       color: '#999999 !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
    },
    con_txt:{
        textAlign: 'center', color: '#BCD171 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '18px !important',
    },
    con_txt1:{
        textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-M !important', fontSize: '16px !important',
        "@media only screen and (max-device-width: 480px)": {
            padding:'1% 5%'
        }
    },
    //added on 11/08/24
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',  width: '100%',
     

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
})

class payment extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
          
            paymentvalue: 'upi',
            upipay: 1,
            payment_succ: false,
            payment_fail: false,
            bankvalue: 'atom',
            subscription:[], // added on 26/07/2024 To fetch subscription details from local storage 
            plans:'Subscription', //  added on 26/07/20245 Set default plan to subscription. 
            amount:'499',//added on 27/07/24 To store payment amount. 
            selectedexchange:''
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {

        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
      
              if(rd2.equity===true)
              {
                this.setState({selectedexchange:'Equity'})
              }
              if(rd2.equity===true && rd2.fando===true)
                {
                  this.setState({selectedexchange:'Equity & FNO'})
                }
                if(rd2.equity===true && rd2.commodity===true)
                    {
                      this.setState({selectedexchange:'Equity & Commodity'})
                    }
                if(rd2.equity===true && rd2.fando===true && rd2.commodity===true)
                    {
                      this.setState({selectedexchange:'Equity,Commodity & FNO'})
                    }
let aaa=[]
               let newArray = rd2.subscription.map((currentValue, index, array) =>{
                // Returns element to new Array
                console.log('aaaaa==',currentValue)
                if(currentValue.card===true)
                {
                    this.setState({amount:currentValue.amount})
          aaa.push(
                   { "amount":currentValue.amount}
                )
            }
            })

            console.log('newArray',newArray)
           this.setState({subscription:aaa})

            console.log('aaaa',this.state.subscription)
        window.scrollTo(0, 0);

        
        if (rd2.stage === 6) {
            let dedata = ""
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            dedata = JSON.parse(CryptoJS.AES.decrypt(decodeURIComponent(urlParams.get('data'), "base64"), "96358214256984120").toString(CryptoJS.enc.Utf8))

            this.setState({
                emailid: rd2.emailid,
                mobileno: rd2.mobileno,
                stage: 0,
                citizen: rd2.citizen,
                aadharno: rd2.aadharno,
                panverfy: rd2.panverfy,
                aadharverfy: rd2.aadharverfy,
                upi_id: rd2.upi_id,
                bankaccno: rd2.bankaccno,
                ifsc: rd2.ifsc,
                tinvestment: rd2.tinvestment,
                Annual_Income: rd2.Annual_Income,
                Occupation: rd2.Occupation,
                panno: rd2.panno,
                aadharno: rd2.aadharno,
                fullname: rd2.fullname,
                dob: rd2.dob,
                gender: rd2.gender,
                profile_img: rd2.profile_img,
                father_name: rd2.father_name

            })


            if (dedata.order_status === "Success") {


                this.setState({
                    payment_orderid: dedata.orderid, payment_succ: true,
                    payment_txid: dedata.bank_ref_no,
                    payment_amount: dedata.amount
                })




            }

            if (dedata.order_status !== "Success") {
                this.setState({
                    payment_orderid: dedata.orderid, payment_fail: true,
                    payment_txid: dedata.bank_ref_no,
                    payment_amount: dedata.amount
                })

            }
        }

    }
    Continue9_1 = async () => {
        window.ChangePageNo(16)
        
      

      

    }
    openPay(atomtocken, atommerchid, retrrnurl) {


        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid = rd2.emailid
        let mobileno = rd2.mobileno
        const options = {
            "atomTokenId": atomtocken,
            "merchId": atommerchid,
            "custEmail": emailid,
            "custMobile": mobileno,
            "returnUrl": retrrnurl // replace with your return URL
        }
        let atom = new window.AtomPaynetz(options, 'uat');
    }
    //added on 27/07/24 To trigger After payment failed. 
    Continue_Failed = async () => {
        this.setState({payment_fail : false})
        const {amount}=this.state
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid = rd2.emailid
        let mobileno = rd2.mobileno
           let bankaccno=rd2.bankaccno
        let payload={
            
            "mobileno": mobileno,
            "emailid": emailid,
            "amount": amount
        }
        let type=0
    if(bankaccno!==null)
    {
    type=0
    
    }else{
        type=2
    }
    this.setState({ islodding: true })
   
        let backoffice = {
    
    
            "mobileno": mobileno,
            "emailid": emailid,
            "type": type
    
        }

try {
    const drfee = await httpRequest({
        endPoint: `/api/registration/drfee`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });

    if (drfee.status === 200) {
    try {
        const bo_data = await httpRequest({
            endPoint: `/api/bo/kyc`,
            method: "post",
            instance: "instanceTwo",
            requestBody: backoffice,
        });

        this.setState({ islodding: false })
        if (bo_data.status === 203) {


           
        }

        if (bo_data.status === 200) {
            window.ChangePageNo(16)
        }
    } catch (error) {
        window.ChangePageNo(16)
    }
    }
} catch (error) {
    
}

       

    }
    Continue9 = async () => {

        const { plans, bankvalue, upipay, upi_id } = this.state
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid = rd2.emailid
        let mobileno = rd2.mobileno
        let bankaccno=rd2.bankaccno
        let isok = 0
        //added on 03/08/24 To push Bank info. To payment 
        let type=0
        if(bankaccno!==null)
        {
        type=0
        
        }else{
            type=2
        }
      
        if (isok === 0) {

            this.setState({ islodding: true })
           



            if (bankvalue === "atom") {
                let payload1 = {
                    "plan": plans,
                    "mobileno": mobileno,
                    "emailid": emailid,
                    "upipay": upipay,
                    "upi_id": upi_id,
                    "pushtype":type//added on 03/08/24 Bank account type 2 payment Service. 
                }

                let obj = {}
                let maindata = JSON.parse(rd1)
                for (var key in maindata) {

                    let f = 0
                    if (key === "stage") {
                        obj[key] = 6
                        f = 1
                    }
                  
                    if (f === 0) {
                        obj[key] = maindata[key]
                    }

                }

                localStorage.removeItem('reg_data')
                localStorage.setItem('reg_data', JSON.stringify(obj))

                try {
                    const payment = await httpRequest({
                        endPoint: `/atompay`,
                        method: "post",
                        instance: "instanceThree",
                        requestBody: payload1,
                    });
                    this.setState({ islodding: false })

                    if (payment.status === 201) {
                        this.setState({ showerr: true, errorMsg: payment.message, mysnack: snackeror.myerror })
                    }
                    if (payment.status === 200) {
                        // console.log(payment.link)
                        //window.open(payment.link,'_self')


                        this.openPay(payment.atomtocken, payment.merchId, payment.atomreturnUrl)
                    }
                } catch (error) {

                }
            }
            if (bankvalue === "hdfc") {
                let payload1 = {
                    "plan": plans,
                    "mobileno": mobileno,
                    "emailid": emailid,
                    "pushtype":type //added on 03/08/24 Bank account type 2 payment Service. 
                }

                let obj = {}
                let maindata = JSON.parse(rd1)
                for (var key in maindata) {

                    let f = 0
                    if (key === "stage") {
                        obj[key] = 6
                        f = 1
                    }
                    if (f === 0) {
                        obj[key] = maindata[key]
                    }

                }

                localStorage.removeItem('reg_data')
                localStorage.setItem('reg_data', JSON.stringify(obj))
                try {

                    const payment = await httpRequest({
                        endPoint: `/pay`,
                        method: "post",
                        instance: "instanceThree",
                        requestBody: payload1,
                    });
                    this.setState({ islodding: false })
                    if (payment.status === 200) {
                        console.log(payment.link)
                        document.getElementById("encRequest").value = payment.link;
                        document.getElementById('loginSubmit').click();

                    }

                } catch (error) {

                }


            }

        }

    }
    async handlePaymentMode(e) {

        let paymentvalue = e.target.value;

        this.setState({ paymentvalue: e.target.value })

        if (paymentvalue === "upi") {
            this.setState({ upipay: 1 })
        } else {
            this.setState({ upipay: 0 })
        }

    };

    async handlePaymentMode1(e) {

        let bankname = e.target.value;

        this.setState({ bankvalue: e.target.value })



    };
    render() {
        
        const { classes } = this.props;
        
        return (
           
            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
    <div style={{width:window.innerWidth<600 ?'90%':'75%',display:'grid',justifyContent:'center'}}>
                       <Grid className={classes.paymentcard} >
                       {/* updaed font on 11/08/24 */}
               <Typography className={classes.card_tit}>Payment</Typography> 
               </Grid>
                 <Grid className={classes.paymentinfocard} >
                    <Grid style={{display:'flex',alignItems:'center',width:'100%'}}>
                    <Grid style={{width:'90%',display:'grid'}} >
                    <Typography className={classes.hedatxt1} >Account Opening Charge
                    </Typography>
                    <span className={classes.payment_failed_txt1}>₹{this.state.amount}</span>
                    </Grid>
                    <Grid style={{width:'10%'}} >
                            <Checkbox
                                checked="true"
                               size={ window.innerWidth<600 ? 'medium' :'medium'}
                                sx={{
                                    color: '#999999',
                                    borderRadius: '4px',
                                    '&.Mui-checked': {
                                        color: '#F74542',
                                    },
                                }}
                                onChange={() => this.setState({ equity: !this.state.equity })}
                            />
                            
                        </Grid>
                    </Grid>


                 </Grid>
         
               <Grid style={{background:'#2A3648',padding:'10% 3%',borderRadius:'8px',marginTop:'5%',width:'100%'}}>
               <Typography className={classes.con_txt}>
               Congratulation!
              </Typography>
              <Typography className={classes.con_txt1}>
              You got free access to your account.!
              </Typography>
              <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center',padding:'3px',marginTop:'3px' }}>
                            <Grid style={{ width: '90%', borderTop: '1px solid #1B2636' }} ></Grid>
                        </Grid>
               <Typography className={classes.payment_failed_txt}>
                     Account opening fee INR  <span className={classes.payment_failed_txt1}>₹{this.state.amount}</span> will be deducted on your first fund transfer
</Typography>
</Grid>
<Grid style={{ width:  '100%', display: 'flex', justifyContent: 'center',marginTop:'5%' }}>
                            <Button className={classes.payment_ok}
                                 style={{ marginTop: '20px', }}
                                 onClick={() =>this.Continue_Failed()}
                             >I agree & continue

                             </Button>
                         </Grid>

                         {/* <a href="upi://pay?pa=9482547357@ybl&pn=BULLFORCE&tr=120034&am=1&cu=INR&mc=1234&url=https://bullforce-trader-payment-gateway-staging.bullforce.co/paymentcancel">Buy Now</a>                          */}
            </div>
                </div>

        
//             <div>   <Grid className={classes.paymentcard} >
//                 <Typography style={{ color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '24px',textAlign: 'center'}}>Pay Subscription Fee</Typography>

             
              
//                 <Grid style={{ width: '100%', display: 'flex', alignItems: 'center',justifyContent:'center' }} >
                   
//                     {this.state.subscription.map((item, index) => {
//                         return(
//                     <Grid className={classes.pay_amount_card} >

//                         <Grid style={{ width: '100%', background: '#2A3648', borderRadius: '6px', padding: '20px',marginTop:'2%' }}>
//                             <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <CurrencyRupee style={{ color: '#FFFFFF' }}></CurrencyRupee> <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '24px' }}>{item.amount}</Typography>
//                             </Grid>
//                             <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <Typography style={{ color: '#9A9A9A', fontFamily: 'cardiuma-regular !important', fontSize: '18px' }}>Amount</Typography>
//                             </Grid>
//                             <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//                             <Grid style={{ width: '100%', borderTop: window.innerWidth<600 ? '1px solid #1B2636' : '1px solid #D9D9D9' }} ></Grid>
//                         </Grid>
//                         <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <Typography style={{ color: '#9A9A9A', fontFamily: 'cardiuma-regular !important', fontSize: '16px' }}>You have selected</Typography>
//                             </Grid>
//                             <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular !important', fontSize: '16px' }}>{this.state.selectedexchange}</Typography>
                            
//                             </Grid>


                            
//                         </Grid>
//                     </Grid>)})}
//                 </Grid>
//                 <Typography style={{ marginTop: '6%', color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '24px',textAlign:'center' }}>Pay using</Typography>
//                 <Grid className={classes.bankcard} >
//                     <RadioGroup
//                         row
//                         defaultValue="atom"
//                         value={bankvalue}


//                         onChange={e =>
//                             this.handlePaymentMode1(e)


//                         }
//                     >
//                         <FormControlLabel value="atom" control={<Radio
//                             disableripple

//                             sx={{
//                                 color: "#999999",
//                                 "&.Mui-checked": {
//                                     position: "relative",
//                                     "&::before": {
//                                         content: '""',
//                                         position: "absolute",
//                                         top: "50%",
//                                         left: "50%",
//                                         transform: "translate(-50%, -50%)",
//                                         width: "10px",
//                                         height: "10px",
//                                         borderRadius: "50%",
//                                         color: "yellow"
//                                     },
//                                     "&::after": {
//                                         content: '""',
//                                         position: "absolute",
//                                         top: "50%",
//                                         left: "50%",
//                                         transform: "translate(-50%, -50%)",
//                                         width: "10px",
//                                         height: "10px",
//                                         borderRadius: "50%",
//                                         backgroundColor: "#F74542",

//                                         borderColor: "#ffffff",
//                                         color: "yellow"
//                                     },

//                                     '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
//                                     {
//                                         stroke: '#ffffff',
//                                         strokeWidth: 0.5,
//                                     },
//                                 }

//                             }}
//                         />} label={<Grid style={{ padding: '3px 30px', borderRadius: '4px', background: '#0F1824' }}>  <img src={atom}></img> </Grid>} />
//                         <FormControlLabel value="hdfc" control={<Radio


//                             sx={{
//                                 color: "#999999",
//                                 "&.Mui-checked": {
//                                     position: "relative",
//                                     "&::before": {
//                                         content: '""',
//                                         position: "absolute",
//                                         top: "50%",
//                                         left: "50%",
//                                         transform: "translate(-50%, -50%)",
//                                         width: "10px",
//                                         height: "10px",
//                                         borderRadius: "50%",
//                                         color: "yellow"
//                                     },
//                                     "&::after": {
//                                         content: '""',
//                                         position: "absolute",
//                                         top: "50%",
//                                         left: "50%",
//                                         transform: "translate(-50%, -50%)",
//                                         width: "10px",
//                                         height: "10px",
//                                         borderRadius: "50%",
//                                         backgroundColor: "#F74542",

//                                         borderColor: "#ffffff",
//                                         color: "yellow"
//                                     },

//                                     '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
//                                     {
//                                         stroke: '#ffffff',
//                                         strokeWidth: 0.5,
//                                     },
//                                 }

//                             }}
//                         />} label={

//                             <Grid style={{ padding: '3px 30px', borderRadius: '4px', background: '#0F1824' }}>
//                                 <img src={hdfc}></img>
//                             </Grid>
//                         } />







//                     </RadioGroup>
//                 </Grid>
//                 {/*<Grid className={classes.payusecard} >
//           <RadioGroup
//               row
//               defaultValue="upi"
//               value={paymentvalue}


//               onChange={e =>
//                   this.handlePaymentMode(e)


//               }
//           >
//               <FormControlLabel value="upi" control={<Radio
//                   disableripple
                
//                   sx={{
//                       color: "#999999",
//                       "&.Mui-checked": {
//                           position: "relative",
//                           "&::before": {
//                               content: '""',
//                               position: "absolute",
//                               top: "50%",
//                               left: "50%",
//                               transform: "translate(-50%, -50%)",
//                               width: "10px",
//                               height: "10px",
//                               borderRadius: "50%",
//                               color: "yellow"
//                           },
//                           "&::after": {
//                               content: '""',
//                               position: "absolute",
//                               top: "50%",
//                               left: "50%",
//                               transform: "translate(-50%, -50%)",
//                               width: "10px",
//                               height: "10px",
//                               borderRadius: "50%",
//                               backgroundColor: "#F74542",

//                               borderColor: "#ffffff",
//                               color: "yellow"
//                           },

//                           '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
//                           {
//                               stroke: '#ffffff',
//                               strokeWidth: 0.5,
//                           },
//                       }

//                   }}
//               />} label={<Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>UPI</Typography>} />
//               <FormControlLabel value="onlinetransfer" control={<Radio

               
//                   sx={{
//                       color: "#999999",
//                       "&.Mui-checked": {
//                           position: "relative",
//                           "&::before": {
//                               content: '""',
//                               position: "absolute",
//                               top: "50%",
//                               left: "50%",
//                               transform: "translate(-50%, -50%)",
//                               width: "10px",
//                               height: "10px",
//                               borderRadius: "50%",
//                               color: "yellow"
//                           },
//                           "&::after": {
//                               content: '""',
//                               position: "absolute",
//                               top: "50%",
//                               left: "50%",
//                               transform: "translate(-50%, -50%)",
//                               width: "10px",
//                               height: "10px",
//                               borderRadius: "50%",
//                               backgroundColor: "#F74542",

//                               borderColor: "#ffffff",
//                               color: "yellow"
//                           },

//                           '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
//                           {
//                               stroke: '#ffffff',
//                               strokeWidth: 0.5,
//                           },
//                       }

//                   }}
//               />} label={<Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Card / Net banking</Typography>} />







//           </RadioGroup>
//                 </Grid>*/}

//                 {upipay === 10 &&
//                     <Grid className={classes.payusecard1} >
//                         <Grid className={classes.pay_amount_upi}>
//                             <Grid >
//                                 <FormControl style={{ width: '100%' }} variant="standard">
//                                     <TextField size="small" variant="standard"

//                                         fullWidth

//                                         sx={{
//                                             '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
//                                             '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
//                                             '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
//                                             "& .MuiInputBase-input.Mui-disabled": {
//                                                 WebkitTextFillColor: "#FFFFFF",
//                                             },
//                                         }}
//                                         InputLabelProps={{
//                                             style: { color: this.state.errtype === 4 ? "#F74542" : '#BCD171', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },
//                                         }}
//                                         label='Enter UPI Id'
//                                         value={this.state.upi_id}

//                                         InputProps={{

//                                             classes: { input: classes.input },
//                                             style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px' }
//                                         }}
//                                         onChange={(e) => this.setState({ upi_id: e.target.value })}
//                                     ></TextField>
//                                     {this.state.errtype === 4 &&
//                                         <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
//                                     }
//                                 </FormControl>


//                             </Grid>


//                         </Grid>
//                     </Grid>
//                 }

//                 <Grid className={classes.payusecard} >
//                     <Grid className={classes.pay_amount_upi} >

//                         <Button fullWidth className={classes.joinDiplomtsBtn}
//                             style={{ marginTop: '20px', }}
//                             onClick={() => this.Continue9()}
//                         >Pay & Continue

//                         </Button>

//                     </Grid>
//                 </Grid>
//                 <form id="nonseamless" name='redirect' action='https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction' method='POST'>

//                     <input id="encRequest" type='hidden' name='encRequest' />
//                     <input type="hidden" name="access_code" id="access_code" value="AVZB26KJ67CH57BZHC" />
//                     <input type="hidden" name="response_type" id="response_type" value="JSON" />

//                     <input style={{ display: 'none' }} id="loginSubmit" type="submit" />
//                 </form>
//             </Grid>
//                 <div style={{ width: '100%' }}>
//                     <Typography style={{ textAlign: 'center', color: '#1B2636', fontFamily: 'cardiuma-regular', fontSize: '14px' }}>Please provide us the OTP, Sent your phone number linked with aadhar</Typography></div>
//                 <Modal
//                     sx={{ border: 'none' }}
//                     open={this.state.islodding}
//                 >

//                     <Box
//                         style={{
//                             position: 'absolute',
//                             top: '50%',
//                             left: '50%',
//                             transform: 'translate(-50%, -50%)',
//                             width: '20%',
//                             height: '8%',

//                             outline: 'none',
//                             background: 'transparent',
//                             p: 4,
//                             borderRadius: '12px',

//                             justifyContent: 'center', alignItems: 'center'
//                         }}
//                     >

//                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//                             <img src={bull} alt={bull} width='50%' height='50%' />
//                         </Grid>
//                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//                             <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
//                         </Grid>
//                     </Box>

//                 </Modal>
//                 <Modal
//                     sx={{ border: 'none' }}
//                     open={this.state.payment_succ}
//                 >

//                     <Box
//                         className={classes.payment_dig}
//                     >

// <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop:window.innerWidth<600 ? '5%':'1%' }}>
//                             <img src={verfiy} alt={verfiy} width='80'></img>
//                         </Grid>
//                         <Typography className={classes.payment_succ_txt}>
//                             Payment Successful</Typography>
//                         <Typography className={classes.payment_succ_txt1}>
//                             Order no:{this.state.payment_orderid}</Typography>
//                         <Typography className={classes.payment_succ_txt1}>
//                             Transaction no:{this.state.payment_txid}</Typography>
//                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//                             <Grid style={{ width: '75%', borderTop: '1px solid #D9D9D9' }} ></Grid>
//                         </Grid>
//                         <Typography className={classes.payment_succ_txt1}>
//                             Amount : ₹{this.state.amount}</Typography>
//                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//                             <Button className={classes.payment_ok}
//                                 style={{ marginTop: '20px', }}
//                                 onClick={() => this.Continue9_1()}
//                             >Ok

//                             </Button>
//                         </Grid>

//                     </Box>

//                 </Modal>
//                 <Modal
//                     sx={{ border: 'none' }}
//                     open={this.state.payment_fail}
//                 >

//                     <Box
//                         className={classes.payment_dig}
//                     >

//                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center',marginTop:window.innerWidth<600 ? '5%':'1%' }}>
//                             <img src={bankupload} alt="unverfy" width='80'></img>
//                         </Grid>
//                         <Typography className={classes.payment_failed_txt }>
//                             Payment Failed</Typography>
//                             <Grid style={{background: window.innerWidth<600 ?'#2A3648':'#ffffff',padding: window.innerWidth<600 ? '5%':'1%',borderRadius:'6px',margin: window.innerWidth<600 ?'5%':'0%'
// }}>
//                         <Typography className={classes.payment_failed_txt1}>
//                         Don't worry we will open account for you!{this.state.payment_orderid}</Typography>
                
//                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
//                             <Grid style={{ width: '75%', borderTop: window.innerWidth<600 ? '1px solid #1B2636' : '1px solid #D9D9D9' }} ></Grid>
//                         </Grid>
//                         <Typography className={classes.payment_failed_txt2}>
//                         Account opening fee INR  <span className={classes.payment_failed_txt1}>₹{this.state.amount}</span> will be deducted on your first fund transfer
// </Typography>
// </Grid>
//                         <Grid style={{ width:  '100%', display: 'flex', justifyContent: 'center' }}>
//                             <Button className={classes.payment_ok}
//                                 style={{ marginTop: '20px', }}
//                                 onClick={() =>this.Continue_Failed()}
//                             >I agree & continue

//                             </Button>
//                         </Grid>

//                     </Box>

//                 </Modal>
//                 <Snackbar
//                     anchorOrigin={{
//                         vertical: "bottom",
//                         horizontal: "right"
//                     }}
//                     open={this.state.showerr}
//                     autoHideDuration={3000}
//                     onClose={() => this.closeContactModal()}

//                 >

//                     <SnackbarContent
//                         style={this.state.mysnack}
//                         message={

//                             <span style={{
//                                 display: "flex",
//                                 alignItems: "center",
//                                 color: "#333333",
//                                 fontSize: "12px",
//                                 fontFamily: "cardiuma-M !important"
//                             }}>

//                                 {this.state.errorMsg}

//                             </span>
//                         }
//                         action={
//                             <React.Fragment>

//                                 <IconButton
//                                     size="small"
//                                     aria-label="close"
//                                     color="inherit"
//                                     onClick={() => { this.setState({ showerr: false }) }}
//                                 >
//                                     <Close fontSize="small" />
//                                 </IconButton>
//                             </React.Fragment>
//                         }
//                     >

//                     </SnackbarContent>

//                 </Snackbar>
//             </div>


        )
    }
}
export default (withStyles(styles)((payment)));
